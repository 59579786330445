// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organisation-table-container{
    margin: 0.625rem 70px;
    background-color: var(--theme-color);
    border-radius: 0.75rem;
    color: var(--primary-color);
    /* padding: 1rem; */
}
.organisation-search-input{
    width: 50%;
    margin: 1.25rem;
}
.organisation-table-wrapper{
    padding: 1rem;
}
.organisation-search-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/pages/organisations/styles.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,oCAAoC;IACpC,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,UAAU;AACd","sourcesContent":[".organisation-table-container{\n    margin: 0.625rem 70px;\n    background-color: var(--theme-color);\n    border-radius: 0.75rem;\n    color: var(--primary-color);\n    /* padding: 1rem; */\n}\n.organisation-search-input{\n    width: 50%;\n    margin: 1.25rem;\n}\n.organisation-table-wrapper{\n    padding: 1rem;\n}\n.organisation-search-container{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
