import { useEffect, useState } from "react";
import { formValidator } from "../../../utils/formValidator";
import FormInput from "../../common/formInput";

type Props = {
  state: any;
  errorObject: any;
  handleCompletedStages: (stage: number) => void;
  stage: number;
  formModels: number[];
  handleModelInformationChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => void;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => void;
  handleDeleteModel: (index: number) => void;
  handleAddModel: () => void;
};
function ModelInformation({
  state,
  errorObject,
  handleCompletedStages,
  stage,
  formModels,
  handleModelInformationChange,
  handleInputChange,
  handleDeleteModel,
  handleAddModel,
}: Props) {
  const [error, setErrorObject] = useState<any>({});

  useEffect(() => {
    let tempLocalErrors = formValidator(state.awi_model_information[0], []);

    setErrorObject(tempLocalErrors);
    if (Object.values(tempLocalErrors).every((v) => !v)) {
      handleCompletedStages(stage);
    } else {
      handleCompletedStages(stage - 1);
    }
  }, [state]);

  return (
    <div className="app-registration-form-body">
      <div className="registration-form-subcontainer-heading">
        Model Information{" "}
        <img
          src="/assets/icons/caution.svg"
          title="Filling model information ensures optimal support, performance, and compatibility for your app's technical foundation."
          style={{ marginLeft: "8px" }}
        />
      </div>
      <div className="registration-form-models-contaner">
        {formModels.map((model, index) => {
          return (
            <div>
              <div style={{ display: "flex" }}>
                <div className="registration-general-details-row">
                  <div className="registration-general-details-row-inputcontainer">
                    <FormInput
                      placeHolder={`${index === 0 ? "Primary " : ""}${
                        index === 1 ? "Secondary " : ""
                      }Model Name`}
                      changeHandler={handleModelInformationChange}
                      type={"text"}
                      required={false}
                      labelOnTop
                      fieldName={`${model}_name`}
                      value={state.awi_model_information[index]?.name}
                      error={index < 1 && error.name}
                    ></FormInput>
                  </div>
                  <div className="registration-general-details-row-inputcontainer">
                    <FormInput
                      placeHolder={`${index === 0 ? "Primary " : ""}${
                        index === 1 ? "Secondary " : ""
                      }Model Architecture`}
                      changeHandler={handleModelInformationChange}
                      type={"text"}
                      required={false}
                      labelOnTop
                      fieldName={`${model}_architecture`}
                      value={state.awi_model_information[index]?.architecture}
                      error={index < 1 && error.type}
                    ></FormInput>
                  </div>
                  <div className="registration-general-details-row-inputcontainer">
                    <FormInput
                      placeHolder={`${index === 0 ? "Primary " : ""}${
                        index === 1 ? "Secondary " : ""
                      }Model Version`}
                      changeHandler={handleModelInformationChange}
                      type={"text"}
                      required={false}
                      labelOnTop
                      fieldName={`${model}_version`}
                      value={state.awi_model_information[index]?.version}
                      error={index < 1 && error.version}
                    ></FormInput>
                  </div>
                </div>
                <div
                  className="registration-form-model-delete-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span onClick={() => handleDeleteModel(index)}>
                    {index > 1 ? (
                      <img src="/assets/icons/appathon_delete.png"></img>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="registration-form-add-models-container">
        <span onClick={handleAddModel}>
          {" "}
          <img src="/assets/icons/appathon_input.svg"></img>
        </span>{" "}
        Add more models used
      </div>

      <div
        className="registration-form-subcontainer-heading"
        style={{ marginTop: "40px" }}
      >
        Additional Information
      </div>
      <div className="registration-general-details-row">
        <div className="registration-general-details-row-additionalinputcontainer">
          <FormInput
            placeHolder="Recommended App Configurations"
            changeHandler={handleInputChange}
            type={"textarea"}
            required
            labelOnTop
            disabled
            fieldName="awi_app_logic"
            value={state.awi_app_logic}
            error={error.awi_so_file_path}
          ></FormInput>
        </div>
        <div className="registration-general-details-row-additionalinputcontainer">
          <FormInput
            placeHolder="Test Results(if any)"
            changeHandler={handleInputChange}
            type={"textarea"}
            required
            labelOnTop
            disabled
            fieldName="awi_test_results"
            value={state.awi_test_results}
            error={error.awi_test_results}
          ></FormInput>
        </div>
      </div>
    </div>
  );
}

export default ModelInformation;
