// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pages-container{
    height: calc(100vh - 100px);
    overflow: auto;
}
.pages-footer{
    border-top: 1px solid #eaeaea;
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;AAClB;AACA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".pages-container{\n    height: calc(100vh - 100px);\n    overflow: auto;\n}\n.pages-footer{\n    border-top: 1px solid #eaeaea;\n    padding: 0.25rem;\n    font-size: 0.75rem;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
