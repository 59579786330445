
import Button from '../../button';
import MultiSelectDropdown from '../../multiSelectDropdown';

import "./styles.css"

type Props = {
  dropdownFields?: string[];
  dropdownOptions?: string[][];
  dropdownValues?: string[];
  setDropdownValues?: ((event: any, data: string[]) => void)[];
  isMultiSelect?: boolean[];
  clearFilters?: () => void;
  closeFilters?: () => void;
  applyHandler?: () =>void;
  appliedFilters?: any[][];
}

function FiltersDropdown({dropdownFields,dropdownOptions,dropdownValues,isMultiSelect,setDropdownValues,clearFilters,closeFilters,appliedFilters,applyHandler}:Props) {
  
  const handleCancel = () => {
    clearFilters && clearFilters();
    closeFilters && closeFilters();
  }
  const handleApply = () => {
    closeFilters && closeFilters();
    applyHandler && applyHandler();
  }
    return (
      <div className={`common-dropdown-container`}>
        <div className='common-dropdowns-wrapper-container'>
          {dropdownFields?.map((field, index) => {
            return (
              <div className="common-dropdown-wrapper">
                <MultiSelectDropdown
                  placeholder={field}
                  changeHandler={setDropdownValues![index]}
                  multiselect={isMultiSelect![index] ?? false}
                  options={dropdownOptions?.[index]}
                  selectedValues={appliedFilters?.[index]}
                />
              </div>
            );
          })}
        </div>
        <div className="common-filter-actions">
          <Button type="primary" onClick={handleApply}>
            Apply Filters
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </div>
      // <div className='dropdown-container'>
      //   <h1>Dropdown items</h1>
      //   {
      // </div>
    );
}

export default FiltersDropdown