import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { approveOrder, downloadOrder } from "../../../services/orders"
import { RootState } from "../../../store"
import Icon from "../../Icons"
import AppInformation from "./AppInformation"
import OrderDetails from "./OrderDetails"
import OrgInformation from "./OrgInformation"
import "./styles.css"


type organisationModalProps =
{
    show:boolean,
    toggleModal: ()=>void
    org_id?:number,
    org_name?:number,
    data?:any
}  



function OrganisationModal({show,toggleModal,data}:organisationModalProps) {
  

    const userObject = useSelector(
        (state: RootState) => state.userDetails
    )

    const checkRootOrApprover = ()=>{
        let isRootorApprover = false
        isRootorApprover = userObject.role.includes("root") || userObject.role.includes("approver")
        return isRootorApprover
    }
        const approveUserOrder = async(idx?:number) =>{
            const payload = {
            order_id: data.order_id
        }
        const response = await approveOrder(payload)
        toggleModal()
    }

    const navigator = useNavigate()

    const editOrder = ()=>{
        navigator(`/edit-order/${data.order_id}`)
    }

    const downloadOrderHelper = async() =>{
        const downloadedOrder = await downloadOrder({order_id:data.order_id})
        const file = new Blob([downloadedOrder], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        let a = document.createElement('a');
                    a.href = fileURL;
                    a.download = `Order ID: ${data.order_id}.pdf`;
                    a.click();
    }

  const renderModal = () =>{
    if (show){
    return (
        <div>
             
             <div className={`order-modal-header`}>
                <div onClick={toggleModal}>
                <FontAwesomeIcon icon={faArrowLeft}/>
                
                </div>
            <h1 className={`${!data.approved ? 'add-left-margin':''} order-modal-header-text`}>
                Order ID : {data?.order_id}
            </h1>
            <div className="view-order-actions">
            {!data.approved && checkRootOrApprover() && <Icon onClick={()=>approveUserOrder()} height = "2.25rem" width="2.25rem" src="/assets/icons/check.svg" title="approve order"></Icon>}
            {!data.approved && checkRootOrApprover() && <Icon onClick={()=>editOrder()} height = "2.25rem" width="2.25rem" src="/assets/icons/edit.svg" title="edit order"></Icon>}
            {<Icon height="2.25rem" onClick={()=>downloadOrderHelper()} width="3.35rem" src="/assets/icons/download.svg" title="download order"></Icon>}
            </div>
            </div>
            <div className='order-modal-content'>
                <div className="order-modal-content-section">
                    <OrgInformation data = {data}></OrgInformation>
                </div>
                <div className="order-modal-content-section">
                    <OrderDetails data = {data}></OrderDetails>
                </div>
                <div className="order-modal-content-section">
                    <AppInformation data = {data}></AppInformation>
                </div>
                
                
            </div>
            
            
        </div>
    )}
    else{
        return <div></div>
    }
  }
    return (

        renderModal()
    
  )
}

export default OrganisationModal