import { toast } from "react-toastify";

import { client } from "./axiosInstance";


export const getAllOrganisations = async () => {
    try {
        let response = await client <any> ("/orgs", {
        baseURL:"base",
        method: "GET",
    })

    

    if (response.status ===200){
    
        // toast.success(response.data.message)

        return await response.data.organisations
    }
    } catch (error) { 
        toast.error("Could not get organisations")
        }
    }
    
export const placeOrder = async (payload:any) =>{
    
    try {
        let response = await client <any> ("/create-order", {
        baseURL:"base",
        method: "POST",
        data:payload
    })

    

    if (response.status ===200){
        
        toast.success(response.data.message)
        return true
    }
    } catch (error) {
         
        toast.error("Could not palce orders")
        }
    }
   

export const updateOrder = async (payload:any) =>{
    
    try {
        let response = await client <any> ("/order", {
        baseURL:"base",
        method: "PATCH",
        data:payload
    })

    

    if (response.status ===200){
        
        toast.success(response.data.message)
        return true
    }
    } catch (error) {
         
        toast.error("Could not update orders")
        }
    }
export const fetchOrder = async (payload:{org_id:any,page:number,status?:boolean,filter?:any,search?:string}) =>{
    
    try {
        let response = await client <any> ("/orders", {
        baseURL:"base",
        method: "GET",
        params: payload
    })

    

    if (response.status ===200){
        
        // toast.success(response.data.message)
        return response.data.orders
    }
    } catch (error) {
         
        toast.error("Could not update Orders")
        }
    }

export const assignOrderToUser = async (payload:any)=>{
    try {
        let response = await client <any> ("/assign-order",{
            baseURL:"base",
            method:"PATCH",
            data:payload
        })

        if (response.status === 200){
            toast.success(response.data.message)
            return response.data
        }
    } catch (error:any) {
        toast.error(error?.response?.data?.message)
    }
}

export const approveOrder = async (payload:any) => {
    try {
        let response = await client <any>("/approve-order",{
            baseURL:"base",
            method:"PATCH",
            data:payload
        })

        if(response.status === 200){
            toast.success(response.data.message)
            return response.data
        }
    } catch (error:any) {
        toast.error(error?.response?.data?.message)
        
    }
}

export const approveReExecution = async (payload:any) => {
    try {
        let response = await client <any>("/reexecute-order",{
            baseURL:"base",
            method:"PATCH",
            data:payload
        })

        if(response.status === 200){
            toast.success(response.data.message)
            return response.data
        }
    } catch (error:any) {
        toast.error(error?.response?.data?.message)
        
    }
}

export const getOrderByID = async (payload:any) => {
    try {
        let response = await client <any>("/order",{
            baseURL:"base",
            method:"GET",
            params:payload
        })

        if(response.status === 200){
            toast.success(response.data.message)
            return response.data.data
        }
    } catch (error:any) {
        toast.error(error?.response?.data?.message)
        
    }
}

export const downloadOrder = async (payload:any) => {
    try {
        let response = await client <any>("/download-order",{
            baseURL:"base",
            method:"GET",
            params:payload,
            responseType:"blob"
        })

        if(response.status === 200){
            return response.data
        }
    } catch (error:any) {
        toast.error(error?.response?.data?.message)
        
    }
}