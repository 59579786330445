import { useEffect, useState } from "react";


import LoginForm from "../../../components/Auth/loginForm";


import "./styles.css";
import { useNavigate } from "react-router-dom";

const Login = () => {


    const navigate = useNavigate();


    

    

    const handleActiveForm = (value: String) => {
        const navigationValue = value as string;
        navigate(`/${navigationValue}`, { replace: true })
    };

    return (
        <div>
            <div className="login-container">
                <img
                    className="login-image"
                    src="/assets/images/login.png"
                    alt="login"
                />
                
                    <LoginForm handleActiveForm={handleActiveForm}></LoginForm>
                
                
            </div>
        </div>
    );
};

export default Login;
