import { Link } from 'react-router-dom'
import Lottie from 'react-lottie';
import animationData from '../../../../lotties/success.json';
import "./styles.css"

const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

function OrderSummary() {
  return (
    <div className='order-summary'>
        <Lottie 
	    options={defaultOptions}
        height={250}
        width={250}
      />
        <h1 className='create-order-heading'>Order Created</h1>
        <p>Visit <Link to={'/view-orders'}>Orders</Link> Page to see detailed view of the order created. </p>
    </div>
  )
}

export default OrderSummary