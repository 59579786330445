import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import FormInput from '../../common/formInput'
import Input from '../../common/Input'
import Modal from '../../common/modal'

import './styles.css'
type Props = {
    onClose: () => void
    open: boolean
}

function ProfileSettings({ onClose, open }: Props) {

    const [email,setEmail] = useState<string>('')
    const [password,setPassword] = useState<string>('*************')

   const userObject = useSelector(
        (state: RootState) => state.userDetails
    )
    useEffect(() => {

        setEmail(userObject.email)
    },[userObject.email])

    function handleInputChange(e: any, arg1: string): void {
    throw new Error('Function not implemented.')
    }


  return (

          <div>
             <div className='modal-backdrop' onClick={onClose}></div>
             <div className='modal-container-wrapper'>
                <div className='modal-container'>
            <h1 className='create-org-modal-header'>
                User Profile
            </h1>
            <div className='modal-content'>
                <div className='modal-content-row'>
                    
                    <Input
                        onChange={(e) => handleInputChange(e, "email")}
                        value={email}
                        placeholder='Email ID' 
                        type={'text'}   
                        hideSearch
                        required
                        disabled                 
                    />
                    
                </div>
                <div className='modal-content-row'>
                    <Input
                        onChange={(e)=>handleInputChange(e,"password")}
                        value = {password}
                        // className = "org-modal-input" 
                        placeholder='Password'
                        type={'text'} 
                        hideSearch
                        required
                        disabled
                    />
                    
                </div>
            </div>
            
            </div>
            </div>
        </div> 
  )
}

export default ProfileSettings

