import { createSlice } from "@reduxjs/toolkit";
import { renderDisplayStatus } from "./utils/appstackCard";

export interface IAppStatusSlice {
  status:{
    key: string;
    value: number;
  }[];
  displayStatus:{
    key: string;
    value: number;
  }[];
}

const initialState: IAppStatusSlice = {
  status: [],
  displayStatus: [],
};

export const appStatusSlice = createSlice({
  name: "commonConfig",
  initialState,
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
      state.displayStatus = payload.map((item:any)=>{
        let displayItemValue = renderDisplayStatus(item.value);
        return {
          value: item.value,
          key: displayItemValue,
        }
      })
    },
    emptyStatus: (state) => {
      state.status = [];
      state.displayStatus = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStatus, emptyStatus } = appStatusSlice.actions;

export default appStatusSlice.reducer;
