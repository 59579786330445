import { toast } from "react-toastify";
import { client } from "./axiosInstance";

export const uploadFile = async (formData: FormData) => {
  let response = await client<any>("/upload-file", {
    baseURL: "util",
    method: "POST",
    data: formData,
  });
  if (response.status === 200) {
    toast.success(response.data.message);
    return await response.data.data;
  } else toast.error("Data could not be fetched");
};

export const registerApp = async (payload: any) => {
  try {
    let response = await client<any>("/register-app", {
      baseURL: "base",
      method: "POST",
      data: payload,
    });

    if (response.status === 200) {
      toast.success(response.data.message);

      return await response.data;
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export const editApp = async (payload: any) => {
  try {
    let response = await client<any>("/edit-app", {
      baseURL: "base",
      method: "PATCH",
      data: payload,
    });

    if (response.status === 200) {
      toast.success(response.data.message);

      return await response.data;
    }
  } catch (error) {
    toast.error("Could not update app");
  }
};

export const registerAppVersion = async (payload: any) => {
  try {
    let response = await client<any>("/register-app-version", {
      baseURL: "base",
      method: "POST",
      data: payload,
    });

    if (response.status === 200) {
      toast.success(response.data.message);

      return await response.data;
    }
  } catch (error) {
    toast.error("Could not register app version");
  }
};

export const getAppVersioningDetails = async (payload: any) => {
  try {
    let response = await client<any>("/app-version-details", {
      baseURL: "base",
      method: "POST",
      data: payload,
    });

    if (response.status === 200) {
      toast.success(response.data.message);

      return await response.data;
    }
  } catch (error) {
    toast.error("Could not fetch app version details");
  }
};
