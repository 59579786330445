import ForgotPasswordComponent from "../../../components/Auth/forgotPasswordFrom";


// import "./styles.css";
import {  useNavigate } from "react-router-dom";

const ForgotPassword = () => {

    const navigate = useNavigate();
    

    
    const handleActiveForm = (value: String) => {
        const navigationValue = value as string;
        navigate(`/${navigationValue}`, { replace: true })
    };

    return (
        <div>
            <div className="login-container">
                <img
                    className="login-image"
                    src="/assets/images/login.png"
                    alt="login"
                />
                
                
                    <ForgotPasswordComponent
                        handleActiveForm={handleActiveForm}
                    ></ForgotPasswordComponent>
                
            </div>
        </div>
    );
};

export default ForgotPassword;
