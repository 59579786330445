function AppInformation({data}:any) {
    const tableHeading = [
        "S.No.",
        "App Name",
        "No of Channels",
        "Liscence ID",
        "Tenure(in years)",
        "Grace Period (in days)",
        "Start Date",
        "End Date",
    ]

const renderTable = (data:any)=>{

         
    return <table className="table app-information-table">
                <thead>
                    {
                        tableHeading?.map((heading)=> {
                            return <th>{heading}</th>
                        })
                    }
                </thead>

                <tbody>
                    {
                        data?.app_data?.map((app:any,idx:number)=>{
                            return (
                               <tr>
                                <td>{idx+1}</td>
                                <td>{app.awi_name || app.app_name || app.awi_app_name  }</td>
                                <td>{app?.app_count}</td>
                                <td>{app?.liscence_id}</td>
                                <td>{app?.tenure}</td>
                                <td>{app?.grace_period}</td>
                                <td>{app?.liscence_start_date}</td>
                                <td>{app?.liscence_end_date}</td>
                               </tr>
                            )
                        })
                    }
                </tbody>
            </table>
}


  return (
    <>
        <h2 className='org-modal-section-header'>
            App Information
        </h2>
        <div className='order-modal-section-body'>
        <div className='order-modal-content-row'>
            <label>
                Type of Licence
            </label>
            <p>
                {data?.liscence_type}
            </p>
            </div>
                    {renderTable(data)}
        
        </div>
    </>
  )
}

export default AppInformation
