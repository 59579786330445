// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-org-information-container{
    display: flex;
    gap: 0.625rem;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    /* height: 100%; */
    overflow: auto;
    width: 100%;
    max-height: 300px;
    padding: 1rem;
}

.order-org-information-container::-webkit-scrollbar{
    width: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Orders/CreateOrder/OrgInformation/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".order-org-information-container{\n    display: flex;\n    gap: 0.625rem;\n    align-items: flex-start;\n    justify-content: space-between;\n    flex-direction: column;\n    /* height: 100%; */\n    overflow: auto;\n    width: 100%;\n    max-height: 300px;\n    padding: 1rem;\n}\n\n.order-org-information-container::-webkit-scrollbar{\n    width: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
