import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./pages/Auth/login/loginSlice";
import commonConfigReducer from "./configSlice";
import userSliceReducer from "./pages/userProfile/userSlice";
import appStatusReducer from "./appStatusSlice";

export const store = configureStore({
  reducer: {
    isLoggedIn: loginReducer,
    commonConfig: commonConfigReducer,
    userDetails: userSliceReducer,
    appStatus: appStatusReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
