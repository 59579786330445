import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { IAppStoreData } from "../../../interfaces/appStore";
import { parseVersion, renderDisplayStatus } from "../../../utils/appstackCard";
import Carousel from "../../common/Carousel";

import "./styles.css";

type AppStackProps = {
    appData: IAppStoreData;
};

function AppStack({ appData }: AppStackProps) {
    let navigate = useNavigate();

    const goToDescription = () => {
        navigate(
          {
            pathname: "/config/description",
            search: `?id=${appData.id}`,
          },
          {
            state: appData,
          }
        );
    };


    const renderAppFlierBackground = (value:number)=>{
        console.log(value)
        switch(value){
            case 1:
                return "var(--tertiary-color)";
            case 2:
                return "var(--primary-color)";
            case 3:
                return "var(--global-success)";
            default:
                return "var(--global-error)";
        }
    }   



    return (
      <div
        className="app-stack-card app-stack-card-is-live"
        onClick={() => {
          goToDescription();
        }}
      >
        <div className="card-header">
          <div
            className="app-status-flier"
            style={{
              backgroundColor: renderAppFlierBackground(
                appData.awi_app_status.value
              ),
            }}
          >
            {renderDisplayStatus(appData.awi_app_status.value)}
          </div>
          <Carousel
            expandOnClick={false}
            media={{
              images: [...appData.awi_sample_images,...appData.awi_sample_fov_image],
              videos: appData.awi_demo_video,
            }}
            addBackdrop
            // autoplay
          />
        </div>
        <div className="card-body">
          <div className="card-body-header">
            <div className="card-logo-container">
              <img
                src={appData.awi_app_logo}
                alt="logo"
                className="card-logo"
              />
            </div>
            <div className="card-body-header-title">
              <h3 title={appData.awi_name}>
                {appData.awi_name.substring(0, 30)}
                {appData.awi_app_name.length > 30 && "..."}
              </h3>
              <p>{appData.awi_app_developer}</p>
              <span className="app-version-label">
                {" "}
                {`${parseVersion(appData.awi_app_version)}`}
              </span>
            </div>
          </div>
          <p className="app-description" title={appData.awi_description}>
            {appData.awi_description}
          </p>
        </div>
      </div>
    );
}

export default AppStack;
