import { defaultConfig } from "./defaults";

export interface Config {
  REACT_APP_BACKEND_URL: string | undefined;
  REACT_APP_SSO_URL: string | undefined;
  CREDIT_STEPPER: number;
  CREDIT_MIN_VALUE: number;
  CREDIT_MAX_VALUE: number;
}

let config: Config;

if (window && window.AwirosDeploymentConfig) {
  config = {
    REACT_APP_BACKEND_URL:
      window.AwirosDeploymentConfig.REACT_APP_BACKEND_URL ||
      defaultConfig.REACT_APP_BACKEND_URL,
    REACT_APP_SSO_URL:
      window.AwirosDeploymentConfig.REACT_APP_SSO_URL ||
      defaultConfig.REACT_APP_SSO_URL,

    // CREDIT_STEPPER: 1,
    // CREDIT_MIN_VALUE: 10,
    // CREDIT_MAX_VALUE: 100,

    CREDIT_STEPPER: window.AwirosDeploymentConfig.CREDIT_STEPPER || 1,
    CREDIT_MIN_VALUE: window.AwirosDeploymentConfig.CREDIT_MIN_VALUE || 10,
    CREDIT_MAX_VALUE: window.AwirosDeploymentConfig.CREDIT_MAX_VALUE || 100,
  };
} else {
  config = {
    ...defaultConfig,
  };
}

export { config };
