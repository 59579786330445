import { Routes, Route } from "react-router-dom";
import AppDescription from "../pages/appDescription";
import AppRegistration from "../pages/appRegistration";
import AppStack from "../pages/appStack";
// import Configuration from "../pages/configuration";
import CreateOrder from "../pages/createOrder";

import Login from "../pages/Auth/login";
import Logout from "../pages/Auth/logout";
import Organisation from "../pages/organisations";
// import AppOrchestrator from "../pages/orchestrator";
import UserProfile from "../pages/userProfile";
import Users from "../pages/users";
import ViewOrders from "../pages/viewOrders";
import RouteGuard from "./routeGuard";
import ForgotPassword from "../pages/Auth/forgotPassword";
import Signup from "../pages/Auth/signup";
import InviteUsers from "../pages/inviteUsers";
import UpdatePassword from "../pages/Auth/updatePassword";

function AppRouter() {
    return (
      <Routes>
        <Route path="/" element={<AppStack></AppStack>} />
        <Route
          path="/home"
          element={
            <RouteGuard>
              <AppStack></AppStack>
            </RouteGuard>
          }
        />
        <Route
          path="/my-apps"
          element={
            <RouteGuard>
              <AppStack></AppStack>
            </RouteGuard>
          }
        />
        <Route
          path="/in-review"
          element={
            <RouteGuard>
              <AppStack></AppStack>
            </RouteGuard>
          }
        />
        <Route
          path="register-app"
          element={
            <RouteGuard>
              <AppRegistration></AppRegistration>
            </RouteGuard>
          }
        />
        <Route path="view-orders" element={<ViewOrders></ViewOrders>} />
        <Route path="invite-users" element={<InviteUsers></InviteUsers>} />
        <Route path="edit-order/:id" element={<CreateOrder></CreateOrder>} />
        <Route path="/organisations" element={<RouteGuard></RouteGuard>}>
          <Route path="create-order" element={<CreateOrder></CreateOrder>} />

          <Route index element={<Organisation></Organisation>} />
        </Route>
        <Route path="users" element={<Users></Users>} />
        <Route path="/config" element={<RouteGuard></RouteGuard>}>
          <Route path="appstack" element={<AppStack></AppStack>} />
          <Route path="description" element={<AppDescription />} />
        </Route>
        <Route path="/user" element={<RouteGuard></RouteGuard>}>
          <Route path="profile" element={<UserProfile />}></Route>
        </Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/reset-password" element={<UpdatePassword></UpdatePassword>}></Route>
        <Route path="/logout" element={<Logout></Logout>}></Route>
      </Routes>
    );
}

export default AppRouter;
