// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.licence-dropdown-container{
    min-width: 20%;
}
.app-select-dropdown-container{
    /* width: calc(50% + 20rem); */
    flex-grow: 1;

}
.create-order-row-header{
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    position: relative;
}
.order-app-info{
    width: 100%;
    display: flex;
    gap: 12rem;
}
.copy-config-button{
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.paste-configs{
    position: absolute;
    right: 30px;
    font-weight: 300;
    cursor: pointer;
    font-size: 0.75rem;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Orders/CreateOrder/AppInformation/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,8BAA8B;IAC9B,YAAY;;AAEhB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,aAAa;IACb,UAAU;AACd;AACA;IACI,wBAAwB;IACxB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,0BAA0B;AAC9B","sourcesContent":[".licence-dropdown-container{\n    min-width: 20%;\n}\n.app-select-dropdown-container{\n    /* width: calc(50% + 20rem); */\n    flex-grow: 1;\n\n}\n.create-order-row-header{\n    font-size: 1.25rem;\n    font-weight: 700;\n    width: 100%;\n    position: relative;\n}\n.order-app-info{\n    width: 100%;\n    display: flex;\n    gap: 12rem;\n}\n.copy-config-button{\n    display: flex !important;\n    align-items: center;\n    justify-content: center;\n    gap: 1rem;\n}\n.paste-configs{\n    position: absolute;\n    right: 30px;\n    font-weight: 300;\n    cursor: pointer;\n    font-size: 0.75rem;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
