// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-input{
    position: relative;
    width: 100%;
    cursor: pointer;
    gap: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;


}

.two-level-dropdown{
    position: relative;
}

.first-dropdown-menu{
    position: absolute;
    top: 45px;
    left:-10px;
    width: 150px;
}

.dropdown-item-container{
    position: relative;
}

.second-dropdown-menu{
    position: absolute;
    top: 0;
    left: 100%;
    width: 200px;
}

.dropdown-container{
    box-shadow: 0px 2px 6px 2px rgba(31, 72, 126, 0.15), 0px 1px 2px 0px rgba(31, 72, 126, 0.30);
}

.multi-dropdown-item{
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    cursor: pointer;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/multiLevelDropdown/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;;;AAGrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,4FAA4F;AAChG;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,eAAe;IACf,WAAW;AACf","sourcesContent":[".dropdown-input{\n    position: relative;\n    width: 100%;\n    cursor: pointer;\n    gap: 0.625rem;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0.625rem;\n\n\n}\n\n.two-level-dropdown{\n    position: relative;\n}\n\n.first-dropdown-menu{\n    position: absolute;\n    top: 45px;\n    left:-10px;\n    width: 150px;\n}\n\n.dropdown-item-container{\n    position: relative;\n}\n\n.second-dropdown-menu{\n    position: absolute;\n    top: 0;\n    left: 100%;\n    width: 200px;\n}\n\n.dropdown-container{\n    box-shadow: 0px 2px 6px 2px rgba(31, 72, 126, 0.15), 0px 1px 2px 0px rgba(31, 72, 126, 0.30);\n}\n\n.multi-dropdown-item{\n    padding: 0.5rem;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    gap: 0.5rem;\n    cursor: pointer;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
