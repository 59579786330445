import { createSlice } from "@reduxjs/toolkit";

export interface UserSliceState {
    name:string,
    profilePicture:string,
    email:string,
    org_id:number[],
    api_key:string,
    role:string[]
}


const initialState: UserSliceState = {
    name: "",
    profilePicture: "",
    email: "",
    org_id: [],
    api_key: "",
    role: []
};

export const UserSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        setUserSuccess: (state,action) => {
            state.name = action.payload.name
            state.profilePicture = action.payload.profile_picture
            state.email = action.payload.email
            state.org_id = action.payload.org_id
            state.api_key = action.payload.api_key
            state.role = action.payload.role
            
        },
        setUserFail: (state) => {
            state.name = initialState.name
            state.profilePicture = initialState.profilePicture
            state.email = initialState.email
            state.org_id = initialState.org_id
            state.api_key = initialState.api_key
            state.role = initialState.role
        }
    }
});

// Action creators are generated for each case reducer function
export const { setUserSuccess, setUserFail } = UserSlice.actions;

export default UserSlice.reducer;
