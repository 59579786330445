import { useEffect, useRef, useState } from "react";
import Icon from "../../Icons";

import Image from "../image";
import "./styles.css";

type carouselProps = {
  expandOnClick?: boolean;
  media?: {
    images: string[];
    videos: string[];
  };
  videoFirst?: boolean;
  addBackdrop?: boolean;
  autoplay?: boolean;
  duration?: number;
}


function Carousel({ expandOnClick, media,videoFirst,addBackdrop,autoplay,duration }: carouselProps) {
  
  const [currentImage, setCurrentImage] = useState(0); 
  const [mediaList,setMediaList] = useState<string[]>([]);
  const [isVideoPlaying,setIsVideoPlaying] = useState(false);
  useEffect(() => {
    let tempMediaList:string[] = [];
    if(videoFirst){
      if(media)
      tempMediaList = media.videos.concat(media.images);
    }
    else{
      if(media)
      tempMediaList = media.images.concat(media.videos);
    }
    setMediaList(tempMediaList);
  },[media]);

  useEffect(() => {
    if(autoplay){
      const interval = setInterval(() => {
        setCurrentImage((currentImage + 1) % mediaList.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [currentImage,autoplay,mediaList]);


  const videoRef = useRef<HTMLVideoElement>(null)

  const handleVideoPlay = (e:any) => {
  
    if(videoRef.current){
      
      if(isVideoPlaying){
        videoRef.current.pause();
      }
      else{
        
        videoRef.current.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
    
    // e.target.play();
    e.preventDefault();
    e.stopPropagation();
  }

  const handleVideoClick = (e:any) => {
    e.stopPropagation();
  }

  const renderCarousel = () => {
    return mediaList.map((mediaItem, index) => {
      if (index === currentImage) 
      return (
         (
        <div
          className={`carousel-item ${
            index === currentImage ? "carousel-item-active" : ""
          } ${autoplay && "autoplay-carousel-item"}`}
          key={index}
        >{
          mediaItem.includes("mp4")? 
          <div className="video-container" >
            {  
              !isVideoPlaying &&
              <button className="video-play-icon" onClick={handleVideoPlay}>
              </button>
            }
            <video controls className={`carousel-item ${
                index === currentImage ? "carousel-item-active" : ""
              }`} onClick = {handleVideoPlay} ref = {videoRef} >
              <source src={mediaItem} type="video/mp4"/>
              
            </video>
          </div>:
          <Image
            src={mediaItem}
            alt="carousel"
            expandOnClick={expandOnClick}
            showBackdrop={addBackdrop}
            className={`carousel-item ${
            index === currentImage ? "carousel-item-active" : ""
          }`}
                    
          />
        }
        </div>
        )
      );
      return null;
    });
  }

  const handleCarouselMediaChange = (e:any,direction: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (direction === "next") {
      if (currentImage === mediaList.length - 1) {
        setCurrentImage(0);
      } else {
        setCurrentImage(currentImage + 1);
      }
    } else {
      if (currentImage === 0) {
        setCurrentImage(mediaList.length - 1);
      } else {
        setCurrentImage(currentImage - 1);
      }
    }
  }

  return (
    <div className="carousel">
      
      {renderCarousel()} 
      <div className="carousel-control-container left-control">
      <button className={`carousel-control`}  onClick = {(e) => handleCarouselMediaChange(e,"next")}> 
        <Icon src="/assets/icons/arrow-backward.svg" className="carousel-control-icon" onClick = {(e) => handleCarouselMediaChange(e,"next")}></Icon>
      </button>
      </div>
      <div className="carousel-control-container right-control">
      <button className={`carousel-control`}  onClick = {(e) => handleCarouselMediaChange(e,"previous")}> 
          <Icon src="/assets/icons/arrow-forward.svg" className="carousel-control-icon"></Icon>
      </button>
      </div> 
    </div>
)
}

export default Carousel