// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-table-container{
    height: 100%;
    /* width: 100%; */
    margin: 0.625rem 70px;
    border-radius: 0.75rem;
    background-color: var(--theme-color);
    color: var(--primary-color);
    display: grid;
    gap: 1.25rem;
}
.user-table-wrapper{
    padding: 1rem;
}

.user-search-container{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* justify-content: stretch; */
    width: 50%;
}
.user-chip{
   margin: 1rem ;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,qBAAqB;IACrB,sBAAsB;IACtB,oCAAoC;IACpC,2BAA2B;IAC3B,aAAa;IACb,YAAY;AAChB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,8BAA8B;IAC9B,UAAU;AACd;AACA;GACG,aAAa;AAChB","sourcesContent":[".users-table-container{\n    height: 100%;\n    /* width: 100%; */\n    margin: 0.625rem 70px;\n    border-radius: 0.75rem;\n    background-color: var(--theme-color);\n    color: var(--primary-color);\n    display: grid;\n    gap: 1.25rem;\n}\n.user-table-wrapper{\n    padding: 1rem;\n}\n\n.user-search-container{\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    /* justify-content: stretch; */\n    width: 50%;\n}\n.user-chip{\n   margin: 1rem ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
