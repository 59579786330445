// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icons-container{
    padding: 10px;
    background: var(--surface-3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.icons-container >img{
    height: 100%;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Icons/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".icons-container{\n    padding: 10px;\n    background: var(--surface-3);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.icons-container >img{\n    height: 100%;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
