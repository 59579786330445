import { createSlice } from "@reduxjs/toolkit";

export interface LoginState {
  value: boolean;
  role: {
    exp: number;
    iat: number;
    id: string;
    role: string[];
  };
}

const initalRoleObject = {
  exp: 0,
  iat: 0,
  id: "",
  role: [],
};

const initialState: LoginState = {
  value: localStorage.getItem("token") ? true : false,
  role: localStorage.getItem("token")
    ? JSON.parse(window?.atob(localStorage?.getItem("token")?.split(".")[1]!))
    : initalRoleObject,
};

export const loginSlice = createSlice({
  name: "isLoggedIn",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      console.log(action.payload, "actincalled");
      state.value = true;
      state.role = action.payload || initalRoleObject;
    },
    loginFail: (state) => {
      state.value = false;
      state.role = initalRoleObject;
    },
    roleSuccess: (state, action) => {
      state.role = action.payload || initalRoleObject;
      state.role.id = action.payload["_id"] || null;
    },
    roleFail: (state) => {
      state.role = initalRoleObject;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, loginFail, roleSuccess, roleFail } =
  loginSlice.actions;

export default loginSlice.reducer;
