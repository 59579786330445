import { createSlice } from "@reduxjs/toolkit";

export interface CommonConfigState {
    selectedTheme: string;
}

const initialState: CommonConfigState = {
    selectedTheme: "light"
};

export const commonConfigSlice = createSlice({
    name: "commonConfig",
    initialState,
    reducers: {
        selectLightTheme: (state) => {
            state.selectedTheme = "light";
        },
        selectDarkTheme: (state) => {
            state.selectedTheme = "dark";
        }
    }
});

// Action creators are generated for each case reducer function
export const { selectDarkTheme, selectLightTheme } = commonConfigSlice.actions;

export default commonConfigSlice.reducer;
