// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organisation-table{
    width: 100%;
    text-align: left;
    overflow: auto;
}
.organisation-table-actions{
    display: flex;
    gap: 0.75rem;
    align-items: center;
    
    /* justify-content: center; */
}
.organisation-table-actions >button {
    padding: 0.5rem 1.25rem;
    font-size: 0.625rem;
    box-shadow: 0px 1px 2px rgba(31, 72, 126, 0.3), 0px 1px 3px 1px rgba(31, 72, 126, 0.15);
}
.org-order-cell{
    text-decoration: underline ;
    font-size: 1rem;
    /* border-bottom: 1px solid var(--primary-color); */
}`, "",{"version":3,"sources":["webpack://./src/components/Organisations/OrganisationTable/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;;IAEnB,6BAA6B;AACjC;AACA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,uFAAuF;AAC3F;AACA;IACI,2BAA2B;IAC3B,eAAe;IACf,mDAAmD;AACvD","sourcesContent":[".organisation-table{\n    width: 100%;\n    text-align: left;\n    overflow: auto;\n}\n.organisation-table-actions{\n    display: flex;\n    gap: 0.75rem;\n    align-items: center;\n    \n    /* justify-content: center; */\n}\n.organisation-table-actions >button {\n    padding: 0.5rem 1.25rem;\n    font-size: 0.625rem;\n    box-shadow: 0px 1px 2px rgba(31, 72, 126, 0.3), 0px 1px 3px 1px rgba(31, 72, 126, 0.15);\n}\n.org-order-cell{\n    text-decoration: underline ;\n    font-size: 1rem;\n    /* border-bottom: 1px solid var(--primary-color); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
