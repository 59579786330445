// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: sticky;
    bottom: 2rem;
}

.paginate-ctn {
    display: flex;
    gap: 0.625rem;
    justify-content: space-between;
    align-items: center;
}
.round-effect {
    color:  var(--primary-color);
    cursor: pointer;
    background-color:  var(--surface);
    font-size: 0.75rem;
    text-align: center;
    padding: 2px;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    margin: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-decoration: none;
}

.round-effect:hover {
    text-decoration: none;
    background: var(--primary-color);
    color: var(--theme-color);
}
.round-effect:hover a {
    text-decoration: none;
    color:  var(--theme-color);
}

.active {
    background: var(--primary-color);
    color:  var(--theme-color);
}
a:link {
    text-decoration: none;
    color: var(--nuetral);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/pagination/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,4BAA4B;IAC5B,eAAe;IACf,iCAAiC;IACjC,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,YAAY;IACZ,wEAAwE;IACxE,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,gCAAgC;IAChC,yBAAyB;AAC7B;AACA;IACI,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,gCAAgC;IAChC,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,qBAAqB;AACzB","sourcesContent":[".flex-container {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    position: sticky;\n    bottom: 2rem;\n}\n\n.paginate-ctn {\n    display: flex;\n    gap: 0.625rem;\n    justify-content: space-between;\n    align-items: center;\n}\n.round-effect {\n    color:  var(--primary-color);\n    cursor: pointer;\n    background-color:  var(--surface);\n    font-size: 0.75rem;\n    text-align: center;\n    padding: 2px;\n    border-radius: 50%;\n    height: 1.5rem;\n    width: 1.5rem;\n    margin: auto;\n    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);\n    text-decoration: none;\n}\n\n.round-effect:hover {\n    text-decoration: none;\n    background: var(--primary-color);\n    color: var(--theme-color);\n}\n.round-effect:hover a {\n    text-decoration: none;\n    color:  var(--theme-color);\n}\n\n.active {\n    background: var(--primary-color);\n    color:  var(--theme-color);\n}\na:link {\n    text-decoration: none;\n    color: var(--nuetral);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
