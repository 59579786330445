
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginFail } from "../login/loginSlice";
import { setUserFail } from "../../userProfile/userSlice";

function Logout() {

    const navigator = useNavigate();
    const dispatch = useDispatch();
    
    const navigateToLogin = () => {
        navigator("/");
    };
    useEffect(()=>{
        dispatch(setUserFail())
        dispatch(loginFail())
        localStorage.removeItem('token')
        navigateToLogin()
    },[])
  return (
    <div>Logout</div>
  )
}

export default Logout