import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../store";

type RouteProps = { children?: JSX.Element };

function RouteGuard({ children }: RouteProps) {
    const isLoggedIn = useSelector(
        (state: RootState) => state.isLoggedIn
    ).value;

    
    if (!isLoggedIn) {
        return <Navigate to={"/login"} replace />;
    }

    return children ? children : <Outlet></Outlet>;
}

export default RouteGuard;
