export const gelAllTerminalsResponse = {
    success: true,
    msg: "Terminals found",
    data: [
        {
            _id: "62989da5f644b03484eae50b",
            id: 3,
            demo_status: false,
            awi_cam_list: 7,
            time: {
                created: "2022-06-02T11:23:17.177Z",
                active_since: "2022-06-02T11:23:17.177Z",
                updated: "2022-08-17T05:31:36.635Z",
                sessions: [
                    {
                        logout: "2022-06-02T11:23:17.177Z",
                        login: "2022-06-02T11:23:17.177Z"
                    }
                ]
            },
            status: true,
            serial_num: "AWI-41688177",
            machine_id: "e488caf39c6545c7a1b362d4377f6e35",
            location: "Awiros",
            terminal_credits: 1000,
            terminal_static_data: { gpu_details: [] },
            awi_pod_list: [],
            awi_running_apps: [],
            awi_not_running_apps: [
                {
                    _id: "6298a08fe77585cb0c494225",
                    awi_app: {
                        awi_name: "Licence Plate Recognition",
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_app_id: "16",
                        awi_app_credits: 100,
                        awi_app_API_key: "34",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        color: "#3ECAA9"
                    },
                    awi_camid: 5,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "awi_vid_1",
                    priority: 0,
                    status: false
                },
                {
                    _id: "6298a8dab594a7b8a0a8f36f",
                    awi_app: {
                        awi_name: "Licence Plate Recognition",
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_app_id: "16",
                        awi_app_credits: 100,
                        awi_app_API_key: "34",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        color: "#3ECAA9"
                    },
                    awi_camid: 9,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "awi_vid_2",
                    priority: 0,
                    status: false
                },
                {
                    _id: "6298a8dab594a7b8a0a8f371",
                    awi_app: {
                        color: "#3ECAA9",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        awi_app_credits: 100,
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_name: "Licence Plate Recognition",
                        awi_app_id: "16",
                        awi_app_API_key: "34"
                    },
                    awi_camid: 10,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "awi_vid_3",
                    priority: 0,
                    status: false
                },
                {
                    _id: "6298a8dab594a7b8a0a8f372",
                    awi_app: {
                        color: "#3ECAA9",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        awi_app_credits: 100,
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_name: "Licence Plate Recognition",
                        awi_app_id: "16",
                        awi_app_API_key: "34"
                    },
                    awi_camid: 11,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "awi_vid_4",
                    priority: 0,
                    status: false
                },
                {
                    _id: "6298a8dab594a7b8a0a8f373",
                    awi_app: {
                        color: "#3ECAA9",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        awi_app_credits: 100,
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_name: "Licence Plate Recognition",
                        awi_app_id: "16",
                        awi_app_API_key: "34"
                    },
                    awi_camid: 12,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "awi_vid_5",
                    priority: 0,
                    status: false
                },
                {
                    _id: "6298a8dab594a7b8a0a8f374",
                    awi_app: {
                        color: "#3ECAA9",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        awi_app_credits: 100,
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_name: "Licence Plate Recognition",
                        awi_app_id: "16",
                        awi_app_API_key: "34"
                    },
                    awi_camid: 13,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "awi_vid_6",
                    priority: 0,
                    status: false
                },
                {
                    _id: "6299b581b594a7b8a0a9f1db",
                    awi_app: {
                        color: "#3ECAA9",
                        icon: "http://192.168.0.3:3000/uploads/Group 576.png",
                        awi_app_credits: 100,
                        awi_app_name: "awi_licence_plate_recognition",
                        awi_name: "Licence Plate Recognition",
                        awi_app_id: "16",
                        awi_app_API_key: "34"
                    },
                    awi_camid: 24,
                    __v: 0,
                    awi_client_id: 2,
                    awi_container_id: "none",
                    awi_terminal_id: 3,
                    location: "ANPR_CAM_1",
                    priority: 0,
                    status: false
                }
            ],
            site: [{ s_name: "Awiros", s_id: 4 }]
        }
    ]
};
