export const listOfTargetIndustries = {
    data: [
        { id: 48957, awi_name: "Warehousing and Logistics" },
        { id: 48958, awi_name: "Smart Cities" },
        { id: 48959, awi_name: "Safety and Security" },
        { id: 48960, awi_name: "Smart Homes" },
        { id: 48961, awi_name: "Defence" },
        { id: 48962, awi_name: "Municipalities" },
        { id: 48964, awi_name: "Manufacturing" },
        { id: 48956, awi_name: "Retail" },
        { id: 48963, awi_name: "Traffic Management" }
    ]
};
