// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 45px;
    flex-grow: 1;
    min-width: 500px;
    justify-content: center;
}
.form-header-container {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}
.form-header {
    margin: 0px;
    font-weight: 300;
    font-size: 1.875rem;
}
.form-description {
    margin: 0px;
    font-weight: 300;
    font-size: 0.875rem;
}
.form-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding-right: 150px;
}

.two-column-field-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
}

.two-column-field-container > div {
    flex-grow: 1;
}

.required-mark {
    color: red;
    font-size: 15px;
}
.form-link {
    font-weight: 400;
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: none;
}
.form-footer {
}
.login-button {
    /* width: 10%; */
    padding: 0.75rem 1.25rem;
    font-size: 13px;
    text-transform: uppercase;
    /* height: 1.875rem; */
}
.footer-text {
    margin-top: 1.875rem;
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Auth/updatePasswordForm/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;;IAET,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,qBAAqB;IACrB,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;AACA;AACA;AACA;IACI,gBAAgB;IAChB,wBAAwB;IACxB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;AAC1B;AACA;IACI,oBAAoB;IACpB,eAAe;AACnB","sourcesContent":[".login-form {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n    gap: 45px;\n    flex-grow: 1;\n    min-width: 500px;\n    justify-content: center;\n}\n.form-header-container {\n    display: flex;\n    flex-direction: column;\n    gap: 0.625rem;\n}\n.form-header {\n    margin: 0px;\n    font-weight: 300;\n    font-size: 1.875rem;\n}\n.form-description {\n    margin: 0px;\n    font-weight: 300;\n    font-size: 0.875rem;\n}\n.form-fields {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n\n    padding-right: 150px;\n}\n\n.two-column-field-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    gap: 50px;\n}\n\n.two-column-field-container > div {\n    flex-grow: 1;\n}\n\n.required-mark {\n    color: red;\n    font-size: 15px;\n}\n.form-link {\n    font-weight: 400;\n    color: var(--primary-color);\n    text-decoration: none;\n    cursor: pointer;\n    border: none;\n    background: none;\n}\n.form-footer {\n}\n.login-button {\n    /* width: 10%; */\n    padding: 0.75rem 1.25rem;\n    font-size: 13px;\n    text-transform: uppercase;\n    /* height: 1.875rem; */\n}\n.footer-text {\n    margin-top: 1.875rem;\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
