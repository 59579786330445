import { client } from "./axiosInstance";



const getUserRole = async (payload:any)=>{
    try {
        let response = await client <any> ("/role", {
        baseURL:"base",
        method: "GET",
        params: payload
    })


    if (response.status ===200){

        console.log(response?.data?.role)
        return response?.data|| []
    }
    } catch (error) {
    }
}

const getUser = async(payload:any) => {
    try {
        let response = await client <any> ("/user", {
        baseURL:"base",
        method: "GET",
        params: payload
    })


    if (response.status ===200){
        return response.data.user
    }
    } catch (error) {
    }
};

const getAllUsers = async(payload?:any) =>{
    try{
        let response = await client<any> (
            "/get-all-users",{
            baseURL:"base",
            method: "GET",
            params: payload
        
            }
        )
        if(response.status ===  200){
            return response.data.data
        }
    }
    catch(error){
    }
}

const getUserByOrg =async (payload:any) => {
    try{
        let response = await client<any>(
            "/org-users",{
                baseURL:"base",
                method:"GET",
                params:payload
            }
        )

        if(response.status === 200){
            return response.data.user
        }
    }
    catch(error){

    }
}

const updateProfilePicture = async (payload:any) => {
    try{
        let response = await client<any>(
            "/user/profile-picture",{
                baseURL:"base",
                method:"PATCH",
                data:payload
            }
        )

        if(response.status === 200){
            return response.data
        }
    }
    catch(error){
    }
}

const bulkInviteUser = async (payload:any) => {
    try{
        let response = await client<any>(
            "/user/bulk-invite",{
                baseURL:"base",
                method:"POST",
                data:payload
            }
        )
        console.log(response)
        if(response.status === 200){
            console.log(response)
            return response
        }
    }
    catch(error){
        console.log(error)
    }
}

export {
  getUser,
  getAllUsers,
  getUserByOrg,
  updateProfilePicture,
  getUserRole,
  bulkInviteUser,
};
