import { toast } from "react-toastify";
import { client } from "./axiosInstance";

export const toggleStatus = async ({
  appId,
  enable,
}: {
  appId: number;
  enable: boolean;
}) => {
  const payload = {
    appId,
    enable,
  };
  try {
    const response = await client("/status", {
      baseURL: "hydra",
      method: "POST",
      data: payload,
    });
    toast.success(response?.data?.message);
    return response?.data?.success;
  } catch (error) {
    toast.error("Unable to change status");
  }
};
