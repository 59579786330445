import { config } from "./config";

export const rootURL = config.REACT_APP_BACKEND_URL;
export const authBaseURL = config.REACT_APP_SSO_URL;

export const baseURL = `${rootURL}/api`;
export const authURL = `${authBaseURL}/auth`;
export const utilURL = `${rootURL}/util`;
export const signupURL = `${rootURL}/auth/signup`;
export const resetLinkGenerateURL = `${rootURL}/auth/generate-reset-token`;
export const hydraURL = `${rootURL}/hydra`;

export const statusIndicatorFlags = [
  {
    labelText: "Running",
    statusColor: "#588157",
  },
  {
    labelText: "Scheduled",
    statusColor: "#A3B18A",
  },
  {
    labelText: "Configured",
    statusColor: "#F6BD60",
  },
  {
    labelText: "Enabled",
    statusColor: "#FDF5D6",
  },
  {
    labelText: "Error",
    statusColor: "#EF767A",
  },
];

export const currentTheme = {
  dark: "false",
};

export const lightThemeColors = {
  backgroundColor: "#ffffff",
  secondaryBackgroudColor: "#f0f0f0",
  secondaryTextColor: "#808080",
  primaryTextColor: "#2d2d2d",
};

export const canvasColorOptions = {
  CANVAS_POINT_COLOR: "#FF5733",
  CANVAS_SAVED_LINE_DRAW_COLOR: "#3358FF",
  CANVAS_SAVED_REGION_DRAW_COLOR: "#46FF33",
  CANVAS_SAVED_MASK_DRAW_COLOR: "#FFE033",
  CANVAS_SCALING_FACTOR: 1.333333333333333,
  CANVAS_BACKGROUND: "#e9e9e9",
};

export const darkThemeColors = {
  backgroundColor: "#252525",
  secondaryBackgroudColor: "#494949",
  secondaryTextColor: "#808080",
  primaryTextColor: "#fcfcfc",
};
