import React from "react";
import "./Tooltip.css"; // Import the CSS for the tooltip

type TooltipProps = {
  content: string;
  children: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
  return (
    <div className='tooltip-container'>
      {children}
      <span className='tooltip-text'>{content}</span>
    </div>
  );
};

export default Tooltip;
