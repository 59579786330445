import { toast } from "react-toastify";
import {
  appStackDevelopers,
  AppStore,
  filteredAppStack,
} from "../exampleResponses/appStore";

import { client } from "./axiosInstance";

export const getAppStore = async (queryItems?: any) => {
  let url = "/apps";
  let response = await client<any>(url, {
    baseURL: "base",
    method: "GET",
    params: queryItems,
  });
  if (response.status === 200) {
    return await response.data.app_response;
  } else toast.error("Data could not be fetched");

  return response;
};

export const getMyAppStore = async (queryItems?: any) => {
  let url = "/developer/apps";
  let response = await client<any>(url, {
    baseURL: "base",
    method: "GET",
    params: queryItems,
  });
  if (response.status === 200) {
    return await response.data.apps;
  } else toast.error("Data could not be fetched");

  return response;
};

export const getAppStoreDevelopers = () => {
  let response = appStackDevelopers?.data;

  return response;
};

export const getFilteredApps = async () => {
  let response = await client<any>("/apps", {
    baseURL: "base",
    method: "GET",
  });
  if (response.status === 200) {
    return await response.data.app_response;
  } else toast.error("Data could not be fetched");

  return response;
};

export const getAppStatus = async (test?: boolean) => {
  // if(test){
  //     return [
  //         {
  //             key:"In Progress",
  //             value: 1
  //         },
  //         {
  //             key:"Completed",
  //             value: 2
  //         },
  //         {
  //             key:"Pending",
  //             value: 3
  //         },
  //         {
  //             key:"On Hold",
  //             value: 4
  //         },
  //         {
  //             key:"Cancelled",
  //             value: 5
  //         },
  //         {
  //             key:"Rejected",
  //             value: 6
  //         }
  //     ]
  // }
  let response = await client<any>("/app/status", {
    baseURL: "base",
    method: "GET",
  });
  console.log(response, "app status");
  if (response.status === 200) {
    return await response.data.appStatus;
  } else toast.error("Data could not be fetched");

  return response;
};

export const updateAppStatus = async (appID: string, status?: string) => {
  try {
    let response = await client<any>(`/app/${appID}/status`, {
      baseURL: "base",
      method: "PUT",
      data: {
        status,
      },
    });
    if (response.status === 200) {
      return await response.data.app;
    }

    return response;
  } catch (error: any) {
    console.log(error, "error");
    toast.error(error?.response?.data?.errors[0] || "Something went wrong");
  }
};

export const getAppFromID = async (appID: string) => {
  let response = await client<any>(`/app`, {
    baseURL: "base",
    method: "GET",
    params: {
      appId: appID,
    },
  });
  if (response.status === 200) {
    return await response.data.app;
  } else toast.error("Data could not be fetched");

  return response;
};

export const getAppVersioningDetailsFromID = async (appID: any) => {
  let response = await client<any>(`/app-version-details`, {
    baseURL: "base",
    method: "POST",
    data: {
      appId: appID,
    },
  });
  if (response.status === 200) {
    return await response.data.app;
  } else toast.error("Data could not be fetched");

  return response;
};

export const publishApp = async (
  appID: string,
  status: boolean,
  reason: string
) => {
  try {
    let response = await client<any>(`/app/publish`, {
      baseURL: "base",
      method: "POST",
      data: {
        appID,
        status,
        reason,
      },
    });
    if (response.status === 200) {
      return await response.data.app;
    }

    return response;
  } catch (error: any) {
    console.log(error, "error");
    toast.error(error?.response?.data?.errors[0] || "Something went wrong");
  }
};
