import './styles.css'
type TooltipProps = {
    children?: React.ReactNode;
    tooltip: string;
    top?: string;
    right?: string;
}
function Tooltip({children,tooltip,top,right}:TooltipProps) {
  return (
    <img src='/assets/icons/caution.svg' className="info-tag" title={tooltip} alt = {"caution-icon"} style = {{top:top,right:right}}></img>
  )
}

export default Tooltip