import { toast } from "react-toastify";

import { client } from "./axiosInstance";


export const createOrganisation = async (payload: any) => {
    try {
        let response = await client <any> ("/create-org", {
        baseURL:"base",
        method: "POST",
        data: payload
    })

    
    if (response.status ===200){
        toast.success(response.data.message)

        return await response.data
    }
    } catch (error:any) {
        toast.error(error.response.data.message)
        }
    }
    
export const getOrgData = async () => {
    try {
        let response = await client <any> ("/orgs", {
        baseURL:"base",
        method: "GET",
        
    })

    
    if (response.status ===200){
        
        return await response.data.organisations
    }
    } catch (error) {
        toast.error("Could not org data")
    }
}

export const generateToken = async (payload:any) => {
    try {
        let response = await client <any> ("/create-user-token",{
            baseURL:"base",
            method:"POST",
            data:payload
        })
        if(response.status === 200){
            return response.data.data.token
        }
    }
    catch(error){
        toast.error('Could not create token')
    }
}