// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-textarea-container{
    height: 10rem;
}

.textarea-input{
    height: 100%;
    resize: none;
}
textarea::-webkit-scrollbar{
    /* display: none; */
    width: 1px;
    

}
`, "",{"version":3,"sources":["webpack://./src/components/common/textarea/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,UAAU;;;AAGd","sourcesContent":[".common-textarea-container{\n    height: 10rem;\n}\n\n.textarea-input{\n    height: 100%;\n    resize: none;\n}\ntextarea::-webkit-scrollbar{\n    /* display: none; */\n    width: 1px;\n    \n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
