import React, { ChangeEvent, useEffect } from 'react'
import { formValidator } from '../../../../utils/formValidator'
import Input from '../../../common/Input'


type Props = {
    state: any,
    stage: number,
    completeStage: (stage: number) => void,
    errorObject: any
    handleErrorObjectChange: (e: any) => void,
    handleInputChange:(e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void
}

function OrderDetails({state,stage,completeStage,errorObject,handleErrorObjectChange,handleInputChange}:Props) {

  useEffect(() => {
        let tempLocalErrors = formValidator(state,["deal_id","order_number","quotation_number","invoice_number","invoice_address"]) 
        if(Object.values(tempLocalErrors).every((v) => !v)){
            completeStage(stage)

            handleErrorObjectChange(tempLocalErrors)
        }
        else{
            completeStage(stage-1)
        }
    },[state])
  return (
    <div className='order-org-information-container'>
        <div className="create-order-row-item">
			<Input
              value={state.deal_id}
              placeholder="Deal ID"
              onChange={(e) => handleInputChange(e, "deal_id")} 
              type={'text'}
              required
              hideSearch				
              ></Input>				
			<Input
              value={state.order_number}
              placeholder="PO Number"
              onChange={(e) => handleInputChange(e, "order_number")} type={'text'}
              required
              hideSearch
              ></Input>
			<Input
              value={state.quotation_number}
              placeholder="Quotation Number"
              onChange={(e) => handleInputChange(e, "quotation_number")} type={'text'}
              required
              hideSearch
              ></Input>
        </div>
        <div className="create-order-row-item">
			<Input
              value={state.invoice_number}
              placeholder="Invoice Number"
              onChange={(e) => handleInputChange(e, "invoice_number")} type={'text'}
              required
              hideSearch
              ></Input>
              <Input
              value={state.invoice_address}
              placeholder="Invoice Billed To "
              onChange={(e) => handleInputChange(e, "invoice_address")} type={'text'}
              required
              hideSearch
              ></Input>
		</div>
    </div>
  )
}

export default OrderDetails