import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { generateToken } from '../../../services/organisation'

import Button from '../../common/button'
import Input from '../../common/Input'
import MultiSelectDropdown from '../../common/multiSelectDropdown'



import "./styles.css"

type TokenModalProps =
{
    show:boolean,
    toggleModal: ()=>void
    org_id: number
}  

const Roles = ["admin","developer","engineer","requester","approver"]

function TokenModal({show,toggleModal,org_id}:TokenModalProps) {
  
    const [loading,setLoading] = useState(false)      
    const [tokenCount,setTokenCount] = useState("")
    const [selectedRole,setSelectedRole] = useState("")
    const [tokens,setTokens] = useState<any>()

    const handleGenerateToken = async () => {
    setLoading(true)
    const payload = {
        token_type:selectedRole,
        org_id:org_id,
        quantity:tokenCount
    }
    const tokenReponse = await generateToken(payload)
    tokenReponse && setTokens(tokenReponse)
    setLoading(false)
        
  }
  const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setTokenCount(e.target.value)
  }

  const handleDropdownChange = (e: any, data: string[])=>{        
        setSelectedRole(data[0]);
    }

    const copyToken = (e:any)=>{
        
        let temp_tokens = tokens.map((token:any)=>{

            return {
                "user-token":token
            }
        })

        navigator.clipboard.writeText(JSON.stringify(temp_tokens))
        toast.success('token copied to clipboard!')
    }

  const renderModal = () =>{
    if (show){
    return (
        <div>
             <div className='modal-backdrop' onClick={toggleModal}></div>
             <div className='modal-container-wrapper'>
             <div className='modal-container'>
            <h1>
                Generate Tokens
            </h1>
            <div className='modal-content'>
                <div className='modal-content-row'>
                
                <MultiSelectDropdown 
                    placeholder={'Select Role'} 
                    changeHandler={handleDropdownChange} 
                    multiselect={false}
                    options = {Roles}                
                ></MultiSelectDropdown>
                </div>
                <div className='modal-content-row'>
                
                <Input 
                    placeholder={'Token Count'} 
                    onChange={handleInputChange}
                    value = {tokenCount}
                    type="number"
                    min={1}
                    max = {5} 
                    hideSearch
                ></Input>
                </div>
            </div>
            <div className='modal-content'>
                <Button 
                    className='create-org-button'
                    title='Maximum of 5 tokens can be generated at once' 
                    onClick = {handleGenerateToken} disabled = {loading || +tokenCount<1 || +tokenCount > 5||!selectedRole}>
                    Generate Tokens
                </Button>
            </div>
            {tokens && <hr className='modal-content-divider'></hr>}
            {tokens && <div className='modal-subcontent modal-content '>
                <div className='modal-subcontent-header'>
                <h3 className='modal-subheading'>
                Generated {selectedRole.toUpperCase()} Tokens
                </h3>
                <div className='copy-tokens-container'>
                <FontAwesomeIcon className='copy-token-icon' icon={faCopy} onClick = {copyToken}></FontAwesomeIcon>
                    Copy 
                </div>
                </div>
                {tokens?.map((token:string,idx:number)=>{
                    return(
                        <div className='modal-content-row'>
                        <label>
                            Token - {idx+1}
                        </label>
                        <input
                            // placeholder={'Select no.of tokens'} 
                            value = {token}
                            disabled = {true}
                            className = "token-modal-input"
                            // onFocus={copyToken}
                        >
                        </input>
                        </div>
                    )
                })}
                
            </div>}
            </div>
            </div>
        </div>
    )}
    else{
        return <div></div>
    }
  }
    return (

        renderModal()
    
  )
}

export default TokenModal