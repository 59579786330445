// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-tag{
    position: absolute;
    top: 10px;
    right: 0;
    /* border: 2px solid var(--primary-color); */
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/components/common/tooltip/styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,4CAA4C;IAC5C,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".info-tag{\n    position: absolute;\n    top: 10px;\n    right: 0;\n    /* border: 2px solid var(--primary-color); */\n    border-radius: 50%;\n    height: 1.5rem;\n    width: 1.5rem;\n    font-size: 1rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    font-weight: 900;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
