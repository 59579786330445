import React, { useEffect, useState } from "react";

import FormInput from "../../common/formInput";
import {verifyEmail } from "../../../utils/formValidator";

import "./styles.css";
import { ILoginFormProps } from "../../../interfaces/loginFormProps";
import {
    ForgotPasswordErrorObject,
    ForgotPasswordForm
} from "../../../typings/userLogin";
import Button from "../../common/button";
import { generateResetPasswordToken} from "../../../services/auth";
import { useNavigate } from "react-router-dom";


const ForgotPassword: React.FC<ILoginFormProps> = ({ handleActiveForm }) => {
    const [loginData, setLoginData] = useState<ForgotPasswordForm>({
        Email: null,
    });

    const [errorObject, setError] = useState<ForgotPasswordErrorObject>({
        // Email: null,
        // Password: null,
        // Captcha: null,
        Email: null,
    });
    
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token") ?? "";
        setLoginData((prevState) => ({
            ...prevState,
            Token: token
        }));
    }, [ setLoginData]);

    const navigator = useNavigate();

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName: string
    ) => {
        let tempInputData: any = { ...loginData };
        tempInputData[fieldName] = e.target.value;
        setLoginData(tempInputData);
        
    };

    const handleFormChange = (
        e: React.MouseEvent<HTMLButtonElement>,
        value: string
    ) => {

        e.preventDefault();
        handleActiveForm(value);
    };

    const handleForgotPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const updatedErrors = {...errorObject} 
        updatedErrors.Email = verifyEmail(loginData.Email??"");

        setError(updatedErrors);
        if(Object.values(updatedErrors).every((v) => v === false)){
            const response = await generateResetPasswordToken(loginData);
            if (response){
                handleFormChange(e, "Login");
                navigator("/login",{replace:true});
            }
            setLoginData({
                Email: '',
            });
        }
    };

    return (
        <div className="login-form forgot-password-form">
            <div className="form-header-container">
                <h1 className="form-header">Forgot Password</h1>
                <p className="form-description">
                    Enter your registered email address and we will send you a link to reset your password.
                </p>
            </div>

            <div className="form-fields">
                <FormInput
                    
                    placeHolder="Email"
                    changeHandler={handleInputChange}
                    type="text"
                    required={true}
                    fieldName="Email"
                    value={loginData.Email??""}
                    error={errorObject.Email}
                    errorMessage="Please enter a valid email address"
                    labelOnTop
                />
                {/* <div className="captcha-field-container"></div>
                <div className="captcha-label">
                    <label>Captcha</label>
                    <span className="required-mark">*</span>
                </div>
                <div className="captcha-container">
                    <div className="captcha-image-container">
                        <p className="captcha-image">Captcha image</p>
                        <FontAwesomeIcon icon={faRefresh} fontSize="25px" />
                    </div>
                    <Input
                        className="captcha-text"
                        placeholder="Enter Captcha Here"
                        onChange={(e) => handleInputChange(e, "Captcha")}
                        required={true}
                        type="text"
                    />
                </div> */}

                
                    

            </div>
            <div className="form-footer">
                <Button
                    className="login-button"
                    onClick={handleForgotPassword}
                    type="primary"
                >
                    Generate Link
                </Button>
                <div className="footer-text">
                    <p>
                        Already have an account?{" "}
                        <Button
                            onClick={(e) => {
                                handleFormChange(e, "Login");
                            }}
                            className="form-link"
                            type="primary"
                        >
                            Login
                        </Button>
                    </p>
                </div>
            </div>
        </div>
    );
};
export default ForgotPassword;
