import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";

import AppCard from "../../components/appStack/AppCard";
import AppList from "../../components/appStack/AppList";
import Input from "../../components/common/Input";
import Loader from "../../components/common/loader";
import { listAllIndustries } from "../../services/eventGrid";

import { IAppStoreData } from "../../interfaces/appStore";
import { ITargetIndustriesData } from "../../interfaces/orchestrator";
import {
  getAppStatus,
  getAppStore,
  getMyAppStore,
} from "../../services/appStore";
import { getAllUsers } from "../../services/user";

import {
  AppStatusState,
  DeveloperState,
  FilterState,
} from "../../typings/appStack";

import "./styles.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setStatus } from "../../appStatusSlice";
import {
  getStatusObjectFromDisplay,
  renderDisplayStatus,
} from "../../utils/appstackCard";

function AppStack() {
  const [developersList, setDevelopersList] = useState<string[] | undefined>();
  const [targetIndustries, setTargetIndustries] = useState<
    ITargetIndustriesData[] | null
  >(null);

  const [appStatus, setAppStatus] = useState<AppStatusState[] | undefined>();
  const [appStack, setAppStack] = useState<IAppStoreData[] | undefined>();
  const [inputQuery, setInputQuery] = useState<string>("");
  const [sort, setSort] = useState<number>(0);
  const [listView, setListView] = useState<boolean>(false);
  const filterFields: {
    Industries?: string;
    Developers?: string;
    AppStatus?: string;
    Processors?: string;
  } = {
    Industries: "Industry",
    Developers: "Developer",
    AppStatus: "App Status",
    // Processors:"Processor"
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterState>({
    Industries: [],
    Developers: [],
    AppStatus: [],
    // Processors?:[],
  });
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [prevPage, setPrevPage] = useState(0); // storing prev page number
  const [wasLastList, setWasLastList] = useState(false);
  const listInnerRef = useRef<HTMLDivElement>(null);
  const [queryObject, setQueryObject] = useState<any>({});

  const user = useSelector((state: RootState) => state.userDetails);
  const dispatch = useDispatch();
  const appStatusReduxState = useSelector(
    (state: RootState) => state.appStatus
  ).status;
  const location = useLocation();
  const isLoggedIn = useSelector((state: RootState) => state.isLoggedIn).value;

  const industryDropdownChangeHandler = (e: any, data: string[]) => {
    let tempFilterState = { ...filter };

    if (targetIndustries) {
      let filteredIndustries = data.map((item) => {
        let selectedIndustry = targetIndustries.filter(
          (industry) => industry.awi_name === item
        )[0];

        let industryType: ITargetIndustriesData = selectedIndustry;

        return industryType;
      });
      if (filteredIndustries)
        tempFilterState.Industries = [...filteredIndustries];
      setFilter(tempFilterState);
    }
  };

  const appStatusDropdownChangeHandler = (e: any, data: string[]) => {
    let tempFilterState = { ...filter };
    console.log("app status", data);
    if (appStatus) {
      let filteredStatus = data.map((item) => {
        let parsedItem = getStatusObjectFromDisplay(item).key;
        let selectedStatus = appStatus.filter(
          (status) => status.key === parsedItem
        )[0];

        let statusType: AppStatusState = selectedStatus;

        return statusType;
      });
      if (filteredStatus) tempFilterState.AppStatus = [...filteredStatus];
      setFilter(tempFilterState);
    }
  };

  const developerChangeHandler = (e: any, data: string[]) => {
    let tempFilterState = { ...filter };

    tempFilterState.Developers = data.map((item) => {
      const developerType: DeveloperState = {
        awi_name: item,
      };
      return developerType;
    });
    setFilter(tempFilterState);
  };

  const removeFilterHandler = (index: number, fieldName: string) => {
    let tempFilterState = { ...filter };
    if (fieldName === "industry") {
      tempFilterState.Industries.splice(index, 1);
    } else if (fieldName === "appStatus") {
      tempFilterState.AppStatus.splice(index, 1);
    } else {
      tempFilterState.Developers.splice(index, 1);
    }
    setFilter(tempFilterState);
    applyFilters();
  };

  const fetchAppStatus = async () => {
    console.log(appStatusReduxState, "fetch app status called");
    let appStatus = [...appStatusReduxState];
    if (appStatus.length === 0) {
      appStatus = await getAppStatus(true);

      if (appStatus) {
        dispatch(setStatus(appStatus));
      }
    }
    setAppStatus(appStatus);
  };

  useEffect(() => {
    isLoggedIn && getAppStoreDevelopers();
    let tempTargetIndustries = listAllIndustries();
    setTargetIndustries(tempTargetIndustries);
    fetchAppStatus();
  }, []);

  useEffect(() => {
    console.log("curr page", currPage);
    extractAppStore();
  }, [currPage, prevPage]);

  useEffect(() => {
    handleSort();
  }, [sort]);

  const showListView = () => {
    setListView(true);
  };

  const showTileView = () => {
    setListView(false);
  };
  const handleSort = (tempSort?: number) => {
    setAppStack([]);
    setWasLastList(false);
    if (currPage === 1 && prevPage === 0) {
      extractAppStore();
    } else {
      setCurrPage(1);
      setPrevPage(0);
    }
    if (loading) return;
    tempSort = tempSort || sort;
    tempSort = tempSort % 3;
    setSort(tempSort);
    switch (tempSort) {
      case 1:
        setQueryObject({ filter: {}, sort: { awi_name: 1 } });
        break;
      case 2:
        setQueryObject({ filter: {}, sort: { awi_name: -1 } });
        break;
      default:
        setQueryObject({ filter: {}, sort: {} });
        break;
    }
  };

  const applyFilters = () => {
    console.log("apply filter called", currPage, prevPage);
    setAppStack([]);
    setWasLastList(false);
    if (currPage === 1 && prevPage === 0) {
      extractAppStore();
    } else {
      console.log("else called");
      setCurrPage(1);
      setPrevPage(0);
    }
  };

  const getAppStoreDevelopers = async () => {
    const response = await getAllUsers();
    let developerNames = response?.map((user: any) => user.name);
    setDevelopersList(developerNames);
  };

  const extractAppStore = async () => {
    setLoading(true);

    let _queryObject = {
      ...queryObject,
      page: 0,
      limit: 10,
      search: inputQuery,
    };
    _queryObject.filter = {
      awi_target_industries: filter.Industries,
      awi_app_developer: filter.Developers,
      awi_app_status: filter.AppStatus,
    };
    _queryObject.page = currPage;
    _queryObject.limit = 10;

    let myApps = location.pathname === "/my-apps";
    let reviewApps = location.pathname === "/in-review";
    console.log(user, myApps, location.pathname, "ashbdh");
    let tempAppStack = [];
    if (reviewApps) {
      _queryObject.filter = {
        ..._queryObject.filter,
        awi_app_status: [{ key: "review", value: 4 }],
      };
      tempAppStack = await getAppStore(_queryObject);
    }
    if (myApps) {
      _queryObject.filter = {
        ..._queryObject.filter,
        awi_app_developer: [{ awi_name: user.name }],
      };
      _queryObject["user"] = user.name;
      tempAppStack = await getMyAppStore(_queryObject);
    } else {
      tempAppStack = await getAppStore(_queryObject);
    }

    if (tempAppStack.length < 10) setWasLastList(true);
    if (appStack && appStack.length > 0 && currPage > 1) {
      setAppStack([...appStack!, ...tempAppStack]);
    } else {
      setAppStack(tempAppStack);
    }
    setPrevPage(currPage);
    setLoading(false);
  };

  const extractIndustryFields = (objectArray: any) => {
    return objectArray?.map((industry: { awi_name: any }) => industry.awi_name);
  };

  const extractAppStatuses = (objectArray: any) => {
    let appStatusArray = objectArray?.map(
      (appStatus: { key: string; value: number }) =>
        renderDisplayStatus(appStatus.value)
    );
    console.log("app status array", appStatusArray);
    return appStatusArray;
  };

  const handleInputChange = (e: any) => {
    setInputQuery(e.target.value);
  };
  const clearFilters = () => {
    setFilter({
      Industries: [],
      AppStatus: [],
      Developers: [],
      Processors: [],
    });
    applyFilters();
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      applyFilters();
    }
  };

  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  const intersectionRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (intersectionObserver.current)
        intersectionObserver.current.disconnect();
      intersectionObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!wasLastList) {
            if (appStack?.length !== 0) setCurrPage(currPage + 1);
          }
        }
      });
      if (node) intersectionObserver.current.observe(node);
    },
    [loading]
  );

  const renderSearchInput = () => {
    const filterOptions = [
      extractIndustryFields(targetIndustries) ?? [],
      extractAppStatuses(appStatus) ?? [],
    ];
    const filterHandlers = [
      industryDropdownChangeHandler,
      appStatusDropdownChangeHandler,
    ];
    const multiSelectOptions = [true, true];
    const appliedFilters = [
      extractIndustryFields(filter.Industries),
      extractAppStatuses(filter.AppStatus),
    ];
    let myApps = location.pathname === "/my-apps";
    if (user.role[0] !== "root" && user.role[0] !== "developer") {
      delete filterFields["AppStatus"];
    }
    if (!isLoggedIn || myApps) {
      delete filterFields["Developers"];
    } else {
      let idx = Object.keys(filterFields).indexOf("Developers");
      filterOptions.splice(idx, 0, developersList);
      filterHandlers.splice(idx, 0, developerChangeHandler);
      multiSelectOptions.splice(idx, 0, false);
      appliedFilters.splice(idx, 0, [
        ...filter.Developers.map((item: any) => item.awi_name),
      ]);
    }
    let filterFieldsArray = Object.values(filterFields);
    return (
      <div className='app-stack-dropdowns'>
        <Input
          placeholder='Search'
          type={"text"}
          value={inputQuery}
          onChange={handleInputChange}
          showFilters
          filterFields={filterFieldsArray}
          filterOptions={filterOptions}
          isMultiSelect={multiSelectOptions}
          filterHandlers={filterHandlers}
          clearFilters={clearFilters}
          applyHandler={applyFilters}
          onKeyDown={handleKeyDown}
          appliedFilters={appliedFilters}
        ></Input>
        <div className='appstack-action-icon-tray'>
          {!listView && (
            <div
              className={`appstack-action-icon ${
                !listView ? "active-icon" : ""
              }`}
              onClick={showListView}
            >
              <FontAwesomeIcon icon={faList} />
              List View
            </div>
          )}
          {listView && (
            <div
              className={`appstack-action-icon ${
                listView ? "active-icon" : ""
              }`}
              onClick={showTileView}
            >
              <img
                src='/assets/icons/gallery view.svg'
                alt='tile view'
                className={`${listView ? "active-icon-image" : ""}`}
              />
              Tile View
            </div>
          )}
          <div
            className={`appstack-action-icon ${sort ? "active-icon" : ""}`}
            onClick={() => handleSort(sort + 1)}
          >
            {sort === 0 && (
              <img src='/assets/icons/sort-inc-black.svg' alt='sort' />
            )}
            {sort === 1 && (
              <img src='/assets/icons/sort-inc.svg' alt='increasing' />
            )}
            {sort === 2 && (
              <img src='/assets/icons/sort-dec.svg' alt='decreasing' />
            )}
            Sort
          </div>
        </div>
      </div>
    );
  };

  const renderSelectedFilterChip = () => {
    return (
      <div className='selected-filter-chips'>
        {filter.AppStatus.length > 0 &&
          filter.AppStatus.map((item: any, idx: number) => {
            return (
              <div className='filter-chip'>
                {renderDisplayStatus(item.value)}
                <span
                  className='close-icon'
                  onClick={(e) => removeFilterHandler(idx, "appStatus")}
                >
                  x
                </span>
              </div>
            );
          })}
        {filter.Industries.length > 0 &&
          filter.Industries.map((item: any, idx: number) => {
            return (
              <div className='filter-chip'>
                {item.awi_name}
                <span
                  className='close-icon'
                  onClick={(e) => removeFilterHandler(idx, "industry")}
                >
                  x
                </span>
              </div>
            );
          })}
        {filter.Developers.length > 0 &&
          filter.Developers.map((item: any, idx) => {
            return (
              <div className='filter-chip'>
                {item.awi_name}
                <span
                  className='close-icon'
                  onClick={(e) => removeFilterHandler(idx, "developer")}
                >
                  x
                </span>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <div className='app-stack-header'>{renderSearchInput()}</div>
      {renderSelectedFilterChip()}

      <div className='app-stack-cards-container' ref={listInnerRef}>
        {loading && (
          <div className='app-stack-loading-container'>
            {" "}
            <Loader height={200} width={200}></Loader>{" "}
          </div>
        )}
        {!listView &&
          appStack?.map((app) => {
            return <AppCard appData={app}></AppCard>;
          })}
        {!listView && appStack?.length === 0 && !loading && (
          <div className='app-stack-loading-container'> No Apps Found </div>
        )}
        {listView && <AppList appData={appStack}></AppList>}
        <span className='intersection-observer' ref={intersectionRef}></span>
      </div>
    </div>
  );
}

export default AppStack;
