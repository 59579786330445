// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.token-modal-input{
    width: 100%;
    flex-grow: 0.75;
    padding: 0.5rem;
    outline: none;
    border: 0px;
    border-bottom: 0.5px solid var(--primary-color-100);
}
.copy-token-icon{
    color: var(--primary-color);
    cursor: pointer;
}
.modal-content-divider{
    size: 10;
    width: 100%;
    border: 1px solid #E9EDF2;
}
.copy-tokens-container{
    display: flex;
    gap: 0.625rem;
    /* width: 100%; */
    align-items: center;
    justify-content: flex-end;
}
.modal-subheading{
    color: var(--nuetral);
}
.modal-subcontent{
    text-align: left;
    align-items: flex-start !important;
    margin-top: 0.75rem;
    width: 80% !important;
}
.modal-subcontent-header{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/Organisations/generateTokenModal/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,eAAe;IACf,aAAa;IACb,WAAW;IACX,mDAAmD;AACvD;AACA;IACI,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,QAAQ;IACR,WAAW;IACX,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,kCAAkC;IAClC,mBAAmB;IACnB,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".token-modal-input{\n    width: 100%;\n    flex-grow: 0.75;\n    padding: 0.5rem;\n    outline: none;\n    border: 0px;\n    border-bottom: 0.5px solid var(--primary-color-100);\n}\n.copy-token-icon{\n    color: var(--primary-color);\n    cursor: pointer;\n}\n.modal-content-divider{\n    size: 10;\n    width: 100%;\n    border: 1px solid #E9EDF2;\n}\n.copy-tokens-container{\n    display: flex;\n    gap: 0.625rem;\n    /* width: 100%; */\n    align-items: center;\n    justify-content: flex-end;\n}\n.modal-subheading{\n    color: var(--nuetral);\n}\n.modal-subcontent{\n    text-align: left;\n    align-items: flex-start !important;\n    margin-top: 0.75rem;\n    width: 80% !important;\n}\n.modal-subcontent-header{\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
