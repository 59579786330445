// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    flex-direction: row;
    margin: 40px 0px;
    font-weight: 300;
    font-family: "Roboto", "sans-serif";
    gap: 0.625rem;
    width: 100%;
}
.login-image {
    max-width: 55%;
    flex-grow: 1;
    object-fit: contain;
    padding: 0px 40px;
}

@media screen and (max-width: 786px) {
    .login-image {
        display: none;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Auth/login/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,mCAAmC;IACnC,aAAa;IACb,WAAW;AACf;AACA;IACI,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".login-container {\n    display: flex;\n    flex-direction: row;\n    margin: 40px 0px;\n    font-weight: 300;\n    font-family: \"Roboto\", \"sans-serif\";\n    gap: 0.625rem;\n    width: 100%;\n}\n.login-image {\n    max-width: 55%;\n    flex-grow: 1;\n    object-fit: contain;\n    padding: 0px 40px;\n}\n\n@media screen and (max-width: 786px) {\n    .login-image {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
