import { addDays } from "date-fns"

export const formatDate = (date:string)=>{
    const dateToFormat = new Date(date)
    const formattedDate = `${dateToFormat.getDate()}/${dateToFormat.getMonth()+1}/${dateToFormat.getFullYear()}`
    return formattedDate 
}

export const orderCreationDate = (days?:number) =>{
		let currDate = new Date()
        if(days)
		currDate = addDays(currDate,days)
        return currDate.toISOString().substring(0,10)
	}