import React, { useRef, useState } from "react";
import "./styles.css";
import useOutsideAlerter from "../../../hooks/OutsideAlerter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";

type MultiLevelDropdownProps = {
  placeholder?: string;
  handleSelection: any;
  dropdownItems: any[];
  numberOfLevels?: number;
};
const MultiSelectDropdown = ({
  placeholder,
  handleSelection,
  dropdownItems,
  numberOfLevels = 1,
}: MultiLevelDropdownProps) => {
  const [openFirstMenu, setOpenFirstMenu] = useState(false);
  const [mainDropdownSelectedItem, setMainDropdownSelectedItem] =
    useState<any>(null);
  const [subDropdownSelectedItem, setSubDropdownSelectedItem] =
    useState<any>(null);

  const closeDropDown = () => {
    setOpenFirstMenu(false);
  };

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, closeDropDown);
  const handleDropdownClick = () => {
    setOpenFirstMenu(!openFirstMenu);
  };

  const handleFirstDropdownItemClick = (item: any) => {
    setMainDropdownSelectedItem(item);

    if (numberOfLevels === 1) {
      handleSelection([
        {
          level: 1,
          id: item.id,
          label: item.label,
          value: item.value,
        },
      ]);
      closeDropDown();
    }
  };

  const handleSecondDropdownItemClick = (item: any) => {
    // setOpenFirstMenu(false);
    setSubDropdownSelectedItem(item);

    handleSelection([
      {
        level: 1,
        id: mainDropdownSelectedItem.id,
        label: mainDropdownSelectedItem.label,
        value: mainDropdownSelectedItem.value,
      },
      {
        level: 2,
        id: item.id,
        label: item.label,
        value: item.value,
      },
    ]);
    closeDropDown();
  };

  return (
    <div className="two-level-dropdown">
      <div className="dropdown-input" onClick={handleDropdownClick}>
        <span>Select Version</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>

      {openFirstMenu && (
        <div
          ref={wrapperRef}
          className="first-dropdown-menu"
          style={{ zIndex: 5 }}
        >
          {dropdownItems.map((item) => (
            <div
              className="dropdown-item dropdown-container"
              onClick={() => handleFirstDropdownItemClick(item)}
              key={item.label}
            >
              <div
                style={{
                  position: "relative",
                  backgroundColor:
                    mainDropdownSelectedItem?.id === item.id
                      ? "black"
                      : "white",
                }}
              >
                <div
                  className="multi-dropdown-item"
                  style={{
                    backgroundColor:
                      mainDropdownSelectedItem?.id === item.id
                        ? "#F5F5F5"
                        : "white",
                  }}
                >
                  {item.label}
                  {numberOfLevels === 2 && (
                    <FontAwesomeIcon icon={faAngleRight} />
                  )}
                </div>

                {mainDropdownSelectedItem?.id === item.id &&
                  numberOfLevels === 2 && (
                    <div
                      style={{
                        position: "absolute",
                        width: "100px",
                        left: "95%",
                        top: "20px",
                        zIndex: 5,
                      }}
                      className="dropdown-container"
                    >
                      {item.children.map((child: any) => (
                        <div
                          className="multi-dropdown-item"
                          key={child.label}
                          onClick={() => handleSecondDropdownItemClick(child)}
                          style={{
                            backgroundColor:
                              subDropdownSelectedItem?.id === child.id
                                ? "#F5F5F5"
                                : "white",
                          }}
                        >
                          {child.label}
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
