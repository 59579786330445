import './styles.css'

type loaderProps = {
  height?: number
  width?: number
}

function Loader({height = 40, width = 40}: loaderProps) {
  return (
    <div className='common-loader' style={{height: `${height}px`, width: `${width}px`}}></div>
  )
}

export default Loader