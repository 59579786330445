// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-summary{
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    width: 100%;
    padding: 1.25rem;
    color: var(--primary-color);
    text-align: center;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/appRegistration/AppSummary/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".order-summary{\n    display: flex;\n    flex-direction: column;\n    gap: 0.625rem;\n    width: 100%;\n    padding: 1.25rem;\n    color: var(--primary-color);\n    text-align: center;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
