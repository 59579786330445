// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button{
    background-color: var(--theme-color);
    color: var(--primary-color);
    padding: 0.75rem 1.5rem;
    border-radius: 0.625rem;
    border: 1px solid var(--primary-color);
    font-size: 1rem;
    box-shadow: 0px 0.25rem 0.5rem 3px rgba(103, 125, 183, 0.15), 0px 1px 3px rgba(103, 125, 183, 0.3);
}
.disabled-button{
    background-color: var(--primary-color-50);
    color: var(--primary-color-100);
    border: none;
    cursor: not-allowed;
}
.primary-button{
    background-color: var(--primary-color);
    color: var(--theme-color);
}
.disabled-button.primary-button{
    background-color: var(--primary-color-50);
    color: var(--primary-color-100);
    border: none;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/common/button/styles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,2BAA2B;IAC3B,uBAAuB;IACvB,uBAAuB;IACvB,sCAAsC;IACtC,eAAe;IACf,kGAAkG;AACtG;AACA;IACI,yCAAyC;IACzC,+BAA+B;IAC/B,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,sCAAsC;IACtC,yBAAyB;AAC7B;AACA;IACI,yCAAyC;IACzC,+BAA+B;IAC/B,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".button{\n    background-color: var(--theme-color);\n    color: var(--primary-color);\n    padding: 0.75rem 1.5rem;\n    border-radius: 0.625rem;\n    border: 1px solid var(--primary-color);\n    font-size: 1rem;\n    box-shadow: 0px 0.25rem 0.5rem 3px rgba(103, 125, 183, 0.15), 0px 1px 3px rgba(103, 125, 183, 0.3);\n}\n.disabled-button{\n    background-color: var(--primary-color-50);\n    color: var(--primary-color-100);\n    border: none;\n    cursor: not-allowed;\n}\n.primary-button{\n    background-color: var(--primary-color);\n    color: var(--theme-color);\n}\n.disabled-button.primary-button{\n    background-color: var(--primary-color-50);\n    color: var(--primary-color-100);\n    border: none;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
