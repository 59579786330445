import { title } from "process"
import { ReactNode } from "react"

import "./styles.css"


type Props = {className?:string,children?:ReactNode,disabled?:boolean,onClick?:(e?:any)=>void,type?:string,title?:string}

const Button = ({className,children,disabled,onClick,type,title}:Props ) => {
  return (
    <button className={`button ${disabled && 'disabled-button'} ${type==='primary' && 'primary-button'}  ${className}`} disabled = {disabled} onClick ={onClick} title = {title}>{children}</button>
  )
}

export default Button