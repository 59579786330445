import { ChangeEvent } from "react";

type Props = {
    name: string,
    value: string,
    id?:string,
    label?:string,
    handleChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    checked?:boolean
}

function Checkbox({name,value,id,label,handleChange,checked}:Props) {
  return (
    <>
    <input type="checkbox" value={value} id = {id} onChange = {handleChange} checked = {checked}></input>
    {label && <label htmlFor={id}>{name}</label>}
    </>
  )
}

export default Checkbox