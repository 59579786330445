import { useEffect, useState } from 'react'
import Input  from '../../components/common/Input'
import Loader from '../../components/common/loader'
import Table from '../../components/common/table'
import { getAllOrganisations } from '../../services/orders'
import { getAllUsers } from '../../services/user'

import "./styles.css"


const tableHeaders = [
  {header:"S.No.",type:"Number"},
  {header:"User Name" ,type:"String"},
  {header:"Role",type:"String"},
  {header:"Email",type:"String"},
  {header:"Joining Date",type:"Date"},
  {header:"Organisation Name",type:"String"},
  {header:"Apps Registered",type:"Number"},
]

const roles = ["root","admin","developer","requester","approver"]

function Users() {
  const [orgs,setOrgs] = useState([])
  const filterOptions = ["Organisation","Role"];
  const [tableData,setTableData] = useState<any>(null)
  const [filteredTableData,setFilteredTableData] = useState<any>(null)
  const [filter,setFilter] = useState([[],[]])
  const [loading,setLoading] = useState(false)

  const [userQuery,setUserQuery] = useState<string|null>("")
    useEffect(() => {
        fetchAllUsers()
        fetchAllOrgs()
    },[])
    
    const fetchAllOrgs = async()=>{
      setLoading(true)
      const org_response = await getAllOrganisations()
      const temp_orgs = org_response.map((org:any)=> { 
        return {org_name:org.org_name, org_id:org.org_id
        }
      })
      setOrgs(temp_orgs)
      setLoading(false)
    }
    
    const fetchAllUsers = async(query?:{filter:any,sort:any})=>{
        const userResponse = await getAllUsers(query)
        setTableData(userResponse)
    }
     useEffect(()=>{
      let temptableData = tableData;
      if(userQuery)
        temptableData = tableData?.filter((row:any)=>row.name.toLowerCase().includes(userQuery.toLowerCase()))
      setFilteredTableData(temptableData)
    },[tableData, userQuery])

    const handleInputChange = (e:any)=>{
      setUserQuery(e.target.value)
    }

    const organisationDropdownHandler = (e: any, data: string[]) => {
        let tempFilterState = [...filter];
        tempFilterState[0] = data as never

        setFilter(tempFilterState)
    };
  
    const roleDropdownHandler =(e:any,data: string[])=>{
      let tempFilterState = [...filter];
        tempFilterState[1] = data as never

        setFilter(tempFilterState)
    }

    const clearFilters = ()=>{
      setFilter([[],[]])
      applyHandler()
    }
    const removeFilterHandler = (index: number,fieldName:string) => {
        let tempFilterState = [...filter];
        if(fieldName === "organisation"){
            tempFilterState[0].splice(index, 1);
        }
        if (fieldName === "role"){
          tempFilterState[1].splice(index, 1);
        }
        setFilter(tempFilterState);
        applyHandler()
    }



    const applyHandler = ()=>{
      let orgIds = filter[0].map((item) => {
          let selectedOrg:any = orgs.filter(
              (org:any) => org.org_name === item
          )[0];
          let org_id = selectedOrg.org_id
          return org_id;
      });
      
      let payload = {filter:{org:orgIds,role:filter[1]},sort:{}}
      fetchAllUsers(payload)
    }
    const extractOrgNames = (objectArray: any) => {
        return objectArray?.map(
            (org:any) => {
              
              return org.org_name
            }
        );
    };
  return (
    <div>
        <div className='users-table-container'>
          <div className='user-search-container'>
            <Input
              placeholder="Search Users"
              onChange={handleInputChange} 
              type={'text'} 
              value={userQuery!}
              showFilters
              filterFields={filterOptions}
              filterOptions={[extractOrgNames(orgs),roles]} 
              isMultiSelect = {[true,true]}
              filterHandlers = {[organisationDropdownHandler,roleDropdownHandler]}
              clearFilters = {clearFilters}   
              appliedFilters = {[filter[0],filter[1]]}  
              applyHandler = {applyHandler}        
            />
            { (filter[0].length>0 || filter[1].length>0) && <div className="selected-filter-chips user-chip">
                    {filter[0].length>0 && filter[0].map((item:any,idx:number)=>{
                        return <div className="filter-chip">{item}
                        <span className="close-icon" onClick={(e)=>removeFilterHandler(idx,'organisation')} >x</span>
                        </div>
                    })}
                    {filter[1].length>0 && filter[1].map((item:any,idx:number)=>{
                        return <div className="filter-chip">{item}
                        <span className="close-icon" onClick={(e)=>removeFilterHandler(idx,'role')} >x</span>
                        </div>
                    })}
            </div>}
          </div>
            
          <div className='user-table-wrapper'>
            {!loading && <Table tableHeaders={tableHeaders} tableData={filteredTableData}></Table>}
            {loading && <div className="loader-container">
                    <Loader height={200} width={200}/>
                    </div>
                    }
        </div>
        </div>
    </div>
  )
}

export default Users