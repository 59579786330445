import {
  IAppRegistration,
  IAppRegistrationErrorExample,
} from "../interfaces/appRegistration";
import {
  LoginFormErrorObject,
  LoginFormValidatorObject,
  SignupFormErrorObject,
  UpdatePasswordErrorObject,
  UpdatePasswordForm,
  UserSignupForm,
} from "../typings/userLogin";

/**
 * Function that validates the login form data
 * @param    {Object}  LoginFormValidatorObject
 * @return   {Object}  LoginFormErrorObject
 */

export const loginFormValidator = ({
  Email,
  Password,
  Captcha,
}: LoginFormValidatorObject) => {
  let errorObject: LoginFormErrorObject = {
    Email: null,
    Password: null,
    Captcha: null,
  };

  if (Password && Password.length > 7) {
    errorObject.Password = false;
  } else {
    errorObject.Password = Password ? true : null;
  }

  if (Email && Email.length > 0) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))
      errorObject.Email = false;
    else errorObject.Email = true;
  } else {
    errorObject.Email = Email ? true : null;
  }

  if (Captcha && Captcha?.length > 0) {
    errorObject.Captcha = false;
  } else {
    errorObject.Captcha = Captcha ? true : null;
  }

  return errorObject;
};

/**
 * Function that validates the signup form data
 * @param    {Object}  SignupFormValidatorObject
 * @return   {Object}  SignupFormErrorObject
 */

export const signupFormValidator = ({
  Dropdown,
  FirstName,
  Email,
  LastName,
  Password,
  ConfirmPassword,
  Token,
}: UserSignupForm) => {
  let errorObject: SignupFormErrorObject = {
    Dropdown: null,
    FirstName: null,
    LastName: null,
    Email: null,
    Password: null,
    ConfirmPassword: null,
    Token: null,
  };

  if (Dropdown && Dropdown.length > 0) {
    errorObject.Dropdown = false;
  } else {
    errorObject.Dropdown = Dropdown ? true : null;
  }

  if (FirstName && FirstName.length > 0) {
    errorObject.FirstName = false;
  } else {
    errorObject.FirstName = FirstName ? true : null;
  }

  if (LastName && LastName.length > 0) {
    errorObject.LastName = false;
  } else {
    errorObject.LastName = LastName ? true : null;
  }

  if (Password && Password.length > 7) {
    errorObject.Password = false;
  } else {
    errorObject.Password = Password ? true : null;
  }

  if (Email && Email.length > 0) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))
      errorObject.Email = false;
    else errorObject.Email = true;
  } else {
    errorObject.Email = Email ? true : null;
  }

  if (Token && Token.length > 0) {
    errorObject.Token = false;
  } else {
    errorObject.Token = Token ? true : null;
  }

  if (ConfirmPassword === Password) {
    errorObject.ConfirmPassword = false;
  } else {
    errorObject.ConfirmPassword = ConfirmPassword ? true : null;
  }

  return errorObject;
};

/**
 * Function that validates the update password form data
 * @param    {Object}  UpdatePasswordValidator
 * @return   {Object}  UpdatePasswordErrorObject
 */

export const updatePasswordValidator = ({
  Email,
  Password,
  ConfirmPassword,
  Captcha,
  NewPassword,
}: UpdatePasswordForm) => {
  let errorObject: UpdatePasswordErrorObject = {
    NewPassword: null,
    ConfirmPassword: null,
  };

  if (NewPassword && NewPassword.length > 7) {
    errorObject.NewPassword = false;
  } else {
    errorObject.NewPassword = NewPassword ? true : null;
  }

  if (
    ConfirmPassword &&
    ConfirmPassword.length > 7 &&
    ConfirmPassword === NewPassword
  ) {
    errorObject.ConfirmPassword = false;
  } else {
    errorObject.ConfirmPassword = ConfirmPassword ? true : null;
  }
  // if (Captcha && Captcha?.length > 0) {
  //   errorObject.Captcha = false;
  // } else {
  //   errorObject.Captcha = Captcha ? true : null;
  // }

  return errorObject;
};

/**
 * Function that validates the app registration form data
 * @param    {Object}  appRegistrationObject
 * @return   {Object}  appRegistrationErrorObject
 */

export const appRegistrationValidator = ({
  awi_app_name,
  awi_app_author,
  awi_app_version,
  awi_target_industries,
  awi_description,
  awi_developer_image,
  awi_app_logo,
  awi_credits,
  awi_supported_acceleration,
  awi_gpu_required,
  awi_model_information,
  awi_resolution,
  awi_max_mount_height,
  awi_ideal_fov,
  awi_varifocal_camera,
  awi_focal_length,
  awi_fps,
  awi_ideal_lighting,
  awi_ptz_compatible,
  awi_handheld_compatible,
  awi_sample_images,
  awi_ppm,
  awi_app_logic,
  awi_app_assumption,
  awi_flowchart,
  awi_demo_video,
  awi_cer,
  awi_app_developer,
  awi_sample_fov_image,
}: IAppRegistration) => {
  let errorObject = IAppRegistrationErrorExample;

  if (awi_app_name && awi_app_name.length > 0) {
    errorObject.awi_app_name = false;
  } else {
    errorObject.awi_app_name = awi_app_name !== null ? true : null;
  }

  if (awi_app_developer && awi_app_developer.length > 0) {
    errorObject.awi_app_developer = false;
  } else {
    errorObject.awi_app_developer = awi_app_developer !== null ? true : null;
  }

  if (awi_app_author && awi_app_author.length > 0) {
    errorObject.awi_app_author = false;
  } else {
    errorObject.awi_app_author = !awi_app_author ? true : null;
  }

  if (awi_app_logo && awi_app_logo.length > 0) {
    errorObject.awi_app_logo = false;
  } else {
    errorObject.awi_app_logo = !awi_app_logo ? true : null;
  }

  if (awi_app_version && awi_app_version?.length > 0) {
    errorObject.awi_app_version = false;
  } else {
    errorObject.awi_app_version = !awi_app_version ? true : null;
  }

  if (awi_target_industries && awi_target_industries?.length > 0) {
    errorObject.awi_target_industries = false;
  } else {
    errorObject.awi_target_industries =
      !awi_target_industries || awi_target_industries.length < 1 ? true : null;
  }

  if (
    awi_description &&
    awi_description?.length > 0 &&
    awi_description?.length < 301
  ) {
    errorObject.awi_description = false;
  } else {
    errorObject.awi_description =
      !awi_description || awi_description.length > 300 ? true : null;
  }

  if (awi_developer_image && awi_developer_image?.length > 0) {
    errorObject.awi_developer_image = false;
  } else {
    errorObject.awi_developer_image = !awi_developer_image ? true : null;
  }

  if (awi_credits && awi_credits > 0) {
    errorObject.awi_credits = false;
  } else {
    errorObject.awi_credits = awi_credits === 0 ? true : null;
  }

  if (awi_supported_acceleration && awi_supported_acceleration?.length > 0) {
    errorObject.awi_supported_acceleration = false;
  } else {
    errorObject.awi_supported_acceleration = !awi_supported_acceleration
      ? true
      : null;
  }

  if (
    awi_model_information[0].name.length > 0 &&
    awi_model_information[0].type.length > 0 &&
    awi_model_information[0].version.length > 0
  ) {
    errorObject.awi_model_information = false;
  } else {
    errorObject.awi_model_information = awi_model_information ? true : null;
  }

  if (awi_resolution && awi_resolution.length > 0) {
    errorObject.awi_resolution = false;
  } else {
    errorObject.awi_resolution = !awi_resolution ? true : null;
  }

  if (awi_max_mount_height && awi_max_mount_height > 0) {
    errorObject.awi_max_mount_height = false;
  } else {
    errorObject.awi_max_mount_height = !awi_max_mount_height ? true : null;
  }

  if (awi_ideal_fov && awi_ideal_fov.length > 0) {
    errorObject.awi_ideal_fov = false;
  } else {
    errorObject.awi_ideal_fov = !awi_ideal_fov ? true : null;
  }

  if (awi_focal_length && awi_focal_length > 0) {
    errorObject.awi_focal_length = false;
  } else {
    errorObject.awi_focal_length = awi_focal_length <= 0 ? true : null;
  }

  if (awi_fps && awi_fps > 0) {
    errorObject.awi_fps = false;
  } else {
    errorObject.awi_fps = awi_fps <= 0 ? true : null;
  }

  if (awi_ideal_lighting && awi_ideal_lighting.length > 0) {
    errorObject.awi_ideal_lighting = false;
  } else {
    errorObject.awi_ideal_lighting = !awi_ideal_lighting ? true : null;
  }

  if (awi_sample_fov_image && awi_sample_fov_image.length > 4) {
    errorObject.awi_sample_fov_image = false;
  } else {
    errorObject.awi_sample_fov_image =
      !awi_sample_fov_image || awi_sample_fov_image.length < 5 ? true : null;
  }

  if (awi_ppm.ppm_value && awi_ppm.ppm_value > 0) {
    errorObject.awi_ppm = false;
  } else {
    errorObject.awi_ppm = awi_ppm.ppm_value || 0 <= 0 ? true : null;
  }

  if (awi_app_logic && awi_app_logic.length > 0) {
    errorObject.awi_app_logic = false;
  } else {
    errorObject.awi_app_logic = !awi_app_logic ? true : null;
  }

  if (awi_app_assumption && awi_app_assumption.length > 0) {
    errorObject.awi_app_assumption = false;
  } else {
    errorObject.awi_app_assumption = !awi_app_assumption ? true : null;
  }

  if (awi_flowchart && awi_flowchart.length > 0) {
    errorObject.awi_flowchart = false;
  } else {
    errorObject.awi_flowchart = !awi_flowchart ? true : null;
  }

  if (awi_sample_images && awi_sample_images.length > 4) {
    errorObject.awi_sample_images = false;
  } else {
    errorObject.awi_sample_images =
      !awi_sample_images || awi_sample_images.length < 4 ? true : null;
  }

  if (awi_demo_video && awi_demo_video.length > 1) {
    errorObject.awi_demo_video = false;
  } else {
    errorObject.awi_demo_video =
      !awi_demo_video || awi_demo_video.length < 2 ? true : null;
  }

  if (awi_cer && awi_cer > 0) {
    errorObject.awi_cer = false;
  } else {
    errorObject.awi_cer = awi_cer < 0 ? true : null;
  }
  return errorObject;
};

export const formValidator = (state: any, keys?: string[]) => {
  let errorObject: any = {};
  if (keys) {
    keys.forEach((key) => {
      if (state[key] !== null && state[key] !== undefined) {
        if (typeof state[key] === "string") {
          errorObject[key] = state[key].trim() === "" ? true : false;
        } else if (Array.isArray(state[key])) {
          errorObject[key] = state[key].length > 0 ? false : true;
        } else if (typeof state[key] === "boolean") {
          errorObject[key] = !state[key] ? true : false;
        } else if (typeof state[key] === "object") {
          let valuesArray = Object.values(state[key]);
          let valuesError = valuesArray.some((value) => !Boolean(value));

          errorObject[key] = valuesError ? true : false;
        }
      } else {
        errorObject[key] = true;
      }
    });
  } else {
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (typeof state[key] === "string") {
          errorObject[key] = state[key].trim() === "" ? true : false;
        }
        if (!Boolean(state[key])) {
          errorObject[key] = true;
        }
        if (Array.isArray(state[key])) {
          errorObject[key] = state[key].length > 0 ? false : true;
        }
      }
    }
  }
  return errorObject;
};

export const appDescriptionValidator = (state: any) => {
  if (
    state.awi_description &&
    state.awi_description?.length > 0 &&
    state.awi_description?.length < 301
  ) {
    return false;
  } else {
    return !state.awi_description || state.awi_description.length > 300
      ? true
      : null;
  }
};

export const verifyEmail = (email: string) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) return false;
  return true;
};
