// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-element-container{
    height: 100%;
    width: 100%;
}
.image-element{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
}
.image-element-expanded{
    width: 80%;
    height: 80%;
    object-fit: contain;
}
.image-backdrop{
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    filter: brightness(0.7);
    /* background-color: rgba(0,0,0,0.9); */
    /* opacity: 0.3; */
    /* z-index: 10; */
    /* display: none; */
}
.image-loader{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/image/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,qBAAqB;AACzB;AACA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,uCAAuC;IACvC,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".image-element-container{\n    height: 100%;\n    width: 100%;\n}\n.image-element{\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    border-radius: 0.5rem;\n}\n.image-element-expanded{\n    width: 80%;\n    height: 80%;\n    object-fit: contain;\n}\n.image-backdrop{\n    /* position: absolute; */\n    /* top: 0; */\n    /* left: 0; */\n    width: 100%;\n    height: 100%;\n    filter: brightness(0.7);\n    /* background-color: rgba(0,0,0,0.9); */\n    /* opacity: 0.3; */\n    /* z-index: 10; */\n    /* display: none; */\n}\n.image-loader{\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    z-index: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
