import { useEffect, useState } from "react";
import Tooltip from "../tooltip";
import "./styles.css";

type TextareaProps = {
  value: string | number | readonly string[] | undefined;
  onChange: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  inputRef?: any;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  tooltip?: string;
};

function Textarea({
  value,
  onChange,
  required,
  disabled,
  inputRef,
  placeholder,
  error,
  errorMessage,
  tooltip,
}: TextareaProps) {
  const [inputFocussed, setInputFocussed] = useState(false);
  const [focussedOnce, setFocussedOnce] = useState(false);
  useEffect(() => {
    if (value !== null || undefined) {
      if (typeof value !== "string") {
        setInputFocussed(true);
        setFocussedOnce(true);
      } else if (value.trim() !== "") {
        setInputFocussed(true);
        setFocussedOnce(true);
      }
    }
  }, [value, error]);

  const handleLabelClick = () => {
    setInputFocussed(true);
    inputRef?.current?.focus();
  };
  const handleBlur = (e: any) => {
    if (value === null || value === undefined) {
      setInputFocussed(false);
    } else {
      if (typeof value === "string" && value.trim() === "") {
        setInputFocussed(false);
      }
    }
  };

  const handleFocus = (e: any) => {
    setInputFocussed(true);
  };

  return (
    <div className={`common-input-component `}>
      <fieldset
        className={`input-component-wrapper ${
          focussedOnce && error ? "error" : ""
        }`}
      >
        <div className='common-input-container common-textarea-container'>
          {!inputFocussed && (
            <label
              className={`common-input-label  ${
                inputFocussed ? "field-active" : ""
              }`}
              title={placeholder}
              onClick={handleLabelClick}
            >
              {placeholder}{" "}
              {required && <span className='required-flag'>*</span>}
            </label>
          )}
          {inputFocussed && (
            <legend className={`common-input-label field-active`}>
              {placeholder}
            </legend>
          )}
          {tooltip && tooltip!.trim() !== "" && (
            <Tooltip tooltip={tooltip}>!</Tooltip>
          )}
          <textarea
            className='common-input textarea-input'
            value={value as string}
            onChange={onChange}
            required={required}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
          />
        </div>
        {/* {error && focussedOnce && <div className='error'><img src='/assets/icons/error.svg' alt={errorMessage} className = "error" title = {errorMessage}></img></div>} */}
      </fieldset>
    </div>
  );
}

export default Textarea;
