import React, { useEffect, useState } from "react";
import { Checkbox } from "semantic-ui-react";
import { formValidator } from "../../../utils/formValidator";
import FormInput from "../../common/formInput";
import MultiSelectDropdown from "../../common/multiSelectDropdown";
import Image from "../../common/image";
import Loader from "../../common/loader";
import Carousel from "../../common/Carousel";
import Modal from "../../common/modal";

type Props = {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => void;
  state: any;
  errorObject: any;
  handleCompletedStages: (stage: number) => void;
  stage: number;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleDropdownChange: (e: any, data: string[]) => void;
  handleCheckboxSelect: (e: any, fieldName: string) => void;
  handleRefClick: (ref: any) => void;
  fieldOfViews: any;
  handleFOVDropdownChange: (e: any, data: any) => void;
  ppmRef: any;
  fovImageRef: any;
  appLogoRef: any;
  outputVideoRef: any;
  removeItemFromArray: (idx: number, fieldName: string) => void;
  loading: any;
  appRegistrationToolTips: any;
  handleDeleteFovImage: (idx: number) => void;
};
const addImageIcon = "/assets/icons/add-icon.svg";
function FovInfo({
  handleInputChange,
  state,
  errorObject,
  handleCompletedStages,
  stage,
  handleFileUpload,
  handleDropdownChange,
  handleCheckboxSelect,
  handleRefClick,
  fieldOfViews,
  handleFOVDropdownChange,
  fovImageRef,
  outputVideoRef,
  ppmRef,
  appLogoRef,
  removeItemFromArray,
  loading,
  appRegistrationToolTips,
  handleDeleteFovImage,
}: Props) {
  const [error, setErrorObject] = useState<any>({});
  const [expanded, setExpanded] = useState(false);

  const handleImageUpload = (
    imageRef: any,
    fieldName: "awi_sample_fov_image" | "awi_ppm"
  ) => {
    handleRefClick(imageRef);
  };
  useEffect(() => {
    let tempLocalErrors = formValidator(state, [
      "awi_ppm",
      "awi_max_mount_height",
      "awi_ideal_fov",
      "awi_sample_fov_image",
    ]);

    setErrorObject(tempLocalErrors);
    if (Object.values(tempLocalErrors).every((v) => !v)) {
      handleCompletedStages(stage);
    } else {
      handleCompletedStages(stage - 1);
    }
  }, [state]);

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <div className='app-registration-form-body'>
      {expanded && (
        <Modal
          className='fov-image-carousel'
          onClose={handleClose}
          open={expanded}
          title={"FOV Images"}
        >
          <div className='image-element-expanded'>
            <Carousel
              media={{
                images: state.awi_sample_fov_image,
                videos: [],
              }}
            ></Carousel>
          </div>
        </Modal>
      )}
      <div className='registration-form-subcontainer-heading'>
        FOV Information
        <img
          src='/assets/icons/caution.svg'
          title={appRegistrationToolTips.fovInfo}
          alt='tooltip'
        />
      </div>
      <div className='registration-form-visit-container'>
        Please visit :{" "}
        <a
          target='_blank'
          href='https://www.jvsg.com/calculators/cctv-lens-calculator/'
          style={{
            textDecoration: "underline",
            color: "blue",
          }}
          rel='noreferrer'
        >
          Reference Tool for FOV Images
        </a>
      </div>
      <div className='registration-general-details-row'>
        <div className='registration-general-details-row-inputcontainer'>
          <FormInput
            placeHolder='PPM Value'
            changeHandler={handleInputChange}
            type={"number"}
            required
            labelOnTop
            fieldName='awi_ppm_value'
            max={100}
            min={0}
            value={state.awi_ppm.ppm_value}
            error={error.awi_ppm}
            toolTip='Pixels per Meter (PPM) defines image resolution, indicating the number of pixels within one meter.'
          ></FormInput>
        </div>
        <div className='registration-general-details-row-inputcontainer'>
          <FormInput
            placeHolder='Recommended Camera Height (m) '
            changeHandler={handleInputChange}
            type={"number"}
            required
            labelOnTop
            fieldName='awi_max_mount_height'
            value={state.awi_max_mount_height}
            error={error.awi_max_mount_height}
            toolTip="The ideal mounting height for your camera influences the app's performance and field of view."
          ></FormInput>
        </div>
        <div className='registration-general-details-row-inputcontainer'>
          <MultiSelectDropdown
            placeholder={
              state.awi_ideal_fov[0]
                ? state.awi_ideal_fov[0]
                : "Ideal Field of View "
            }
            changeHandler={handleFOVDropdownChange}
            multiselect={false}
            options={fieldOfViews.map((item: any) => item.awi_name)}
            id='awi_ideal_fov'
            selectedValues={state.awi_ideal_fov}
            required
            error={error.awi_ideal_fov}
            allowOptionScroll
            showSelectedOptions={false}
            hideChip={true}
            toolTip='FOV represents the visible area in your app'
          ></MultiSelectDropdown>
        </div>
      </div>
      <div style={{ marginTop: "12px", display: "flex", gap: "3px" }}>
        FOV Images <span style={{ color: "red" }}> *</span>
      </div>

      <div className='upload-image-main-container'>
        <div className='upload-image-container'>
          <img
            onClick={(e) => handleRefClick(fovImageRef)}
            src={"assets/icons/Appathon_Upload.svg"}
            alt='Fov images'
            style={{ cursor: "pointer", width: "45px" }}
          ></img>

          <input
            ref={fovImageRef}
            type='file'
            onChange={handleFileUpload}
            className='file-upload-input'
            accept='image/*'
            id='awi_sample_fov_image'
            multiple
          ></input>
        </div>
        <div className='sample-fov-image-container'>
          {(state.awi_sample_fov_image || []).map(
            (item: any, index: number) => (
              <div key={index} className='sample-fov-single-image-container'>
                <img
                  className='sample-fov-image'
                  src={state.awi_sample_fov_image[index]}
                ></img>
                <div className='sample-fov-image-delete-container'>
                  <img
                    src='/assets/icons/appathon_image_delete.svg'
                    style={{ width: "30px", cursor: "pointer" }}
                    onClick={() => handleDeleteFovImage(index)}
                  ></img>
                </div>
              </div>
            )
          )}

          {(state.awi_sample_fov_image || []).length > 0 && (
            <div
              onClick={() => setExpanded(true)}
              style={{ cursor: "pointer" }}
            >
              View all
            </div>
          )}
        </div>
      </div>
      {(state.awi_sample_fov_image || []).length > 0 ? (
        <span>
          {(state.awi_sample_fov_image || []).length > 1 ? (
            `${(state.awi_sample_fov_image || []).length} images uploaded`
          ) : (
            <span>
              {`${(state.awi_sample_fov_image || []).length} image uploaded`}
            </span>
          )}
        </span>
      ) : (
        <span>
          {" "}
          <span style={{ color: "red" }}>
            {`${(state.awi_sample_fov_image || []).length} images uploaded`}
          </span>
        </span>
      )}

      <div className='registration-form-appinfo-container'>
        <div className='registration-form-appinfo-container-column'>
          <div className='registration-form-appinfo-container-column-heading-container'>
            <div className='registration-form-appinfo-checkbox-main-container'>
              <span style={{ color: "#676767", fontSize: "16px" }}>
                Does your App require GPU ?{" "}
              </span>
              <input
                type='checkbox'
                onChange={(e) => handleCheckboxSelect(e, "awi_gpu_required")}
                checked={state.awi_gpu_required}
                style={{ marginLeft: "10px" }}
              ></input>
              <img
                src='/assets/icons/caution.svg'
                title={appRegistrationToolTips.awi_gpu_required}
                style={{ marginLeft: "auto" }}
              />
            </div>
            {state.awi_gpu_required && (
              <div
                className='registration-form-appinfo-container-column-heading'
                style={{ marginTop: "20px" }}
              >
                Acceleration modes
              </div>
            )}
          </div>
          {state.awi_gpu_required && (
            <>
              <div className='registration-form-appinfo-checkbox-main-container'>
                <div className='registration-form-appinfo-checkbox-container'>
                  <input
                    type='checkbox'
                    onChange={(e) =>
                      handleCheckboxSelect(e, "awi_nvdia_deepstream")
                    }
                    checked={state.awi_nvdia_deepstream}
                  ></input>{" "}
                  NVIDIA DeepStream
                </div>
                <img
                  src='/assets/icons/caution.svg'
                  title={appRegistrationToolTips.awi_nvdia_deepstream}
                />
              </div>
              <div className='registration-form-appinfo-checkbox-main-container'>
                <div className='registration-form-appinfo-checkbox-container'>
                  <input
                    type='checkbox'
                    onChange={(e) =>
                      handleCheckboxSelect(e, "awi_intel_openvino")
                    }
                    checked={state.awi_intel_openvino}
                  ></input>{" "}
                  Intel OpenVino
                </div>
                <img
                  src='/assets/icons/caution.svg'
                  title={appRegistrationToolTips.awi_intel_openvino}
                />
              </div>
              <div className='registration-form-appinfo-checkbox-main-container'>
                <div className='registration-form-appinfo-checkbox-container'>
                  <input
                    type='checkbox'
                    onChange={(e) =>
                      handleCheckboxSelect(e, "awi_nvidia_tensorrt")
                    }
                    checked={state.awi_nvidia_tensorrt}
                  ></input>{" "}
                  NVIDIA TensorRT
                </div>
                <img
                  src='/assets/icons/caution.svg'
                  title={appRegistrationToolTips.awi_nvidia_tensorrt}
                />
              </div>
            </>
          )}
        </div>
        <div className='registration-form-appinfo-container-column'>
          <div className='registration-form-appinfo-container-column-heading-container'>
            <div className='registration-form-appinfo-container-column-heading '>
              Type of Cameras suitable for your App{" "}
              <span style={{ fontSize: "12px" }}>
                (selecting none assumes the app works best on all)
              </span>
            </div>
          </div>
          <div className='registration-form-appinfo-checkbox-main-container'>
            <div className='registration-form-appinfo-checkbox-container'>
              <input
                type='checkbox'
                onChange={(e) =>
                  handleCheckboxSelect(e, "awi_varifocal_camera")
                }
                checked={state.awi_varifocal_camera}
              ></input>{" "}
              Varifocal Camera Compatible
            </div>
            <img
              src='/assets/icons/caution.svg'
              title={appRegistrationToolTips.awi_varifocal_camera}
            />
          </div>
          <div className='registration-form-appinfo-checkbox-main-container'>
            <div className='registration-form-appinfo-checkbox-container'>
              <input
                type='checkbox'
                onChange={(e) =>
                  handleCheckboxSelect(e, "awi_handheld_compatible")
                }
                checked={state.awi_handheld_compatible}
              ></input>{" "}
              Handheld Camera Compatible
            </div>
            <img
              src='/assets/icons/caution.svg'
              title={appRegistrationToolTips.awi_handheld_compatible}
            />
          </div>
          <div className='registration-form-appinfo-checkbox-main-container'>
            <div className='registration-form-appinfo-checkbox-container'>
              <input
                type='checkbox'
                onChange={(e) => handleCheckboxSelect(e, "awi_ptz_compatible")}
                checked={state.awi_ptz_compatible}
              ></input>{" "}
              PTZ Compatible
            </div>
            <img
              src='/assets/icons/caution.svg'
              title={appRegistrationToolTips.awi_ptz_compatible}
            />
          </div>
        </div>
        <div className='registration-form-appinfo-container-column'>
          <div className='registration-form-appinfo-container-column-heading-container'>
            <div className='registration-form-appinfo-container-column-heading'>
              Can you provide Demo Video ?
            </div>
          </div>
          <div
            className={`upload-registration-video-container`}
            style={{ marginTop: "-30px" }}
          >
            <img
              onClick={(e) => handleRefClick(outputVideoRef)}
              src={"assets/icons/Appathon_Upload.svg"}
              alt='developer'
            ></img>
            <input
              ref={outputVideoRef}
              type='file'
              onChange={handleFileUpload}
              className='file-upload-input'
              accept='video/*'
              id='awi_demo_video'
              multiple
            ></input>
            <span>
              {(state.awi_demo_video || []).length > 0
                ? `${(state.awi_demo_video || []).length} ${
                    (state.awi_demo_video || []).length === 1
                      ? "video"
                      : "videos"
                  } uploaded`
                : ""}
            </span>
          </div>

          {loading.awi_demo_video && <Loader></Loader>}

          <div
            className='registration-form-appinfo-container-visibility-heading'
            style={{ marginTop: "20px" }}
          >
            Set your app’s visibility
          </div>
          <div className='registration-form-appinfo-visibility-container'>
            <input
              type='radio'
              onClick={(e) => handleCheckboxSelect(e, "awi_private")}
              checked={!state.awi_private}
              className='registration-form-appinfo-visibility-radio'
            ></input>
            <label>Public</label>
            <input
              type='radio'
              onClick={(e) => handleCheckboxSelect(e, "awi_private")}
              checked={state.awi_private}
              style={{ marginLeft: "10px" }}
              className='registration-form-appinfo-visibility-radio'
            ></input>
            <label>Private</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FovInfo;
