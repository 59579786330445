import { useEffect, useState } from "react";
import { paginationProps } from "../../../interfaces/pagination";

import "./styles.css";

function Pagination(props: paginationProps) {
    const [currentPage, setCurrentPage] = useState(props.currentPage);
    const [maxPages, setMaxPages] = useState(props.maxPages);
    useEffect(() => {
        setCurrentPage(props.currentPage);
    }, [props.currentPage]);
    useEffect(() => {
        setMaxPages(props.maxPages);
    }, [props.maxPages]);

    let items = [];
    let leftSide = currentPage - 1;
    let rightSide = currentPage + 1;
    if (rightSide > maxPages) {
        rightSide = maxPages;
        leftSide = maxPages - 2;
    }
    if (leftSide <= 0) {
        leftSide = 1;
        rightSide = Math.min(3, maxPages);
    }
    
    for (let number = leftSide; number <= rightSide; number++) {
        items.push(
            <div
                key={number}
                className={
                    number === currentPage
                        ? "round-effect active"
                        : "round-effect"
                }
                onClick={() => {
                    setCurrentPage(number);
                    props.handlePageChange(number);
                }}
            >
                {number}
            </div>
        );
    }
    const nextPage = () => {
        if (currentPage < maxPages) {
            props.handlePageChange(currentPage + 1);
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            props.handlePageChange(currentPage - 1);
            setCurrentPage(currentPage - 1);
        }
    };

    const paginationRender = (
        <div className="flex-container">
            <div className="paginate-ctn">
                <div className="round-effect" onClick={prevPage}>
                    {" "}
                    &lsaquo;{" "}
                </div>
                {items}
                <div className="round-effect" onClick={nextPage}>
                    {" "}
                    &rsaquo;{" "}
                </div>
            </div>
        </div>
    );
    return paginationRender;
}

export default Pagination;
