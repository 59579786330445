import React, { useState } from "react";
import {
  Dropdown,
  DropdownItemProps,
  TextArea,
  TextAreaProps,
} from "semantic-ui-react";
import Input from "../Input";
import { FormInputType } from "../../../typings/userLogin";
import "./styles.css";

const FormInput = ({
  labelText,
  placeHolder,
  changeHandler,
  type,
  value,
  checked,
  required,
  fieldName,
  error,
  max,
  min,
  step,
  options,
  labelOnTop,
  showValue,
  multiselect,
  dropdownChangeHandler,
  disabled,
  errorMessage,
  toolTip,
}: FormInputType) => {
  const [focussedOnce, setFocussedOnce] = useState(false);

  const formatDropdownOptions = (optionsArray: string[] | undefined) => {
    let dropdownItems: DropdownItemProps[] = [];
    optionsArray?.map((option) => {
      dropdownItems.push({
        text: option,
        value: option,
      });
    });

    return dropdownItems;
  };

  const getInputType = (type: string) => {
    switch (type) {
      case "dropdown":
        return (
          <Dropdown
            placeholder={placeHolder}
            fluid
            selection
            options={formatDropdownOptions(options)}
            multiple={multiselect ? true : false}
            onChange={dropdownChangeHandler}
          ></Dropdown>
        );
      case "textarea":
        return (
          <TextArea
            placeholder={placeHolder}
            classname="form-input-textarea"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement>,
              data: TextAreaProps
            ) => changeHandler(e, fieldName ?? labelText ?? "")}
            value={value as unknown as string}
          ></TextArea>
        );
      default:
        return (
          <Input
            placeholder={placeHolder}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              changeHandler(e, fieldName ?? labelText ?? "")
            }
            type={type}
            value={value}
            checked={checked}
            required={required}
            max={max}
            min={min}
            step={step}
            disabled={disabled}
            error={error ? error : undefined}
            hideSearch
            errorMessage={errorMessage}
            setInputFocussedOnce={() => setFocussedOnce(true)}
            toolTip={toolTip || ""}
          ></Input>
        );
    }
  };

  return (
    <>
      <div className={labelOnTop ? "label-header-top" : "label-header"}>
        {labelText && (
          <label className="form-input-label">
            {labelText} {showValue && <span>{value}</span>}
            {required && <span className="required-mark">*</span>}
          </label>
        )}

        {getInputType(type)}
        {error && focussedOnce && <span className="error">{errorMessage}</span>}
      </div>
    </>
  );
};

export default FormInput;
