import { useState } from "react";
import {  useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button  from "../common/button";

import { RootState } from "../../store";
import OrganisationModal from "../Organisations/organisationModal";
import ProfileAvatar from "./profileAvatar";
import "./styles.css";

interface IAppheaderProps {
    titleText?: string;
    iconUrl?: string;
    isLandingPage?: boolean;
    handlePageChange?: (activePage: string) => void;
}

    

    const navigationItems = [
      {
        itemText: <div>Organisations</div>,
        route: "/organisations",
        display: ["root", "requester", "approver"],
      },
      {
        itemText: <div>Users</div>,
        route: "/users",
        display: ["root", "requester", "approver"],
      },
      {
        itemText: <div>Orders</div>,
        route: "/view-orders",
        display: ["root", "admin", "requester", "approver"],
      },
      {
        itemText: <div>My Apps</div>,
        route: "/my-apps",
        display: ["root", "admin", "requester", "approver", "developer"],
      },
      {
        itemText: <div>All Apps</div>,
        route: "/",
        display: ["root", "admin", "requester", "approver", "developer"],
      },
      {
        itemText: <div>In Review Apps</div>,
        route: "/in-review",
        display: ["root", "requester", "approver"],
      },
    ];

    const headerActions = [
      {
        actionName: "Create Organisation",
        actionType: "create_org",
        display: ["root", "requester", "approver"],
      },
      {
        actionName: "Register App",
        actionType: "register_app",
        display: ["root", "developer"],
      },
      {
        actionName: "Invite Users",
        actionType: "invite_users",
        display: ["root", "requester", "approver"],
      },
    ];


function AppHeader(props: IAppheaderProps) {
    const isLoggedIn = useSelector(
        (state: RootState) => state.isLoggedIn
    ).value;

    const userObject = useSelector(
        (state: RootState) => state.userDetails
    )


    const navigator = useNavigate()

    const [showOrgModal,setShowOrgModal] = useState(false)
    const handleAction = (type:string) =>{
        switch (type) {
            case "create_org":
                // toggleCreateOrg()
                setShowOrgModal(!showOrgModal)
                break;
            case "register_app":
                navigator('/register-app')
                break;
            case "signup":
                navigator('/signup')
                break;
            case "login":
                navigator('/login')
                break;
            case "invite_users":
                navigator('/invite-users')
                break;
            default:
                break;
        }
    }

    const renderAppHeader = ()=>{
        if (!isLoggedIn)
        return(<div className="app-header">
            <Link to={"/"}>
                    <img
                        className="awiros-logo"
                        src="/assets/icons/Awiros_logo_white.svg"
                        alt="logo"
                    />
            </Link>    
            <div className="app-actions">
                <Button onClick={()=>handleAction('login')}>
                    <Link to={"/login"}> Login </Link>
                    </Button>
                    <Button onClick={()=>handleAction('signup')}>
                    <Link to={"/signup"}> Signup </Link>
                    </Button>
                </div>
        </div>
        )
        else{
         return(
            <div className="app-header">
                <Link to={"/"}>
                    <img
                        className="awiros-logo"
                        src="/assets/icons/Awiros_logo_white.svg"
                        alt="logo"
                    />
            </Link>
             
                <div className="app-heading">
                    {navigationItems.map((item)=>{
                        return (
                            item.display.includes(userObject.role[0]) &&
                            <NavLink to={item.route!} className={({isActive})=> !isActive?"landing-nav-item":"landing-nav-item-active"} state = {userObject}>
                                <li>
                                    {item.itemText}
                                </li>
                            </NavLink>
                        );
                    })}
                </div>
                <div className="app-actions">
                    {headerActions.map((item)=>{
                        return (
                            item.display.includes(userObject.role[0]) && <Button onClick={(e:any)=>handleAction(item.actionType)}>
                                
                                    {item.actionName}
                                
                            </Button>
                        );
                    })}
                </div>
             <div className="user-avatar">
                    <ProfileAvatar></ProfileAvatar>
            </div>
            {showOrgModal && <OrganisationModal show={showOrgModal} toggleModal={()=>{setShowOrgModal(!showOrgModal)}} />}    
        </div>
         )
        }
    }

    return (
        renderAppHeader()
    );
}

export default AppHeader;

