export const parseVersion = (version: string) => {
    let parsedVersion = Number(version?.replace(/[^0-9\.]+/g,""));
    if(isNaN(parsedVersion)){
        parsedVersion = 1.0;
    }
    else{
        
    }
    return `v ${parsedVersion.toPrecision(3)}`;
}


export const  renderDisplayStatus = (value:number)=>{
        switch(value){
            case 1:
                return "In Testing";
            case 2:
                return "In Production";
            case 3:
                return "Published";
            case 4:
                return "In Review";
            default:
                return "Under Development";
        }
    }

export const getStatusObjectFromDisplay = (value:string)=>{
    switch(value){
        case "In Testing":
            return {
                key:"test",
                value: 1
            };
        case "In Production":
            return {
                key:"prod",
                value: 2
            };
        case "Published":
            return {
                key:"publish",
                value: 3
            };
        case "In Review":
            return {
                key:"review",
                value: 4
            };
        default:
            return {
                key:"dev",
                value: 0
            };
    }
}