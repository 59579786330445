import React, { useEffect, useState } from "react";

import FormInput from "../../common/formInput";
import { signupFormValidator } from "../../../utils/formValidator";

import "./styles.css";
import { ILoginFormProps } from "../../../interfaces/loginFormProps";
import { SignupFormErrorObject, UserSignupForm } from "../../../typings/userLogin";
import { signUpService } from "../../../services/auth";
import Button  from "../../common/button";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";



const SignUp: React.FC<ILoginFormProps> = ({ handleActiveForm }) => {
    const [loginData, setLoginData] = useState<UserSignupForm>({
        Dropdown: "developer",
        FirstName: null,
        LastName: null,
        Email: null,
        Password: null,
        ConfirmPassword: null,
        Token:null
    });
    const [privacyPolicy,setPrivacyPolicy] = useState<boolean>(false)
    const [disableToken,setDisableToken] = useState<boolean>(false)
    const location = useLocation();

    const [errorObject, setError] = useState<SignupFormErrorObject>({
        Email:null,
        Password:null,
        Dropdown:null,
        FirstName:null,
        LastName:null,
        ConfirmPassword:null,
        Token:null
    });

    const handleInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>,
        fieldName: string
    ) => {
        let tempInputData: any = { ...loginData };
        tempInputData[fieldName] = e.target.value;
        setLoginData(tempInputData);
        
    };

    const handleUserToken = ()=>{
        const token = location.search.split("=")[1];
        if(token){
            let tempInputData: any = { ...loginData };
            setDisableToken(true)
            tempInputData["Token"] = token;
            setLoginData(tempInputData);
        }
    }

    useEffect(()=>{
        handleUserToken();
    },[location])
    const handleFormChange = async (
        value: string
    ) => {
        handleActiveForm(value);
    };

    const handleFormSubmit = async ()=>{
        const updatedErrors = signupFormValidator(loginData);
        setError(updatedErrors);
        if (Object.values(updatedErrors).every((v) => v === false)) {
          const response = await signUpService(loginData);
          if (response) {
            handleFormChange("Login");
          }
          else{
            toast.error("Something went wrong")
          }
        }else{
          toast.error("Please fill all the required fields")
        }
    }

    const handleSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        handleFormSubmit();
    };

    const dropdownOptions = [
      "developer",
      "admin",
      "engineer",
      "requester",
      "approver",
    ];

    return (
      <form onSubmit={() => handleFormSubmit()}>
        <div className="login-form">
          <div className="form-header-container">
            <h1 className="form-header">Signup</h1>
            <p className="form-description">
              Experience the World's first AI OS
            </p>
          </div>
          <div className="form-fields">
            <div>
              <label>I am a </label>
              <span className="required-mark">*</span>
            </div>
            <div>
              <select
                className="dropdown"
                onChange={(e) => handleInputChange(e, "Dropdown")}
              >
                {dropdownOptions.map((option) => (
                  <option className="dropdown-item">{option}</option>
                ))}
              </select>
            </div>
            <div className="two-column-field-container">
              <div>
                <FormInput
                  placeHolder="First Name"
                  changeHandler={handleInputChange}
                  type="text"
                  required={true}
                  fieldName="FirstName"
                  labelOnTop
                  value={loginData.FirstName ?? ""}
                  error={errorObject.FirstName}
                  errorMessage="First Name is required"
                />
              </div>
              <div>
                <FormInput
                  placeHolder="Last Name"
                  changeHandler={handleInputChange}
                  type="text"
                  required={true}
                  fieldName="LastName"
                  labelOnTop
                  value={loginData.LastName ?? ""}
                  error={errorObject.LastName}
                  errorMessage="Last Name is required"
                />
              </div>
            </div>
            <FormInput
              value={loginData.Email ?? ""}
              fieldName="Email"
              placeHolder="Email"
              changeHandler={handleInputChange}
              type="text"
              labelOnTop
              required={true}
              error={errorObject.Email}
              errorMessage="Invalid Email"
            />
            <FormInput
              value={loginData.Token ?? ""}
              placeHolder="Token"
              changeHandler={handleInputChange}
              type="text"
              required={true}
              labelOnTop
              fieldName="Token"
              error={errorObject.Token}
              errorMessage="Token is required"
              disabled={disableToken}
            />
            <div className="two-column-field-container">
              <div>
                <FormInput
                  fieldName="Password"
                  placeHolder="Password"
                  changeHandler={handleInputChange}
                  type="password"
                  required={true}
                  labelOnTop
                  value={loginData.Password ?? ""}
                  error={errorObject.Password}
                  errorMessage="Password is required"
                />
              </div>
              <div>
                <FormInput
                  placeHolder="Confirm Password"
                  changeHandler={handleInputChange}
                  type="password"
                  required={true}
                  fieldName="ConfirmPassword"
                  labelOnTop
                  value={loginData.ConfirmPassword ?? ""}
                  error={errorObject.ConfirmPassword}
                  errorMessage="Password does not match"
                />
              </div>
            </div>
          </div>
          <div className="form-footer">
            <div className="footer-privacy-policy">
              <input
                type="checkbox"
                className="checkbox"
                required={true}
                checked={privacyPolicy}
                onChange={(e) => setPrivacyPolicy(e.target.checked)}
              ></input>
              By signing up, I agree to the terms and conditions of this
              <a
                href="https://awi-genreal-bucket.s3.ap-southeast-1.amazonaws.com/EULA_Appathon.pdf"
                target="_blank"
              >
                user agreement.
              </a>
            </div>
            <Button
              className="login-button"
              type="primary"
              onClick={handleSignUp}
              disabled={!privacyPolicy}
            >
              SIGNUP
            </Button>
            <div className="footer-text">
              <p>
                Already have an account?{" "}
                <Button
                  onClick={(e) => {
                    handleFormChange("Login");
                  }}
                  type="primary"
                  className="form-link"
                >
                  Login
                </Button>
              </p>
            </div>
          </div>
        </div>
      </form>
    );
};

export default SignUp;
