import { useEffect, useState } from "react";
import ForgotPassword from "../../../components/Auth/forgotPasswordFrom";

import LoginForm from "../../../components/Auth/loginForm";
import SignUp from "../../../components/Auth/signupForm";
import UpdatePasswordComponent from "../../../components/Auth/updatePasswordForm";

// import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";

const UpdatePassword = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token") ?? "";
        if(!token){
            navigate("/login")
        }
        }, []
        
    )

    const handleActiveForm = (value: String) => {
        const navigationValue = value as string;
        navigate(`/${navigationValue}`, { replace: true })
    };

    return (
        <div>
            <div className="login-container">
                <img
                    className="login-image"
                    src="/assets/images/login.png"
                    alt="login"
                />
                
                
                    <UpdatePasswordComponent
                        handleActiveForm={handleActiveForm}
                    ></UpdatePasswordComponent>
            </div>
        </div>
    );
};

export default UpdatePassword;
