
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { RootState } from "../../../store"
import Button from "../../common/button"
import TokenModal from "../generateTokenModal"
import "./styles.css"

type organisationTableProps = {
    orgNameFilter:string|null|undefined,
    orgTableData :{
    apps_length: string, 
    org_name:string,
    org_id:number,
    total_orders:number,
    total_users:number,
    apps:any[]
}[]}


const tableHeadings = ["S.No.", "Organisation","Users","Apps","Active Licence","Expired Licence","Total Orders","Actions"]

function OrganisationTable({orgNameFilter,orgTableData}:organisationTableProps) {
  
    const navigator = useNavigate()
    const [showModal,setShowModal] = useState(false)
    const [selectedOrgId,setSelectedOrgId] = useState<number|null>(null)
    const [filteredOrgTable,setFilteredOrgTable] = useState<any>(null)

    const userObject = useSelector(
        (state: RootState) => state.userDetails
    )

    const checkRootOrApprover = ()=>{
        let isRootorApprover = false
        isRootorApprover = userObject.role.includes("root") || userObject.role.includes("approver")
        return isRootorApprover
    }

    const handleCreateOrder =(idx:number) =>{
        navigator('/organisations/create-order',{
            state:{
                org_id:idx,
                org_name:orgTableData.filter((entry)=>entry.org_id===idx)[0].org_name
            }
        })
    }

    const handleGenerateToken = (idx:number) =>{
        setSelectedOrgId(idx)
        setShowModal(true)
    }

    const fetchRowActions = (idx:number) => {
        return (
            <div className="organisation-table-actions">
                <Button onClick={()=>handleCreateOrder(idx)}>Create Order</Button>
                {checkRootOrApprover() &&  <Button onClick={()=>handleGenerateToken(idx)}>Generate Token</Button>}
            </div>
        )
    }

    useEffect(()=>{
        let tempOrgdata = orgTableData
        if(orgNameFilter)
        tempOrgdata = tempOrgdata.filter((row)=>{
            return row.org_name.toLowerCase().includes(orgNameFilter?.toLowerCase())
        })
        setFilteredOrgTable(tempOrgdata)
    },[orgNameFilter, orgTableData])

    const fetchTableRows = () => {
        return (
            filteredOrgTable?.map((rowItem:any,idx:number)=>{
                return (
                <tr>
                <td className="td-number">
                    {idx+1}
                </td>
                
                <td>
                    {rowItem.org_name}
                </td>
                <td className="td-number">
                    {rowItem.total_users}
                </td>
                <td className="td-number">
                    {rowItem?.total_apps}
                </td>
                <td className="td-number">
                    {rowItem?.active_licences}
                </td>
                <td className="td-number">
                    
                    {rowItem?.total_licences - rowItem?.active_licences}
                    
                </td>
                <td className="org-order-cell td-number">
                    <Link to={"/view-orders"} state={{org_id:rowItem.org_id}}>
                    {rowItem?.total_orders}
                    </Link>
                </td>
                <td>{fetchRowActions(rowItem?.org_id)}
                </td>
                </tr>
                )
            })
        )
    }

    return (
    <div className='organisation-table'>
        <table className="table">
            <thead>
                {
                    tableHeadings.map((heading)=>{
                        return <th>{heading}</th>
                    })
                }
            </thead>
            <tbody>
                {fetchTableRows()}
            </tbody>
        </table>
        {showModal && <TokenModal show = {showModal} toggleModal={()=>setShowModal(!showModal)} org_id = {selectedOrgId??0} ></TokenModal>}
    </div>
  )
}

export default OrganisationTable