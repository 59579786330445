import SignUp from "../../../components/Auth/signupForm";

import {  useNavigate } from "react-router-dom";

const Signup = () => {

    const navigate = useNavigate();


    

    const handleActiveForm = (value: String) => {
        const navigationValue = value as string;
        navigate(`/${navigationValue}`, { replace: true })
    };

    return (
        <div>
            <div className="login-container">
                <img
                    className="login-image"
                    src="/assets/images/login.png"
                    alt="login"
                />
               

                    <SignUp handleActiveForm={handleActiveForm}></SignUp>
            </div>
        </div>
    );
};

export default Signup;
