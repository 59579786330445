import { useEffect, useState } from "react";
import "./styles.css";
import { toggleStatus } from "../../services/hydra";

export default function StatusToggle({
  isEnabled,
  appId,
}: {
  isEnabled: boolean;
  appId: number;
}) {
  const [isToggled, setIsToggled] = useState(isEnabled);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleToggleChange = async () => {
    setIsDisabled(true);
    try {
      const response = await toggleStatus({ appId: appId, enable: !isToggled });
      if (response) {
        setIsToggled((prevState) => !prevState);
      }
    } catch (error) {}
    setIsDisabled(false);
  };

  useEffect(() => {
    setIsToggled(isEnabled);
  }, [isEnabled]);

  return (
    <div className='container'>
      <h3 className='text'>Hydra</h3>
      <label className='switch'>
        <input
          type='checkbox'
          checked={isToggled}
          onChange={handleToggleChange}
          disabled={isDisabled}
        />
        <span className='slider'></span>
      </label>
    </div>
  );
}
