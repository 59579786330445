import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormInput from "../../common/formInput";
import { loginFormValidator } from "../../../utils/formValidator";
import "./styles.css";
import { ILoginFormProps } from "../../../interfaces/loginFormProps";
import {
  LoginFormErrorObject,
  UserLoginForm,
} from "../../../typings/userLogin";
import { loginService } from "../../../services/auth";
import Button from "../../common/button";
import { loginSuccess } from "../../../pages/Auth/login/loginSlice";
import { getUserRole } from "../../../services/user";
import { toast } from "react-toastify";

const LoginForm: React.FC<ILoginFormProps> = ({ handleActiveForm }) => {
  const [loginData, setLoginData] = useState<UserLoginForm>({
    Email: null,
    Password: null,
    Captcha: "test",
    RememberMe: false,
    uuid: "test",
  });

  const [errorObject, setError] = useState<LoginFormErrorObject>({
    Email: null,
    Password: null,
    Captcha: null,
  });

  //   const [captcha, setCaptcha] = useState("");

  const dispatch = useDispatch();

  let navigator = useNavigate();

  //   useEffect(() => {
  //     getCaptcha();
  //   }, []);

  //   const getCaptcha = async () => {
  //     let captchaResponse = await generateCaptcha();
  //     let tempCaptcha = "data:image/jpeg;base64, " + captchaResponse.img;
  //     setCaptcha(tempCaptcha);

  //     setLoginData((prev) => {
  //       let tempInputData = { ...prev };
  //       tempInputData.uuid = captchaResponse.uuid;
  //       return tempInputData;
  //     });
  //   };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    let tempInputData: any = { ...loginData };
    tempInputData[fieldName] = e.target.value;
    setLoginData(tempInputData);
  };

  const toggleRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => {
      let tempInputData = { ...prev };
      tempInputData.RememberMe = e.target.checked;
      return tempInputData;
    });
  };

  const handleFormChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    value: string
  ) => {
    e.preventDefault();
    handleActiveForm(value);
  };

  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const updatedErrors = loginFormValidator(loginData);

    setError(updatedErrors);
    if (Object.values(updatedErrors).every((v) => v === false)) {
      let loginStatus = await loginService(loginData);

      if (loginStatus) {
        let userRole = await getUserRole({});
        console.log(userRole, "user role");
        dispatch(loginSuccess(userRole));
        navigator("/", { replace: true });
      }
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  return (
    <div className='login-form'>
      <div className='form-header-container'>
        <h1 className='form-header'>Login</h1>
        <p className='form-description'>Experience the World's first AI OS</p>
      </div>
      <div className='form-fields'>
        <FormInput
          fieldName='Email'
          placeHolder='Email'
          changeHandler={handleInputChange}
          type='text'
          required={true}
          error={errorObject.Email}
          labelOnTop
          value={loginData.Email ?? ""}
          errorMessage={"Invalid Email Address"}
        />
        <FormInput
          fieldName='Password'
          placeHolder='Password'
          changeHandler={handleInputChange}
          type='password'
          required={true}
          error={errorObject.Password}
          labelOnTop
          value={loginData.Password ?? ""}
          errorMessage={"Password must contain atleast 7 characters"}
        />
        {/* <div className="captcha-field-container">
                <div className="captcha-label">
                    <label>Captcha</label>
                    <span className="required-mark">*</span>
                </div> 
                <div className="captcha-container">
                    <div className="captcha-image-container">
                        <img
                            src={captcha}
                            alt="captcha"
                            className="captcha-image"
                        ></img>
                        <FontAwesomeIcon
                            icon={faRefresh}
                            fontSize="25px"
                            onClick={() => getCaptcha()}
                        />
                    </div>
                    <Input
                        className="captcha-text"
                        placeholder="Enter Captcha Here"
                        onChange={(e) => handleInputChange(e, "Captcha")}
                        required={true}
                        type="text"
                    />
                </div>
                </div> */}
        <div className='remember-me-container'>
          <div></div>
          <Button
            className='forgot-password-button'
            onClick={(e) => {
              handleFormChange(e, "forgot-password");
            }}
          >
            Forgot Password?
          </Button>
        </div>
        <div className='remember-me-container'>
          <div>
            <input
              onChange={toggleRememberMe}
              type='checkbox'
              value={"Remember Me"}
              checked={loginData.RememberMe}
              id='remember_me_checkbox'
            />
            <label htmlFor='remember_me_checkbox'>Keep me logged in</label>
          </div>
        </div>
      </div>
      <div className='form-footer'>
        <Button className='login-button' onClick={handleLogin} type='primary'>
          LOGIN
        </Button>

        <div className='footer-text'>
          <p>
            Here for the first time?{" "}
            <Button
              onClick={(e) => {
                handleFormChange(e, "signup");
              }}
              className='form-link'
              type='primary'
            >
              Signup
            </Button>{" "}
            to get a first-hand experince
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
