import React, { useEffect, useState } from "react";
import { AppConfig, DemoVideo } from "../../../interfaces/appRegistration";
import { formValidator } from "../../../utils/formValidator";
import Textarea from "../../common/textarea";
import Loader from "../../common/loader";

type Props = {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => void;
  state: any;
  errorObject: any;
  handleCompletedStages: (stage: number) => void;
  stage: number;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<string>;
  handleRefClick: (ref: any) => void;
  fovImageRef: any;
  removeItemFromArray: (idx: number, fieldName: string) => void;
  loading: any;
  handleAppConfig: (data: AppConfig) => void;
  handleAddDemoVideoConfig: (demoVideo: DemoVideo) => void;
  handleDelDemoVideoConfig: (idx: number) => void;
};

// Default configuration
const defaultConfig = { version: 1, appConfig: {}, srcConfig: {} };

// Initial state
const initialState = { config: defaultConfig, video: "" };

function AppConfigInfo({
  handleInputChange,
  state,
  errorObject,
  handleCompletedStages,
  stage,
  handleFileUpload,
  handleRefClick,
  fovImageRef,
  removeItemFromArray,
  loading,
  handleAppConfig,
  handleAddDemoVideoConfig,
  handleDelDemoVideoConfig,
}: Props) {
  const [error, setErrorObject] = useState<any>({});
  const [appConfigPairs, setAppConfigPairs] = useState<DemoVideo[]>([]);
  const [uploadLoadingStates, setUploadLoadingStates] = useState<boolean[]>([]); // Initialize loading states

  useEffect(() => {
    // Function to check if a config is modified from the default config
    const isConfigModified = (config: AppConfig) => {
      return (
        JSON.stringify(config) !== JSON.stringify(defaultConfig) &&
        Object.keys(config.appConfig).length > 0
      );
    };

    // Function to check if the pair is valid
    const isPairComplete = (pair: DemoVideo) => {
      return isConfigModified(pair.config) && pair.video !== "";
    };

    // Check if all pairs are complete
    const allPairsComplete = appConfigPairs.every(isPairComplete);

    if (allPairsComplete) {
      handleCompletedStages(stage); // All pairs complete, mark form as complete
    } else {
      handleCompletedStages(-1); // Incomplete form, mark as incomplete
    }
  }, [appConfigPairs, stage, handleCompletedStages]);

  useEffect(() => {
    const initializedPairs =
      state.demoVideos.length > 0
        ? state.demoVideos.map((video: any) => ({
            config: video.config || defaultConfig,
            video: video.video,
          }))
        : [initialState];

    setAppConfigPairs(initializedPairs);
    setUploadLoadingStates(new Array(initializedPairs.length).fill(false)); // Reset loading states
  }, [state.demoVideos]);

  const handleAddConfigPair = () => {
    const newPair = {
      ...initialState,
    };
    setAppConfigPairs((prev) => [...prev, newPair]);
    setUploadLoadingStates((prev) => [...prev, false]); // Add a loading state for the new pair
    handleAddDemoVideoConfig(newPair);
  };

  const handleDeleteConfigPair = (index: number) => {
    if (appConfigPairs.length > 1) {
      const newPairs = [...appConfigPairs];
      newPairs.splice(index, 1); // Remove the item at the given index
      setAppConfigPairs(newPairs);
      setUploadLoadingStates((prev) => {
        const newLoadingStates = [...prev];
        newLoadingStates.splice(index, 1); // Remove loading state at index
        return newLoadingStates;
      });
      handleDelDemoVideoConfig(index); // Remove associated demo video config
    }
  };

  const handleConfigChange = (index: number, value: string) => {
    try {
      const newConfig = JSON.parse(value);
      const newPairs = [...appConfigPairs];
      newPairs[index].config = newConfig;
      setAppConfigPairs(newPairs);
      handleAddDemoVideoConfig(newPairs[index]);
    } catch (error) {
      console.error("Invalid JSON input for config");
    }
  };

  const handleVideoChange = async (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Set loading state for the specific index
    setUploadLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = true;
      return newStates;
    });

    try {
      const videoUrl = await handleFileUpload(e);
      if (videoUrl) {
        const newPairs = [...appConfigPairs];
        newPairs[index].video = videoUrl;
        setAppConfigPairs(newPairs);
        handleAddDemoVideoConfig(newPairs[index]);
      }
    } catch (error) {}

    // Reset loading state after upload
    setUploadLoadingStates((prev) => {
      const newStates = [...prev];
      newStates[index] = false;
      return newStates;
    });
  };

  const onAppConfigInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    try {
      const appConfig = JSON.parse(e.target.value);
      handleAppConfig(appConfig);
    } catch (err) {
      console.error("Invalid app JSON config");
    }
  };

  const downloadConfigFromURL = async () => {
    const configUrl =
      "https://cloud-trial.appstack.awiros.com/public/assets/config.json";
    try {
      const response = await fetch(configUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const configData = await response.json();

      const blob = new Blob([JSON.stringify(configData, null, 2)], {
        type: "application/json",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "app-config.json";
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Failed to download config file:", error);
    }
  };

  return (
    <form className='app-registration-form-body'>
      <div className='registration-form-visit-container'>
        <div
          onClick={downloadConfigFromURL}
          style={{
            color: "blue",
            textDecoration: "underline",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          Download Default Config
        </div>
      </div>
      <div>
        <Textarea
          value={JSON.stringify(state.config, null, 2)}
          onChange={onAppConfigInput}
          placeholder='Enter App Config'
        />
      </div>
      <div className='input-info-key'>Add Config and Demo Videos</div>
      <div className='registration-general-details-row'>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {appConfigPairs.map((pair, index) => (
            <div
              className='registration-general-details-row-inputcontainer'
              key={pair.video}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <div className='registration-general-details-row-inputcontainer'>
                <Textarea
                  value={JSON.stringify(pair.config, null, 2)}
                  onChange={(e) => handleConfigChange(index, e.target.value)}
                  placeholder='Enter App Config'
                />
              </div>
              {uploadLoadingStates[index] && <Loader />}{" "}
              {/* Show loader for specific index */}
              {pair.video && (
                <video
                  className='sample-fov-image'
                  src={pair.video}
                  controls
                  style={{ width: "100px", marginTop: "8px" }}
                ></video>
              )}
              <div className='upload-image-main-container'>
                <div className='upload-image-container'>
                  <img
                    onClick={() => handleRefClick(fovImageRef)}
                    src={"assets/icons/Appathon_Upload.svg"}
                    alt='Upload Video'
                    style={{ cursor: "pointer", width: "45px" }}
                  />
                  <input
                    ref={fovImageRef}
                    type='file'
                    onChange={(e) => handleVideoChange(index, e)}
                    className='file-upload-input'
                    accept='video/*'
                    id={`demoVideos-${index}`}
                  />
                </div>
              </div>
              {index === 0 ? (
                <img
                  style={{ width: "46px", marginTop: "8px", cursor: "pointer" }}
                  src='/assets/icons/appathon_input.svg'
                  onClick={handleAddConfigPair}
                  alt='Add Config and Video Pair'
                />
              ) : (
                <img
                  style={{ width: "46px", marginTop: "8px", cursor: "pointer" }}
                  src='/assets/icons/appathon_delete.png'
                  alt='Delete Config and Video Pair'
                  onClick={() => handleDeleteConfigPair(index)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </form>
  );
}

export default AppConfigInfo;
