import { useRef } from "react";
import useOutsideAlerter from "../../../hooks/OutsideAlerter";
import Button from "../button";
import "./styles.css"

type ModalProps = {
    children: React.ReactNode;
    className?: string;
    onClose: () => void;
    open: boolean;
    title?: string;
    hideModal?: boolean;
}


const Modal = ({children,onClose,open,title,hideModal,className}:ModalProps)=>{

  const handleModalClose = (e:any) => {  
    e.stopPropagation()
    onClose();
  }

  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(modalRef, onClose);
  return open ? (
    <div className="common-modal-wrapper">
      <div className="common-modal-backdrop"></div>
      {!hideModal && (
        <div
          className={
            className
              ? `common-modal-container ${className}`
              : "common-modal-container"
          }
          ref={modalRef}
        >
          <div className="modal-header">
            <Button className="modal-close-button" onClick={handleModalClose}>
              X
            </Button>
            <h3 className="modal-title">{title}</h3>
          </div>
          {children}
        </div>
      )}
      {hideModal && (
        <div className="common-modal-without-container">
          <div className="modal-children-wrapper" ref={modalRef}>
            {children}
          </div>
        </div>
      )}
    </div>
  ) : null;
}

export default Modal