import "./styles.css"

type IconProps = {
    height?:string,
    width?:string,
    color?:string,
    src?:string,
    className?:string,
    onClick?:(e?:any)=>void,
    title?:string,
    padding?:string
}

function Icon({height,width,color,src,className,title,onClick,padding}:IconProps) {
  return (
    <div 
        className={`icons-container ${className?className:''}`} 
        style = {{height:height,width,color,padding}} 
        title = {title}
        onClick = {onClick}
    >
        <img src={src} alt = {title?title:'icon'}/>
    </div>
  )
}

export default Icon