import { useEffect, useState } from "react";
import DragAndDrop from "../../common/dragAndDropFileUpload";
import Papa from "papaparse";
import Button from "../../common/button";
import MultiSelectDropdown from "../../common/multiSelectDropdown";
import { bulkInviteUser } from "../../../services/user";
import { toast } from "react-toastify";
import "./styles.css"
const roles = ["root", "admin", "requester", "approver", "developer"];

function BulkInvite() {
  const [file, setFile] = useState<FileList | null>(null);
  const [csvData, setCSVData] = useState<any[]>([]);
  const [role, setRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (file) {
      parseCSVFile(file[0]);
    }
  }, [file]);

  const uploadFile = async (file: FileList) => {
    const selectedFiles = file;
    setFile(selectedFiles);
    console.log("Upload file");
  };

  const sendInvite = async () => {
    console.log("send invite");
    let payload = {
      users: csvData,
      role: role,
    };
    setLoading(true);
    console.log(payload, "payload");
    let response = await bulkInviteUser(payload);
    console.log(response, "response");
    if (response) {
      toast.success("Invite sent successfully");
      // resetState();
    }
    setLoading(false);
  };
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      console.log(file);
      uploadFile(e.target.files!);
    }
  };

  const parseCSVFile = async (file: File) => {
    const csvFile = file;
    // console.log(csvFile, "csv file");
    const csvData = await new Promise<any[]>((resolve, reject) => {
      Papa.parse(csvFile, {
        header: true,
        complete: function (results) {
          console.log(results,"askhjdbnasjkdn");
          // setCSVData(results.data);
          resolve(results.data);
        },
        error(err, file) {
          console.log(err);
          reject(err);
        },
      });
    });
    let dataToSet = csvData?.map((item: any) => {
      if(item && item["email"] && item["team"])
      return {
        email: item["email"]?.trim(),
        organisation: item["team"]?.trim(),
      };
      else
      return null;
    });
    dataToSet = dataToSet.filter((item:any)=> item);
    console.log(dataToSet,"data to set");
    if(dataToSet.length > 0)
    setCSVData(dataToSet);
    else {
      toast.error("Invalid CSV file");
    }
  };

  const selectRole = (event: any, data: string[]) => {
    console.log(data, "data");
    setRole(data[0]);
  };
  const resetState = () => {
    setFile(null);
    setCSVData([]);
    setRole("");
  }
  const renderPopupData = () => {
    if (csvData.length > 0) {
      return (
        <div className="csv-uploader-container">
          <div>
            <h1>Uploaded Data</h1>
            <MultiSelectDropdown
              placeholder={"Select a role"}
              changeHandler={selectRole}
              multiselect={false}
              options={roles}
            ></MultiSelectDropdown>
          </div>
          <div className="csv-uploader-header">
            <h3>Email</h3>
            <h3>Organisation</h3>
          </div>
          <div className="csv-uploader-body">
            {csvData.map((item) => {
              return (
                <div className="csv-uploader-list-item">
                  <p>{item?.email}</p>
                  <p>{item?.organisation}</p>
                </div>
              );
            })}
          </div>
          <div className="csv-uploader-footer">
            <Button onClick={sendInvite}>Invite</Button>
            <Button onClick={resetState}>Reset</Button>
          </div>
        </div>
      );
    } else {
      return (
        <DragAndDrop handleFileSelect={uploadFile}>
          <div className="drag-and-drop-container">
            <img src="/assets/icons/upload-icon.svg" alt="upload" />
            <div>
              <div>Drag and drop a file to upload here</div>
            </div>
            <div>or</div>
            <div>
              {/* eslint-disable-next-line  */}
              <input
                id="upload-csv"
                hidden
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="upload-csv">
                <span>Browse Files</span>
              </label>
            </div>
          </div>
        </DragAndDrop>
      );
    }
  };

  return renderPopupData();
}

export default BulkInvite;
