// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.csv-uploader-container{
    height: 100%;
    width: 80%;
    padding: 0 auto;
}
.csv-uploader-header{
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
    > h3{
        margin: 0;
    }
}
.csv-uploader-body{
    margin-top: 10px;
    overflow-y: auto;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 2rem;
}
.csv-uploader-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.csv-uploader-footer{
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
    > button{
        margin-left: 10px;
    }
}
.drag-and-drop-container{
    display: flex;
    flex-direction: column;
    height: 600px;
    width: 800px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    > p{
        margin: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Users/BulkInvite/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,eAAe;AACnB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB;QACI,SAAS;IACb;AACJ;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS;IACT,aAAa;AACjB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB;QACI,iBAAiB;IACrB;AACJ;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,kBAAkB;IAClB;QACI,SAAS;IACb;AACJ","sourcesContent":[".csv-uploader-container{\n    height: 100%;\n    width: 80%;\n    padding: 0 auto;\n}\n.csv-uploader-header{\n    display: flex;\n    justify-content: space-between;\n    padding: 2rem;\n    align-items: center;\n    > h3{\n        margin: 0;\n    }\n}\n.csv-uploader-body{\n    margin-top: 10px;\n    overflow-y: auto;\n    height: 400px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    gap: 10px;\n    padding: 2rem;\n}\n.csv-uploader-list-item{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 10px;\n}\n.csv-uploader-footer{\n    display: flex;\n    justify-content: space-between;\n    padding: 2rem;\n    align-items: center;\n    > button{\n        margin-left: 10px;\n    }\n}\n.drag-and-drop-container{\n    display: flex;\n    flex-direction: column;\n    height: 600px;\n    width: 800px;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    border: 2px dashed #ccc;\n    border-radius: 5px;\n    > p{\n        margin: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
