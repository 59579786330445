import { toast } from "react-toastify";
import { gelAllTerminalsResponse } from "../exampleResponses/getAllTerminal";
import { getAllCameras } from "../exampleResponses/listOfAllCameras";
import { listOfTargetIndustries } from "../exampleResponses/listOfTargetIndustries";
import { GetMatrixResponse } from "../typings/orchestrator";

import { client } from "./axiosInstance";

const getMatrix = async () => {
    let response = await client<GetMatrixResponse>("/app_source_matrix_data", {
        baseURL: "base",
        method: "POST"
    });
    if (response.data.success) return response.data;
    else toast.error("Data could not be fetched");
};

const getAllTerminals = () => {
    let response = gelAllTerminalsResponse;

    return response;
};

const listAllIndustries = () => {
    let response = listOfTargetIndustries.data;

    return response;
};

const listAllCameras = () => {
    let response = getAllCameras.data;

    return response;
};

export { getMatrix, getAllTerminals, listAllIndustries, listAllCameras };
