import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../../hooks/OutsideAlerter";

import "./styles.css";

type MultiSelectProps = {
  placeholder: string;
  options?: string[];
  changeHandler: (event: any, data: string[]) => void;
  multiselect: boolean;
  id?: string;
  selectedValues?: string[];
  required?: boolean;
  error?: boolean;
  allowOptionScroll?: boolean;
  hideChip?: boolean;
  showSelectedOptions?: boolean;
  toolTip?: string | null;
};

function MultiSelectDropdown({
  placeholder,
  options,
  changeHandler,
  multiselect,
  selectedValues,
  id,
  required,
  error,
  allowOptionScroll,
  hideChip,
  showSelectedOptions = true,
  toolTip = null,
}: MultiSelectProps) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [inputFocussed, setInputFocussed] = useState<boolean>(false);
  const [focussedOnce, setFocussedOnce] = useState<boolean>(false);
  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      setSelectedOptions(selectedValues);
      setInputFocussed(true);
      // setFocussedOnce(true);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (options) {
      if (searchText !== "") {
        setFilteredOptions(
          options.filter((option) =>
            option.toLowerCase().includes(searchText.toLowerCase())
          )
        );
        setInputFocussed(true);
      } else {
        setFilteredOptions(options);
        setInputFocussed(false);
      }
    }
  }, [options, searchText]);

  const renderDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value.trim());
    e.stopPropagation();
  };

  const handleInputClick = (e: any) => {
    e.stopPropagation();
    if (!showDropdown) {
      setShowDropdown(true);
    }
  };
  const checkIndex = (option: string) => {
    if (selectedOptions.indexOf(option) === -1) {
      return false;
    }
    return true;
  };

  const closeDropDownMenu = () => {
    setShowDropdown(false);
    setInputFocussed(false);
  };

  const removeSelectedOption = (
    e: React.MouseEvent | ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    e.stopPropagation();
    const newSelectedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption !== option
    );
    setSelectedOptions(newSelectedOptions);
    changeHandler(e, newSelectedOptions);
  };

  const handleOptionSelect = (
    e: React.MouseEvent | ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    e.stopPropagation();

    let tempOptions = [...selectedOptions];
    let index = selectedOptions.indexOf(option);

    if (index !== -1) {
      tempOptions.splice(index, 1);
    } else {
      if (multiselect) {
        tempOptions.push(option);
      } else {
        tempOptions[0] = option;
      }
    }
    if (!multiselect) {
      setShowDropdown(false);
    }
    setSelectedOptions(tempOptions);
    setSearchText("");
    changeHandler(e, tempOptions);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, closeDropDownMenu);
  const handleLabelClick = (e: React.MouseEvent) => {
    setInputFocussed(true);
  };

  const handleFocus = (e: any) => {
    setInputFocussed(true);
    !focussedOnce && setFocussedOnce(true);
  };
  const handleBlur = (e: any) => {
    if (searchText === null || searchText === undefined) {
      setInputFocussed(false);
    } else {
      if (typeof searchText === "string" && searchText.trim() === "") {
        setInputFocussed(false);
      }
    }
  };
  return (
    <div
      className='multi-select-dropdown'
      ref={wrapperRef}
      onClick={renderDropdownMenu}
    >
      <div
        className={`multi-select-value ${focussedOnce && error ? "error" : ""}`}
        style={{ border: "1px solid black" }}
        id={id}
      >
        <label
          className={`common-dropdown-input-label ${
            inputFocussed && "field-active"
          }`}
          title={placeholder}
          onClick={handleLabelClick}
        >
          {placeholder} {required && <span className='required-flag'>*</span>}
        </label>
        <input
          className='dropdown-search-input'
          onClick={handleInputClick}
          onChange={handleInputChange}
          value={searchText}
          onFocus={handleFocus}
          onBlur={handleBlur}
        ></input>
        <div
          className='dropdown-icon'
          style={{ display: "flex", alignItems: "center" }}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </div>

        {toolTip && (
          <div className='error'>
            <img
              src='/assets/icons/caution.svg'
              alt={toolTip}
              className='error'
              title={toolTip || ""}
              style={{ marginLeft: "10px", cursor: "auto" }}
            ></img>
          </div>
        )}
      </div>
      {showDropdown && (
        <div className='dropdown-items-container'>
          {filteredOptions?.map((option, i) => {
            return (
              <div
                className={
                  !checkIndex(option)
                    ? "multi-dropdown-item"
                    : "multi-dropdown-item-selected"
                }
                onClick={(e) => handleOptionSelect(e, option)}
                id={id}
              >
                <label className='multi-dropdown-item-label' id={id}>
                  {option}
                </label>
                <br></br>
              </div>
            );
          })}
        </div>
      )}
      {selectedOptions.length > 0 && (!hideChip || showSelectedOptions) && (
        <div className={`selected-values-container`}>
          {selectedOptions.map((option) => {
            return (
              <span className='dropdown-selected-values'>
                {option}{" "}
                <button
                  className='chip-remove-button'
                  onClick={(e) => removeSelectedOption(e, option)}
                  id={id}
                >
                  <img
                    src={"/assets/icons/close.svg"}
                    alt='cancel'
                    id={id}
                  ></img>
                </button>
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MultiSelectDropdown;
