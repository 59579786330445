import { useEffect, useState } from "react";
import Carousel from "../Carousel";
import Loader from "../loader";
import Modal from "../modal";

import "./styles.css"
type ImageProps = {
    src: string | string [];
    alt: string;
    className?: string;
    onClick?: () => void;
    expandOnClick?: boolean;
    showBackdrop?: boolean;
    hideModal?: boolean;
    idx?: number;
}


const Image = ({src,alt,className,onClick,expandOnClick,showBackdrop,hideModal,idx}:ImageProps) => {
    const [expanded, setExpanded] = useState(false);
    const [loaded,setLoaded] = useState(false);
    const [carouselImageLoaded,setCarouselImageLoaded] = useState(false)
    const [image_array,setImageArray] = useState<string[]>([]);

    useEffect(() => {
        if(Array.isArray(src)){
            let tempSrc = src.slice(idx!+1).concat(src.slice(0,idx));
            setImageArray(tempSrc);
        }
        else{
            setImageArray([src]);
        }
    },[src])
    const handleLoaded = () => {
        setLoaded(true);
    }

    const handleCarouselImageLoaded = () => {
        setCarouselImageLoaded(true);
    }

    const handleClick = (e:any) => {
        if (onClick) {
            onClick();
        }
        expandOnClick && setExpanded(true);
        expandOnClick && e.stopPropagation()
    }

    const handleClose = () => {

        setExpanded(false);
    }




    return (
    <div className={`image-element-container ${className}`} onClick={handleClick}>
        {
            !loaded && 
             <div className="image-loader"><Loader></Loader></div>
        }
        <img className={`image-element ${showBackdrop && 'image-backdrop'}`} src={image_array[0]} alt={alt} onLoad = {handleLoaded} onError = {handleLoaded}/>
        {
            expanded && 
            <Modal onClose={handleClose} open = {expanded} title={alt} hideModal = {hideModal}>
                {
                !loaded && 
                <div className="image-loader"><Loader></Loader></div>
                }
                
                <div className="image-element-expanded">
                    <Carousel
                        media={{
                            images: image_array,
                            videos: []
                        }}
                        // addBackdrop={true}
                    ></Carousel>    
                </div>
            </Modal>
        }
    </div>
  )
}

export default Image