import React, { useEffect, useState } from "react";

import FormInput from "../../common/formInput";
import { updatePasswordValidator } from "../../../utils/formValidator";

import "./styles.css";
import { ILoginFormProps } from "../../../interfaces/loginFormProps";
import {
    UpdatePasswordErrorObject,
    UpdatePasswordForm
} from "../../../typings/userLogin";
import Button from "../../common/button";
import { updatePasswordService } from "../../../services/auth";
import { useNavigate } from "react-router-dom";


const UpdatePassword: React.FC<ILoginFormProps> = ({ handleActiveForm }) => {
    const [loginData, setLoginData] = useState<UpdatePasswordForm>({
        Email: null,
        Password: null,
        Captcha: null,
        ConfirmPassword: null,
        NewPassword: null,
        Token: null
    });

    const [errorObject, setError] = useState<UpdatePasswordErrorObject>({
        // Email: null,
        // Password: null,
        // Captcha: null,
        ConfirmPassword: null,
        NewPassword: null
    });
    
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token") ?? "";
        setLoginData((prevState) => ({
            ...prevState,
            Token: token
        }));
    }, [ setLoginData]);

    const navigator = useNavigate();

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName: string
    ) => {
        let tempInputData: any = { ...loginData };
        tempInputData[fieldName] = e.target.value;
        setLoginData(tempInputData);
        
    };

    const handleFormChange = (
        e: React.MouseEvent<HTMLButtonElement>,
        value: string
    ) => {

        e.preventDefault();
        handleActiveForm(value);
        navigator("/login",{replace:true});
    };

    const handleUpdatePassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const updatedErrors = updatePasswordValidator(loginData);

        setError(updatedErrors);
        if(Object.values(updatedErrors).every((v) => v === false)){
            const response = await updatePasswordService(loginData);
            if (response){
                handleFormChange(e, "Login");
                navigator("/login",{replace:true});
            }
        }
    };

    return (
        <div className="login-form">
            <div className="form-header-container">
                <h1 className="form-header">Update Password</h1>
                <p className="form-description">
                    Experience the World's first AI OS
                </p>
            </div>

            <div className="form-fields">
                {/* <FormInput
                    
                    placeHolder="Email"
                    changeHandler={handleInputChange}
                    type="text"
                    required={true}
                /> */}
                {/* <div className="captcha-field-container"></div>
                <div className="captcha-label">
                    <label>Captcha</label>
                    <span className="required-mark">*</span>
                </div>
                <div className="captcha-container">
                    <div className="captcha-image-container">
                        <p className="captcha-image">Captcha image</p>
                        <FontAwesomeIcon icon={faRefresh} fontSize="25px" />
                    </div>
                    <Input
                        className="captcha-text"
                        placeholder="Enter Captcha Here"
                        onChange={(e) => handleInputChange(e, "Captcha")}
                        required={true}
                        type="text"
                    />
                </div> */}

                
                    <div>
                        <FormInput
                            placeHolder="Enter Password"
                            changeHandler={handleInputChange}
                            type="password"
                            required={true}
                            fieldName="NewPassword"
                            value={loginData.NewPassword ?? ""}
                            error={errorObject.NewPassword}
                            errorMessage="Password must be at least 8 characters long"
                            labelOnTop
                        />
                    </div>
                    <div>
                        <FormInput
                            placeHolder="Confirm Password"
                            changeHandler={handleInputChange}
                            type="password"
                            required={true}
                            fieldName="ConfirmPassword"
                            value={loginData.ConfirmPassword ?? ""}
                            error={errorObject.ConfirmPassword}
                            errorMessage="Password must be same as the one entered before"
                            labelOnTop
                        />
                    </div>

            </div>
            <div className="form-footer">
                <Button
                    className="login-button"
                    onClick={handleUpdatePassword}
                    type="primary"
                >
                    UPDATE
                </Button>
                <div className="footer-text">
                    <p>
                        Already have an account?{" "}
                        <Button
                            onClick={(e) => {
                                handleFormChange(e, "Login");
                            }}
                            className="form-link"
                            type="primary"
                        >
                            Login
                        </Button>
                    </p>
                </div>
            </div>
        </div>
    );
};
export default UpdatePassword;
