
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import  Button  from "../../components/common/button";
import uuid from "react-uuid"
import { getAppStore } from "../../services/appStore";
import {  getOrderByID, placeOrder, updateOrder } from "../../services/orders";
import { LocationProps } from "../../typings/common";
import "./styles.css"
import { formValidator } from "../../utils/formValidator";
import { toast } from "react-toastify";
import { orderCreationDate } from "../../utils/formatDate";
import OrgInformation from "../../components/Orders/CreateOrder/OrgInformation";
import OrderDetails from "../../components/Orders/CreateOrder/OrderDetails";
import AppInformation from "../../components/Orders/CreateOrder/AppInformation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import OrderSummary from "../../components/Orders/CreateOrder/OrderSummary";
	interface OrderState {
		"customer_name": string,
		"order_id": string,
		"deal_id": string,
		"app_data" : 
				{
					"app_id": number,
					"awi_name": string,
					"app_count": number,
					"liscence_start_date":string,
					"liscence_end_date":string,
					"liscence_id":string,
					"tenure":string,
					"grace_period":string
				}[],
		"org_id": string,
		"organisation_name":string,
		"org_name"?:string,
		"partner_name": string,
		"project_title": string,
		"invoice_number": string,
		"invoice_address": string,
		"gst_number": string,
		"quotation_number": string,
		"poc_name": string,
		"poc_email": string,
		"poc_phone": string,
		"liscence_type": string,
		"order_number": string,
	}


function CreateOrder() {
	const [state,setState] = useState<OrderState>({
		organisation_name:"",
		customer_name:"",
		order_id:"",
		deal_id: "",
		app_data:[], 
		
		org_id: "",
		partner_name:"",
		
		project_title:"",
		invoice_number:"",
		invoice_address:"",
		gst_number:"",
		quotation_number:"",
		poc_name:"",
		poc_email:"",
		poc_phone:"",
		liscence_type:"",
		order_number:""
	})

	const [isEdit,setEdit] = useState(false)
	const [app_total,setAppTotal] = useState({
				"app_id": 1,
				"awi_name": "Awiros Platform",
				"app_count": 0,
				"liscence_start_date": orderCreationDate(),
				"liscence_end_date":"",
				"liscence_id":uuid(),
				"liscence_type":"Perpetual",
				"tenure":"",
				"grace_period":""
			})
	
	const [multipartStage,setMultipartStage] = useState(1)
	const [completedStage,setCompletedStage] = useState(0)
	let location = useLocation() as unknown as LocationProps;
	const navigator = useNavigate() 
	const [appStack,setAppStack] = useState<any>()
	const [errorObject,setErrorObject] = useState<any>()
	const [clipboard,setClipboard] = useState<any>()
	const {id} = useParams()

	useEffect(()=>{
		getAllApps()
		setInitialData()
	},[])
	
	const setInitialData = async ()=>{

		if(id){
			let order_response = await getOrderByID({order_id:id})
			let order = {...order_response}
			if(order_response.app_data[order_response.app_data.length -1].awi_name === "Awiros Platform"){
			let temp_total_data = order_response.app_data.pop()
			setAppTotal(temp_total_data)
			}
			order.invoice_address = order.site_location
			setState(order)
			setEdit(true)
		}
		else{
		let tempOrder = {...state}
		tempOrder.org_id = location?.state?.org_id
		tempOrder.organisation_name = location?.state?.org_name
		tempOrder.order_id = uuid() 
		setState(tempOrder)
		} 
	}

	const getAllApps = async () =>{
		let tempAppStack = await getAppStore() 
		setAppStack(tempAppStack);
	}
	
	const extractAppName = (selectedApps?:any) => {

		selectedApps = selectedApps || appStack
		let result = selectedApps?.map((app:any)=>app.awi_name)
		return result
	}
	
	const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>,fieldName:string)=>{
		let tempState:any = {...state}
		tempState[fieldName] = e.target.value
		if(fieldName === "poc_phone" ){
			if(e.target.value.length > 10){
				return
			}
			tempState[fieldName] = e.target.value.replace(/\D/g, "");
		}
		setState(tempState)
	}

	const handleAppCountChange = (e:React.ChangeEvent<HTMLInputElement>,idx:number)=>{
							
		let tempState:any = {...state}
		tempState["app_data"][idx]["app_count"] = e.target.value
		setState(tempState)
	}

	const getObjectWithMatchingKey = (dataArray:any,matchKey:string,matchValue:string) => {
		const foundItem = dataArray.find((obj:any) => obj[matchKey]===matchValue)
		
		return foundItem
	}

	const fetchDropdownObjects = (fieldName:string,data:string[])=>{
		let mappedObject
		switch (fieldName) {
			case "app_data" :
				let appData = data.map((item)=>{
					return getObjectWithMatchingKey(appStack,"awi_name",item)
				})
				mappedObject = appData.map((app:any)=>{
					let foundItem =  state.app_data.find((obj:any) => obj["awi_name"]===app.awi_name)
					if(foundItem){
						return foundItem
					}
					return {
						app_id:app.id,
						awi_name:app.awi_name,
						awi_app_name:app.awi_app_name,
						liscence_id:uuid(),
						app_count: null,
						liscence_start_date: orderCreationDate(),
						liscence_end_date:"",
						tenure:"",
						grace_period:""
					}
				})
				break;
			default:
				mappedObject = data[0]
				break;
		}
		return mappedObject
	}

	const handleDropdownChange = (e: any, data: string[]) =>{
		const fieldName = e.target.id
		
		let tempInputData: any = { ...state };
		let filteredDropdownData = fetchDropdownObjects(fieldName,data)
		tempInputData[fieldName] = filteredDropdownData;
		setState(tempInputData);
		
	}
	const handleAppItemChange = (e:any,data:string,fieldName:string,idx:number) =>{
		let tempInputData: any = { ...state };
		tempInputData.app_data[idx][fieldName] = data
		if(fieldName==="app_count"){
			let tempTotalData = {...app_total}
			tempTotalData.app_count = state.app_data.reduce((acc,val)=>{
				return acc+(+val.app_count||0)
			},0)
			setAppTotal(tempTotalData)
		}


		if(fieldName === "tenure"){
			tempInputData.app_data[idx]["liscence_end_date"] = orderCreationDate(+data*365) 
		}
		if(fieldName === "grace_period"){
			let days = +data
			tempInputData.app_data[idx]["liscence_start_date"] = orderCreationDate(days)
			tempInputData.app_data[idx]["liscence_end_date"] = orderCreationDate(+tempInputData.app_data[idx]["tenure"]*365 + days)
		}
		setState(tempInputData) 
	}

	const handleTotalsObjectChange = (e:any,fieldName:string)=>{
		let tempTotalData:any = {...app_total}
		tempTotalData[fieldName] = e.target.value
		let data =  e.target.value
		if(fieldName === "tenure"){
			tempTotalData["liscence_end_date"] = orderCreationDate(+data*365) 
		}
		if(fieldName === "grace_period"){
			let days = +data
			tempTotalData["liscence_start_date"] = orderCreationDate(days)
			tempTotalData["liscence_end_date"] = orderCreationDate(+tempTotalData["tenure"]*365 + days)
		}
		setAppTotal(tempTotalData)
	}

	const copyAboveConfigs = (idx:number)=>{
		let tempClipboard:any = {};
		let tempInputData: any = { ...state };
		tempClipboard["app_count"] = tempInputData.app_data[idx]["app_count"]
		tempClipboard["liscence_start_date"] = tempInputData.app_data[idx]["liscence_start_date"]
		tempClipboard["liscence_end_date"] = tempInputData.app_data[idx]["liscence_end_date"]
		tempClipboard["liscence_type"] = tempInputData.app_data[idx]["liscence_type"]
		tempClipboard["tenure"] = tempInputData.app_data[idx]["tenure"]
		tempClipboard["grace_period"] = tempInputData.app_data[idx]["grace_period"]
		setClipboard(tempClipboard)
	}

	const pasteConfigs = (idx:number) => {
		if(!clipboard)
			return
		let tempInputData: any = { ...state };
		 tempInputData.app_data[idx]["app_count"] =   clipboard["app_count"]
		 tempInputData.app_data[idx]["liscence_start_date"] =   clipboard["liscence_start_date"]
		 tempInputData.app_data[idx]["liscence_end_date"] =   clipboard["liscence_end_date"]
		 tempInputData.app_data[idx]["liscence_type"] =   clipboard["liscence_type"]
		 tempInputData.app_data[idx]["tenure"] =   clipboard["tenure"]
		 tempInputData.app_data[idx]["grace_period"] =   clipboard["grace_period"]
		setState(tempInputData) 
		let tempTotalData = {...app_total}
			tempTotalData.app_count = state.app_data.reduce((acc,val)=>{
				return acc+(+val.app_count||0)
			},0)
		setAppTotal(tempTotalData)
	}

	const handleSubmit = async () => {
		let objectToSend = {...state}
		objectToSend.app_data.push(app_total)
		const errorObject = formValidator(state)
		if(Object.values(errorObject).every((v) => v === false)){
			const result = !isEdit ? await placeOrder(state): await updateOrder(state)
			if(result){
				setMultipartStage(multipartStage+1)
			}
		}
		else{
			toast.error("Kindly Fill all the fields")
			objectToSend.app_data.pop()
		}
		// 
		}

	const handleMultipartStageChange = (stage:number)=>{
		if(stage-1<=completedStage && multipartStage<4){
			setMultipartStage(stage)
		}
	}

	const handleCompletedStages = (stage:number)=>{
		setCompletedStage(stage)
	}

	const handleErrorObjectChange = (e:any)=>{
		setErrorObject(e)
	}

	const handleNextorCreate = ()=>{
		if(multipartStage < 3){
			setMultipartStage(multipartStage+1)
		}
		else{
			handleSubmit()
		}
	}	

	const pasteAllConfigs = ()=>{
		if(!clipboard)
			return
		let tempInputData: any = { ...state };
		tempInputData.app_data.map((val:any,idx:number)=>{
		 val["app_count"] =   clipboard["app_count"]
		 val["liscence_start_date"] =   clipboard["liscence_start_date"]
		 val["liscence_end_date"] =   clipboard["liscence_end_date"]
		 val["liscence_type"] =   clipboard["liscence_type"]
		 val["tenure"] =   clipboard["tenure"]
		 val["grace_period"] =   clipboard["grace_period"]
		 return null
		})
		// setState(tempInputData) 
		let tempTotalData = {...app_total}
			tempTotalData.app_count = state.app_data.reduce((acc,val)=>{
				return acc+(+val.app_count||0)
			},0)
			setAppTotal(tempTotalData)
	}

return (
	<div className="create-order-form-container">
		<div className="create-order-header">
			{multipartStage <4 &&
				<>
				<h1 className="create-order-heading">Order Creation</h1>
				<p>Fill the below information in order to create a new order.</p>
				</>
			}
		</div>
		<div className="create-order-body">
			<div className="multipart-navigation-container">
				<div className="multipart-navigation">
					<div 
						className={ 
							`multipart-navigation-item 
							${multipartStage===1?'multipart-item-active':''}
							${multipartStage>1?'multipart-item-completed':''}`
						} 
						onClick={()=>handleMultipartStageChange(1)}>
						<div className="multipart-navigation-item-header">
							<h3>Organisation Information</h3>
							<p>Fill in the organisation details</p>
						</div>
						<div className="multipart-navigation-item-number">
								<span>{multipartStage===1?1:<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</span>
							</div>
						
					</div>
					<div className={ 
							`multipart-navigation-item 
							${multipartStage===2?'multipart-item-active':''}
							${multipartStage>2?'multipart-item-completed':''}`
						} onClick={()=>handleMultipartStageChange(2)}>
						<div className="multipart-navigation-item-header">
							<h3>Order Details</h3>
							<p>Fill in the order information</p>
						</div>
						<div className="multipart-navigation-item-number">
								<span>{multipartStage<3?2:<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</span>
							
							</div>
						
					</div>
					<div className={ 
							`multipart-navigation-item 
							${multipartStage===3?'multipart-item-active':''}
							${multipartStage>3?'multipart-item-completed':''}`
						} onClick={()=>handleMultipartStageChange(3)}>
						<div className="multipart-navigation-item-header">
							<h3>App Information</h3>
							<p>Add the details of apps for your order</p>
						</div>
						<div className="multipart-navigation-item-number">
							<span>{multipartStage<4?3:<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}</span>
						</div>
						
					</div>
				</div>
				<div className="multipart-navigation-border"></div>	
			</div>
			<div className="multipart-forms">
				{multipartStage===1 && 
				<OrgInformation 
					state = {state}
					stage = {1}
					completeStage = {handleCompletedStages}
					errorObject = {errorObject}
					handleErrorObjectChange = {handleErrorObjectChange}	
					handleInputChange = {handleInputChange}
				/>}
				{multipartStage===2 && 
				<OrderDetails
					state = {state}
					stage = {2}
					completeStage = {handleCompletedStages}
					errorObject = {errorObject}
					handleErrorObjectChange = {handleErrorObjectChange}
					handleInputChange = {handleInputChange}
				></OrderDetails>}
				{multipartStage===3 && 
				<AppInformation
						state={state}
						stage={3}
						completeStage={handleCompletedStages}
						errorObject={errorObject}
						handleErrorObjectChange={handleErrorObjectChange}
						extractAppNames={extractAppName}
						isEdit={isEdit}
						appTotal={app_total}
						handleAppCountChange={handleAppCountChange}
						handleDropdownChange={handleDropdownChange} 
						handleTotalsObjectChange={handleTotalsObjectChange} 
						copyAboveConfigs={copyAboveConfigs}
						pasteConfigs = {pasteConfigs} 
						pasteAllConfigs = {pasteAllConfigs}
						handleAppItemChange={handleAppItemChange}/>}
						
				{multipartStage===4 && <OrderSummary></OrderSummary>}
			</div>
		</div>
		{multipartStage< 4 && 
			<div className="create-order-footer">
				<div className="create-order-footer-actions">
					{multipartStage>1 && <Button onClick={(e)=>handleMultipartStageChange(multipartStage-1)}>Back</Button>}
					<Button 
						type="primary" 
						onClick={handleNextorCreate} 
						disabled = {completedStage<multipartStage}
					>
						{multipartStage<3?"Next":"Create"}
					</Button>
				</div>
			</div>
		}
		
	</div>
)
}
export default CreateOrder