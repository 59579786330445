import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AppConfigInfo from "../../components/appRegistration/AppConfig";
import AppSummary from "../../components/appRegistration/AppSummary";
import FormNavigation from "../../components/appRegistration/FormNavigation";
import FovInfo from "../../components/appRegistration/FovInfo";
import GeneralInfo from "../../components/appRegistration/GeneralInfo";
import ModelInformation from "../../components/appRegistration/ModelInformation";
import Button from "../../components/common/button";
import { listOfTargetIndustries } from "../../exampleResponses/listOfTargetIndustries";
import {
  AppConfig,
  DemoVideo,
  IAppRegistration,
  IAppRegistrationError,
  IAppRegistrationErrorExample,
  IAppRegistrationExample,
} from "../../interfaces/appRegistration";
import {
  editApp,
  getAppVersioningDetails,
  registerApp,
  registerAppVersion,
  uploadFile,
} from "../../services/appRegistration";
import { RootState } from "../../store";
import { appRegistrationValidator } from "../../utils/formValidator";
import "./styles.css";

type loadingKeys =
  | "awi_app_logo"
  | "awi_app_developer_image"
  | "awi_sample_fov_image"
  | "ppm_image"
  | "awi_flowchart"
  | "awi_sample_images"
  | "awi_demo_video"
  | "demoVideos";

type currentProcessOptions = "registerApp" | "editApp" | "registerAppVersion";

function AppRegistration() {
  const [state, setState] = useState<IAppRegistration>(IAppRegistrationExample);
  const [errorObject, setError] = useState<IAppRegistrationError>(
    IAppRegistrationErrorExample
  );
  const appRegistrationToolTips = {
    appInfo: "Fill default app configuration of your app",
    fovInfo:
      "FOV determines the visible area within your app, influencing user interactions.",
    awi_ppm:
      "Pixels per Meter (PPM) defines image resolution, indicating the number of pixels within one meter.",
    mountHeight:
      "The ideal mounting height for your camera influences the app's performance and field of view.",
    fovCompatible: "FOV represents the visible area in your app.",
    awi_gpu_required:
      "Check this box if your app relies on GPU (Graphics Processing Unit) for enhanced performance or specific features; otherwise, leave it unchecked.",
    awi_nvdia_deepstream:
      "If your app utilizes video analytics, consider integrating Nvidia DeepStream for enhanced performance and capabilities.",
    awi_nvidia_tensorrt:
      "If your app involves deep learning inference, consider leveraging Nvidia TensorRT to accelerate model execution.",
    awi_intel_openvino:
      "If your app relies on Intel architecture, OpenVINO can help you achieve efficient deep learning inference.",
    awi_varifocal_camera: "Optimize for adjustable focal length cameras.",
    awi_handheld_compatible: "Enhance usability with handheld cameras.",
    awi_ptz_compatible:
      "Provide intuitive control for PTZ (Pan-Tilt-Zoom) cameras.",
  };

  const [appVersionDetails, setAppVersionDetails] = useState<any>(null);

  const [currentProcess, setCurrentProcess] =
    useState<currentProcessOptions>("registerApp");
  const [initialButtonState, setInitialButtonState] = useState(false);
  const [multipartStage, setMultipartStage] = useState(1);
  const [completedStage, setCompletedStage] = useState(0);
  const [loading, setLoading] = useState({
    awi_app_logo: false,
    awi_app_developer_image: false,
    awi_sample_fov_image: false,
    ppm_image: false,
    awi_flowchart: false,
    awi_sample_images: false,
    awi_demo_video: false,
    demoVideos: false,
  });
  const fieldOfViews = [
    {
      id: 1,
      awi_name: "Narrow",
    },
    {
      id: 2,
      awi_name: "Wide",
    },
    {
      id: 3,
      awi_name: "Medium",
    },
  ];
  const locationState = useLocation().state;

  const devImageRef = useRef(null);
  const appLogoRef = useRef(null);
  const fovImageRef = useRef(null);
  const ppmRef = useRef(null);
  const ssoFileRef = useRef(null);
  const flowchartRef = useRef(null);
  const sampleImagesRef = useRef(null);
  const outputVideoRef = useRef(null);

  const [formModels, setFormModels] = useState([0, 1]);

  const userObject = useSelector((state: RootState) => state.userDetails);
  const parsedToken = useSelector((state: RootState) => state.isLoggedIn).role
    .id;

  const setAppVersioningDetails = async () => {
    const tempAppVersionDetails = await getAppVersioningDetails({
      appID: locationState?.id,
    });

    setAppVersionDetails(tempAppVersionDetails.data || []);
  };

  const maxStep = 4;

  useEffect(() => {
    let tempInputData: any;

    if (locationState?.editApp) {
      setCurrentProcess("editApp");
      tempInputData = {
        ...structuredClone(locationState),
        awi_aggreement_accepted: true,
        awi_major_version: locationState?.awi_version?.major || 1,
        awi_minor_version: locationState?.awi_version?.minor || 0,
        awi_patch_version: locationState?.awi_version?.patch || 0,
        awi_release_note: locationState?.awi_version?.release_note,
      };

      if (
        tempInputData.awi_supported_acceleration.includes("NVIDIA DeepStream")
      ) {
        tempInputData.awi_nvdia_deepstream = true;
      }
      if (
        tempInputData.awi_supported_acceleration.includes("NVIDIA TensorRT")
      ) {
        tempInputData.awi_nvidia_tensorrt = true;
      }
      if (tempInputData.awi_supported_acceleration.includes("Intel OpenVINO")) {
        tempInputData.awi_intel_openvino = true;
      }
      if (tempInputData.awi_varifocal_camera === "yes") {
        tempInputData.awi_varifocal_camera = true;
      } else {
        tempInputData.awi_varifocal_camera = false;
      }
    } else if (locationState?.registerAppVersion) {
      setCurrentProcess("registerAppVersion");

      tempInputData = {
        ...structuredClone(IAppRegistrationExample),
        awi_app_version: "1.0.0",
        awi_major_version: 1,
        awi_minor_version: 0,
        awi_patch_version: 0,
        awi_release_note: "Release note to be added",
        awi_name: locationState?.awi_name,
        app_id: locationState?.id,
      };

      setAppVersioningDetails();

      tempInputData["awi_app_developer"] = userObject.name;
      tempInputData["awi_app_developer_id"] = parsedToken;
      tempInputData["awi_developer_image"] = userObject?.profilePicture ?? "";
      tempInputData["awi_org_id"] = userObject.org_id;
    } else {
      setError({ ...IAppRegistrationErrorExample });
      tempInputData = { ...structuredClone(IAppRegistrationExample) };

      tempInputData["awi_app_developer"] = userObject.name;
      tempInputData["awi_app_developer_id"] = parsedToken;
      tempInputData["awi_developer_image"] = userObject?.profilePicture ?? "";
      tempInputData["awi_org_id"] = userObject.org_id;
      tempInputData["awi_major_version"] = 1;
      tempInputData["awi_minor_version"] = 0;
      tempInputData["awi_patch_version"] = 0;
    }
    setState(tempInputData);
  }, [parsedToken, userObject.name, locationState]);

  const getAwiName = (name: string) => {
    let temp_name = "awi_";
    const modified_name = name.toLowerCase().trim().split(" ");
    temp_name += modified_name.join("_");
    return temp_name;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    let tempInputData: any = { ...state };
    let inputValue = e.target.value;

    if (fieldName === "awi_ppm_value") {
      tempInputData.awi_ppm.ppm_value = inputValue;
    } else {
      //if no exceptional handling needed
      inputValue = inputValue.trimStart();
      tempInputData[fieldName] = inputValue;
    }

    if (fieldName === "awi_app_logic") {
      tempInputData.awi_app_logic = e.target.value;
    }
    if (fieldName === "ppm_value")
      tempInputData.awi_ppm.ppm_value = +e.target.value;

    const updatedErrors = appRegistrationValidator(tempInputData);

    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleDeleteModel = (index: number) => {
    const tempFormModels = [...formModels];
    tempFormModels.splice(index, 1);
    setFormModels(tempFormModels);
    setState({
      ...state,
      awi_model_information: state.awi_model_information.filter(
        (model, i) => i !== index
      ),
    });
  };

  const handleAddModel = () => {
    const tempFormModels = [...formModels];
    tempFormModels.push(formModels.length);
    setFormModels(tempFormModels);
    setState({
      ...state,
      awi_model_information: [
        ...state.awi_model_information,
        {
          name: "",
          type: "",
          version: "",
          architecture: "",
        },
      ],
    });
  };

  const handleModelInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    let tempInputData: any = { ...state };
    const model_metadata = fieldName.split("_");

    const model_metadata_index = +model_metadata[0];
    const model_metadata_key = model_metadata[1];

    tempInputData.awi_model_information[model_metadata_index][
      model_metadata_key
    ] = e.target.value;
    const updatedErrors = appRegistrationValidator(tempInputData);
    setState(tempInputData);
    setError(updatedErrors);
  };

  const handleCheckboxSelect = (
    e: FormEvent<HTMLInputElement>,
    fieldName: String | boolean
  ) => {
    let tempInputData: any = { ...state };

    if (fieldName === "awi_gpu_required" && tempInputData.awi_gpu_required) {
      tempInputData.awi_gpu_required = !tempInputData.awi_gpu_required;
      tempInputData.awi_nvdia_deepstream = false;
      tempInputData.awi_nvidia_tensorrt = false;
      tempInputData.awi_intel_openvino = false;
    } else {
      tempInputData[fieldName as any] =
        !tempInputData[fieldName as unknown as any];
    }

    // tempInputData[fieldName as unknown as any] = data.value;
    // if (fieldName !== "awi_varifocal_camera") {
    //   if (data.value === "Yes")
    //     tempInputData[fieldName as unknown as any] = data.value === "Yes";
    //   if (data.value === "No")
    //     tempInputData[fieldName as unknown as any] = false;
    // }
    const updatedErrors = appRegistrationValidator(tempInputData);
    setState(tempInputData);
    setError(updatedErrors);
  };

  const handleDropdownChange = (e: any, data: string[]) => {
    const fieldName = e.target.id;
    let tempInputData: any = { ...state };
    tempInputData[fieldName] = data;
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const getObjectWithMatchingKey = (
    dataArray: any,
    matchKey: string,
    matchValue: string
  ) => {
    const foundItem = dataArray.find(
      (obj: any) => obj[matchKey] === matchValue
    );

    return foundItem;
  };

  const getIndustries = (listOfIndustries: any) => {
    return listOfIndustries.data.map((industry: any) => industry.awi_name);
  };

  const getUpdateOptions = () => {
    return ["major", "minor", "patch"];
  };

  const getVersionSelectionOptions = () => {
    return appVersionDetails.map((version: any) => {
      return {
        label: `Major v ${version.major_version}`,
        id: version.major_version,
        value: version.major_version,
        children: version.minor_versions.map((minorVersion: any) => {
          return {
            label: `Minor v ${minorVersion.minor_version}`,
            id: minorVersion.minor_version,
            value: minorVersion.minor_version,
          };
        }),
      };
    });
  };

  const handleTypeOfUpdateDropdownChange = (e: any, data: string[]) => {
    const tempInputData = { ...state };
    if (data[0] === tempInputData.awi_version_upgrade_type) {
      return;
    }

    if ((appVersionDetails || []).length === 0) {
      toast.error("App version details not available");
      return;
    }

    const selectedUpdateType = data[0];

    if (selectedUpdateType === "major") {
      tempInputData.awi_major_version = appVersionDetails[0].major_version + 1;
      tempInputData.awi_minor_version = 0;
      tempInputData.awi_patch_version = 0;
    } else if (
      selectedUpdateType === "minor" ||
      selectedUpdateType === "patch"
    ) {
      tempInputData.awi_major_version = null;
      tempInputData.awi_minor_version = null;
      tempInputData.awi_patch_version = null;
    }
    tempInputData.awi_version_upgrade_type = selectedUpdateType;
    setState(tempInputData);

    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleIndustryDropdownChange = (event: any, data: string[]) => {
    const industryIndex = +event.target.id;

    const tempInputData = { ...state };
    tempInputData.industry_uses[industryIndex]["awi_name"] = data[0];

    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleVersionSelection = (data: any) => {
    const tempInputData = { ...state };
    let selectedMajorVersion: any;
    let selectedMinorVersion: any;

    if (state.awi_version_upgrade_type === "minor") {
      selectedMajorVersion = data[0].value;
      tempInputData.awi_major_version = selectedMajorVersion;

      tempInputData.awi_minor_version =
        appVersionDetails.find(
          (version: any) => version.major_version === selectedMajorVersion
        ).minor_versions[0].minor_version + 1;
      tempInputData.awi_patch_version = 0;
    } else if (state.awi_version_upgrade_type === "patch") {
      selectedMajorVersion = data[0].value;
      selectedMinorVersion = data[1].value;
      tempInputData.awi_major_version = selectedMajorVersion;
      tempInputData.awi_minor_version = selectedMinorVersion;

      tempInputData.awi_patch_version =
        appVersionDetails
          .find(
            (version: any) => version.major_version === selectedMajorVersion
          )
          .minor_versions.find(
            (minorVersion: any) =>
              minorVersion.minor_version === selectedMinorVersion
          ).patch_versions[0].patch_version + 1;
    }
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleIndustriesDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const industryIndex = +fieldName;

    const tempInputData = { ...state };
    tempInputData.industry_uses[industryIndex]["awi_description"] =
      e.target.value;

    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleAddIndustry = () => {
    const tempInputData = { ...state };
    tempInputData.industry_uses.push({
      awi_name: "",
      awi_description: "",
    });

    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleDeleteIndustry = (index: number) => {
    const tempInputData = { ...state };
    tempInputData.industry_uses.splice(index, 1);
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleFOVDropdownChange = (e: any, data: string[]) => {
    const fieldName = e.target.id;
    let tempInputData: any = { ...state };

    tempInputData[fieldName] = fieldOfViews
      .filter((item) => item.awi_name === data[0])
      .map((item) => item.awi_name);

    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tempInputData: any = { ...state };
    tempInputData["awi_cer"] = e.target.value;
    setState(tempInputData);
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
  };

  const handleRefClick = (ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.click();
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSelected = e.target.files!;

    let file_upload_response = [];
    let id = e.target.id;

    let tempLoadingState = { ...loading };
    tempLoadingState[id as unknown as loadingKeys] = true;
    setLoading(tempLoadingState);
    for (let i = 0; i < fileSelected.length; i++) {
      const formData = new FormData();
      formData.append("file", fileSelected[i], fileSelected[i].name);
      file_upload_response.push(await uploadFile(formData));
    }
    let tempInputData: any = { ...state };

    if (
      id === "awi_sample_fov_image" ||
      id === "awi_sample_images" ||
      id === "awi_demo_video" ||
      id === "awi_so_file_path"
    ) {
      if (tempInputData[id]) {
        tempInputData[id] = [...tempInputData[id], ...file_upload_response];
      } else {
        tempInputData[id] = file_upload_response;
      }
    } else if (id === "demoVideos") {
      let demoVideos = [];
      for (let i = 0; i < file_upload_response.length; i++) {
        demoVideos.push({ video: file_upload_response[i], config: null });
      }
      if (tempInputData[id]) {
        tempInputData[id] = [...tempInputData[id], ...demoVideos];
      } else {
        tempInputData[id] = [...demoVideos];
      }
    } else {
      if (id === "ppm_image") {
        tempInputData.awi_ppm.ppm_image = file_upload_response[0];
      } else tempInputData[id] = file_upload_response[0];
    }
    const updatedErrors = appRegistrationValidator(tempInputData);
    tempLoadingState[id as unknown as loadingKeys] = false;
    setLoading(tempLoadingState);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const removeItemFromArray = (idx: number, fieldName: string) => {
    let tempInputData: any = { ...state };
    tempInputData[fieldName].splice(idx, 1);
    // const updatedErrors = loginFormValidator(tempInputData);
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleDeleteFovImage = (idx: number) => {
    const tempInputData: any = { ...state };
    tempInputData.awi_sample_fov_image.splice(idx, 1);
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleAppConfig = (appConfig: AppConfig) => {
    const tempInputData: IAppRegistration = { ...state };
    tempInputData.config = appConfig;
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleAddDemoVideoConfig = (selectedDemoVideo: DemoVideo) => {
    const tempInputData: IAppRegistration = { ...state };

    // Find index of existing demo video
    const index = tempInputData.demoVideos.findIndex((demoVideo) => {
      return demoVideo.video === selectedDemoVideo.video;
    });

    // Update or add the demo video configuration
    if (index >= 0) {
      tempInputData.demoVideos[index].config = selectedDemoVideo.config;
    } else {
      tempInputData.demoVideos.push(selectedDemoVideo); // Add new video config
    }

    // Validate and update state
    const updatedErrors = appRegistrationValidator(tempInputData);
    setError(updatedErrors);
    setState(tempInputData);
  };

  const handleDelDemoVideoConfig = (idx: number) => {
    const tempInputData: IAppRegistration = { ...state };

    // Remove demo video at specified index
    tempInputData.demoVideos.splice(idx, 1);

    // Validate and update state
  };

  const submitregistrationForm = async () => {
    let submitResponse: any;
    setInitialButtonState(true);
    const awi_supported_acceleration = [];

    if (state.awi_nvdia_deepstream) {
      awi_supported_acceleration.push("NVIDIA DeepStream");
    }

    if (state.awi_nvidia_tensorrt) {
      awi_supported_acceleration.push("NVIDIA TensorRT");
    }

    if (state.awi_intel_openvino) {
      awi_supported_acceleration.push("Intel OpenVINO");
    }

    const formDetails = {
      ...state,
      awi_supported_acceleration,
      awi_varifocal_camera: state.awi_varifocal_camera ? "yes" : "no",
      awi_name: state.awi_name,
      awi_app_name: getAwiName(state.awi_name),
      awi_so_file_name: `${getAwiName(state.awi_name)}.so`,
      awi_target_industries: state.industry_uses.map((industry: any) => {
        let industry_object = {
          id: 0,
          awi_name: "",
        };
        let foundIndustry = getObjectWithMatchingKey(
          listOfTargetIndustries.data,
          "awi_name",
          industry.awi_name
        );
        industry_object = foundIndustry;
        return industry_object;
      }),
      awi_version: {
        major: state.awi_major_version,
        minor: state.awi_minor_version,
        patch: state.awi_patch_version,
        release_note: state.awi_release_note,
      },
    };

    if (currentProcess === "editApp") {
      submitResponse = await editApp(formDetails);
      setInitialButtonState(false);
    } else if (currentProcess === "registerAppVersion") {
      submitResponse = await registerAppVersion({
        ...formDetails,
        awi_major_version: state.awi_major_version,
        awi_minor_version: state.awi_minor_version,
        awi_patch_version: state.awi_patch_version,
        release_note: state.awi_release_note,
      });
      setInitialButtonState(false);
    } else {
      submitResponse = await registerApp(formDetails);
      setInitialButtonState(false);
    }
    if (submitResponse) {
      setState(IAppRegistrationExample);
      setMultipartStage(multipartStage + 1);
    } else {
      setMultipartStage(1);
    }
  };

  const handleMultipartStageChange = (stage: number) => {
    if (
      multipartStage < 4 &&
      (["editApp"].includes(currentProcess) || stage - 1 <= completedStage)
    ) {
      setMultipartStage(stage);
    }
  };

  const handleCompletedStages = (stage: number) => {
    setCompletedStage(stage);
  };

  const handleNextorCreate = () => {
    if (multipartStage < maxStep) {
      setMultipartStage(multipartStage + 1);
    } else {
      submitregistrationForm();
    }
  };

  const handleDemoVideoUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<string> => {
    const fileSelected = e.target.files!;

    // Initialize loading state
    const id = e.target.id;
    let tempLoadingState = { ...loading };
    tempLoadingState[id as unknown as loadingKeys] = true;
    setLoading(tempLoadingState);

    // Upload the first selected file only
    const formData = new FormData();
    formData.append("file", fileSelected[0], fileSelected[0].name);
    const response = await uploadFile(formData); // Assuming uploadFile returns the video URL as a string

    // Update state with the uploaded video URL
    let tempInputData: any = { ...state };

    // Handle the demo videos state update
    if (id === "demoVideos") {
      // Create a new demo video entry with the uploaded URL
      const demoVideo = { video: response, config: null };

      if (tempInputData[id]) {
        tempInputData[id] = [...tempInputData[id], demoVideo];
      } else {
        tempInputData[id] = [demoVideo]; // Initialize if undefined
      }
    }

    // Validate the updated state
    const updatedErrors = appRegistrationValidator(tempInputData);

    // Reset loading state
    tempLoadingState[id as unknown as loadingKeys] = false;
    setLoading(tempLoadingState);

    // Update errors and state
    setError(updatedErrors);
    setState(tempInputData);

    // Return the uploaded video URL
    return response;
  };

  return (
    <>
      <div className='app-registration-container'>
        <div className='app-registration-header'>
          {multipartStage < 6 && (
            <>
              <h1>APP REGISTRATION</h1>
              <p>
                Give your Computer Vision App a global platform with Awiros!
              </p>
            </>
          )}
        </div>
        <div className='app-registration-body'>
          <FormNavigation
            handleMultipartStageChange={handleMultipartStageChange}
            multipartStage={multipartStage}
          ></FormNavigation>
          {multipartStage === 1 && (
            <GeneralInfo
              handleInputChange={handleInputChange}
              handleCheckboxSelect={handleCheckboxSelect}
              state={state}
              errorObject={errorObject}
              handleCompletedStages={handleCompletedStages}
              handleIndustryDropdownChange={handleIndustryDropdownChange}
              handleIndustriesDescriptionChange={
                handleIndustriesDescriptionChange
              }
              handleTypeOfUpdateDropdownChange={
                handleTypeOfUpdateDropdownChange
              }
              getVersionSelectionOptions={getVersionSelectionOptions}
              getUpdateOptions={getUpdateOptions}
              handleVersionSelection={handleVersionSelection}
              handleAddIndustry={handleAddIndustry}
              handleDeleteIndustry={handleDeleteIndustry}
              getIndustries={getIndustries}
              listOfTargetIndustries={listOfTargetIndustries}
              stage={1}
              handleRefClick={handleRefClick}
              devImageRef={devImageRef}
              appLogoRef={appLogoRef}
              ssoFileRef={ssoFileRef}
              handleFileUpload={handleFileUpload}
              loading={loading}
              currentProcess={currentProcess}
            />
          )}
          {/* {multipartStage === 1 && (
              <GeneralInformation
                handleInputChange={handleInputChange}
                state={state}
                errorObject={errorObject}
                handleCompletedStages={handleCompletedStages}
                handleIndustryDropdownChange={handleIndustryDropdownChange}
                getIndustries={getIndustries}
                listOfTargetIndustries={listOfTargetIndustries}
                stage={1}
                handleRefClick={handleRefClick}
                devImageRef={devImageRef}
                appLogoRef={appLogoRef}
                handleFileUpload={handleFileUpload}
                loading={loading}
              />
            )} */}
          {multipartStage === 2 && (
            <FovInfo
              state={state}
              errorObject={errorObject}
              handleCompletedStages={handleCompletedStages}
              stage={2}
              handleInputChange={handleInputChange}
              handleFileUpload={handleFileUpload}
              handleDropdownChange={handleDropdownChange}
              handleCheckboxSelect={handleCheckboxSelect}
              handleRefClick={handleRefClick}
              fieldOfViews={fieldOfViews}
              handleFOVDropdownChange={handleFOVDropdownChange}
              ppmRef={ppmRef}
              fovImageRef={fovImageRef}
              appLogoRef={appLogoRef}
              outputVideoRef={outputVideoRef}
              removeItemFromArray={removeItemFromArray}
              loading={loading}
              appRegistrationToolTips={appRegistrationToolTips}
              handleDeleteFovImage={handleDeleteFovImage}
            />
          )}
          {multipartStage === 3 && (
            <AppConfigInfo
              state={state}
              errorObject={errorObject}
              handleCompletedStages={handleCompletedStages}
              stage={3}
              handleInputChange={handleInputChange}
              handleFileUpload={handleDemoVideoUpload}
              handleRefClick={handleRefClick}
              fovImageRef={fovImageRef}
              removeItemFromArray={removeItemFromArray}
              loading={loading}
              handleAppConfig={handleAppConfig}
              handleAddDemoVideoConfig={handleAddDemoVideoConfig}
              handleDelDemoVideoConfig={handleDelDemoVideoConfig}
            />
          )}
          {multipartStage === 4 && (
            <ModelInformation
              state={state}
              errorObject={errorObject}
              handleCompletedStages={handleCompletedStages}
              stage={4}
              formModels={formModels}
              handleDeleteModel={handleDeleteModel}
              handleAddModel={handleAddModel}
              handleInputChange={handleInputChange}
              handleModelInformationChange={handleModelInformationChange}
            />
          )}

          {multipartStage === 5 && (
            <AppSummary processCompleted={currentProcess} />
          )}
        </div>
      </div>
      {multipartStage <= maxStep && (
        <div>
          <div className='multipart-registration-footer'>
            {multipartStage > 1 && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleMultipartStageChange(multipartStage - 1);
                }}
              >
                Back
              </Button>
            )}
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleNextorCreate();
              }}
              disabled={completedStage < multipartStage || initialButtonState}
              className={` ${
                completedStage < multipartStage
                  ? "registration-disabled-button"
                  : "registration-primary-button"
              }`}
            >
              {multipartStage < maxStep
                ? "Next"
                : currentProcess === "editApp"
                ? "Update"
                : currentProcess === "registerApp"
                ? "Register"
                : "Register Version"}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default AppRegistration;
