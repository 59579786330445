import { useEffect, useState } from 'react'
import Input  from '../../components/common/Input'
import Loader from '../../components/common/loader'
import OrganisationTable from '../../components/Organisations/OrganisationTable'
import { getOrgData } from '../../services/organisation'

import "./styles.css"

type organisationTableProps = {
    org_name:string,
    org_id:number,
    total_orders:number,
    total_users:number,
    apps:any[],
    apps_length: string, 
}[]

function Organisation() {
  
  const [orgTableData,setOrgTableData] = useState<organisationTableProps>([])
  const [orgName,setOrgName] = useState<string|null>("")
  const [loading,setLoading] = useState(false)
  useEffect(() => {
    
  fetchOrgTableData()
    
  },[])
  
  const handleOrgNameChange = (e:any)=>{
    setOrgName(e.target.value as unknown as string)
  }

  const fetchOrgTableData = async() =>{
    setLoading(true)
    const response = await getOrgData()
    if (response){
      setOrgTableData(response)
    }
    setLoading(false)
  }
  return (
    <div>
        <div className='organisation-table-container'>
          <div className='organisation-search-container'>
          <Input
          // className="organisation-search-input large"
            placeholder="Search Organisations"
            onChange={handleOrgNameChange}
            value={orgName!} 
            type={'text'}
          />
          </div>
          <div className='organisation-table-wrapper'>
          {!loading && <OrganisationTable orgTableData = {orgTableData} orgNameFilter = {orgName}></OrganisationTable>}
          {loading && <div className="loader-container">
                    <Loader height={200} width={200}/>
                    </div>
                    }
          </div>
        </div>
    </div>
  )
}

export default Organisation