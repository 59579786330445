// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table{
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0 1em;
    /* table-layout: fixed; */
    overflow: auto;

}



.table thead{
    /* border-bottom: 1px solid var(--primary-color) ; */
    border-radius: 8px;
    text-align: left;
    padding: 1.25rem 0px;
}

.table td {
    padding: 0.75rem;
    text-align: left;
    max-width: 200px;
    overflow: hidden;
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    word-break: break-all;
}
.table th {
    padding: 1.25rem 0.75rem;
    border-bottom: 1px solid var(--primary-color);
    /* border-radius: 1rem; */
}
.table tr {
    background-color: var(--surface);
    border-radius: 0.75rem;
    margin: 0.75rem;
}
th:first-child, td:first-child{
    /* border-top-left-radius: 0.75rem; */
    border-bottom-left-radius: 0.75rem;
}

th:last-child, td:last-child{
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}
.td-number{
    text-align: center !important;
    width: 100px;
}
/* .odd-table-row{
    
} */`, "",{"version":3,"sources":["webpack://./src/components/common/table/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,yBAAyB;IACzB,cAAc;;AAElB;;;;AAIA;IACI,oDAAoD;IACpD,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,qBAAqB;AACzB;AACA;IACI,wBAAwB;IACxB,6CAA6C;IAC7C,yBAAyB;AAC7B;AACA;IACI,gCAAgC;IAChC,sBAAsB;IACtB,eAAe;AACnB;AACA;IACI,qCAAqC;IACrC,kCAAkC;AACtC;;AAEA;IACI,gCAAgC;IAChC,mCAAmC;AACvC;AACA;IACI,6BAA6B;IAC7B,YAAY;AAChB;AACA;;GAEG","sourcesContent":[".table{\n    width: 100%;\n    text-align: left;\n    border-collapse: separate;\n    border-spacing: 0 1em;\n    /* table-layout: fixed; */\n    overflow: auto;\n\n}\n\n\n\n.table thead{\n    /* border-bottom: 1px solid var(--primary-color) ; */\n    border-radius: 8px;\n    text-align: left;\n    padding: 1.25rem 0px;\n}\n\n.table td {\n    padding: 0.75rem;\n    text-align: left;\n    max-width: 200px;\n    overflow: hidden;\n    width: fit-content;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    word-wrap: break-word;\n    word-break: break-all;\n}\n.table th {\n    padding: 1.25rem 0.75rem;\n    border-bottom: 1px solid var(--primary-color);\n    /* border-radius: 1rem; */\n}\n.table tr {\n    background-color: var(--surface);\n    border-radius: 0.75rem;\n    margin: 0.75rem;\n}\nth:first-child, td:first-child{\n    /* border-top-left-radius: 0.75rem; */\n    border-bottom-left-radius: 0.75rem;\n}\n\nth:last-child, td:last-child{\n    border-top-right-radius: 0.75rem;\n    border-bottom-right-radius: 0.75rem;\n}\n.td-number{\n    text-align: center !important;\n    width: 100px;\n}\n/* .odd-table-row{\n    \n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
