import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createOrganisation } from '../../../services/organisation'
import Button from '../../common/button'
import Input from '../../common/Input'



import "./styles.css"

type organisationModalProps =
{
    show:boolean,
    toggleModal: ()=>void
    org_id?:number,
    org_name?:number
}  

function OrganisationModal({show,toggleModal}:organisationModalProps) {
  
    const [orgName,setOrgName] = useState("")
    const [loading,setLoading] = useState(false)
    const [gstNumber,setGstNumber] = useState("")
    const navigator = useNavigate()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>,fieldName:string) => {
    if(fieldName === "orgName")
        setOrgName(e.target.value)
    else    
        setGstNumber(e.target.value)
  }
  
  const createOrg = async () => {
    setLoading(true)
    const response = await createOrganisation({
        name:orgName
    })

    setLoading(false)
    if(response){
        navigator('/organisations')
        toggleModal()
    }
  }

  const renderModal = () =>{
    if (show){
    return (
        <div>
             <div className='modal-backdrop' onClick={toggleModal}></div>
             <div className='modal-container-wrapper'>
                <div className='modal-container'>
            <h1 className='create-org-modal-header'>
                CREATE ORGANISATION
            </h1>
            <div className='modal-content'>
                <div className='modal-content-row'>
                    
                    <Input
                        onChange={(e) => handleInputChange(e, "orgName")}
                        value={orgName}
                        placeholder='Enter Organisation Name' 
                        type={'text'}   
                        hideSearch
                        required                 
                    />
                    
                </div>
                <div className='modal-content-row'>
                    <Input
                        onChange={(e)=>handleInputChange(e,"gstNumber")}
                        value = {gstNumber}
                        // className = "org-modal-input" 
                        placeholder='Enter GST Number'
                        type={'text'} 
                        hideSearch
                        required
                    />
                    
                </div>
            </div>
            <div className='modal-content'>
                <Button className='create-org-button' onClick = {createOrg} disabled = {orgName ==="" || loading || gstNumber ===""} type = {"primary"}>
                    Create 
                </Button>
            </div>
            </div>
            </div>
        </div>
    )
}
    else{
        return <div></div>
    }
  }
    return (

        renderModal()
    
  )
}

export default OrganisationModal