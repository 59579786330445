import React, { useCallback, useEffect, useState } from "react";
import { formValidator } from "../../../utils/formValidator";
import FormInput from "../../common/formInput";
import Loader from "../../common/loader";
import MultiSelectDropdown from "../../common/multiSelectDropdown";
import MultiLevelDropdown from "../../common/multiLevelDropdown";
import Tooltip from "./Tooltip";
import { config } from "../../../config";

type Props = {
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => void;
  handleCheckboxSelect: (e: any, fieldName: string) => void;
  state: any;
  errorObject: any;
  handleCompletedStages: (stage: number) => void;
  handleIndustryDropdownChange: (e: any, data: string[]) => void;
  getIndustries: (listOfIndustries: any) => any;
  listOfTargetIndustries: any;
  stage: number;
  handleRefClick: (ref: any) => void;
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  devImageRef: any;
  appLogoRef: any;
  ssoFileRef: any;
  loading: any;
  handleIndustriesDescriptionChange: any;
  handleVersionSelection: any;
  handleAddIndustry: any;
  handleDeleteIndustry: any;
  currentProcess: any;
  handleTypeOfUpdateDropdownChange: any;
  getUpdateOptions: any;
  getVersionSelectionOptions: any;
};

function GeneralInfo({
  handleInputChange,
  state,
  handleIndustryDropdownChange,
  handleCompletedStages,
  getIndustries,
  listOfTargetIndustries,
  stage,
  devImageRef,
  handleRefClick,
  handleFileUpload,
  appLogoRef,
  ssoFileRef,
  loading,
  handleVersionSelection,
  handleIndustriesDescriptionChange,
  handleAddIndustry,
  handleDeleteIndustry,
  currentProcess,
  handleTypeOfUpdateDropdownChange,
  getUpdateOptions,
  getVersionSelectionOptions,
}: Props) {
  const [error, setErrorObject] = useState<any>({});

  const handleImageUpload = (
    imageRef: any,
    fieldName:
      | "awi_app_logo"
      | "awi_app_developer_image"
      | "awi_developer_image"
      | "awi_so_file_path"
  ) => {
    handleRefClick(imageRef);
  };

  const alreadySelectedIndustries = state?.industry_uses?.map(
    (industry: any) => {
      return industry?.awi_name;
    }
  );

  useEffect(() => {
    const fieldsToValidate = [
      "awi_name",
      "awi_description",
      "awi_app_assumption",
      "awi_app_logo",
      "awi_developer_image",
      "awi_docker_image_name",
      "awi_major_version",
      "awi_minor_version",
      "awi_patch_version",
    ];
    let tempLocalErrors = formValidator(state, fieldsToValidate);

    if (state.industry_uses || [].length > 0) {
      for (let index = 0; index < state.industry_uses.length; index++) {
        if (
          state.industry_uses[index].awi_name === "" ||
          state.industry_uses[index]?.awi_description === ""
        ) {
          tempLocalErrors.awi_target_industries = true;
        }
      }
    }
    setErrorObject(tempLocalErrors);
    if (Object.values(tempLocalErrors).every((v) => !v)) {
      handleCompletedStages(stage);
    } else {
      handleCompletedStages(stage - 1);
    }
  }, [state, handleCompletedStages, stage]);

  const handleCreditsBurnRateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (/^\d+(\.\d+)?$/.test(newValue)) {
        handleInputChange(e, "awi_credits_burn_value");
      }
    },
    [handleInputChange]
  );

  const incrementCreditsBurnRate = useCallback(() => {
    const newValue =
      (parseInt(state.awi_credits_burn_value, 10) || 0) + config.CREDIT_STEPPER;
    const clampedValue = Math.min(newValue, config.CREDIT_MAX_VALUE);
    handleInputChange(
      {
        target: { value: clampedValue.toString() },
      } as React.ChangeEvent<HTMLInputElement>,
      "awi_credits_burn_value"
    );
  }, [state.awi_credits_burn_value, handleInputChange]);

  const decrementCreditsBurnRate = useCallback(() => {
    const currentValue = parseInt(state.awi_credits_burn_value, 10) || 0;
    const newValue = currentValue - config.CREDIT_STEPPER;
    const clampedValue = Math.max(newValue, config.CREDIT_MIN_VALUE);
    handleInputChange(
      {
        target: { value: clampedValue.toString() },
      } as React.ChangeEvent<HTMLInputElement>,
      "awi_credits_burn_value"
    );
  }, [state.awi_credits_burn_value, handleInputChange]);

  return (
    <div className='app-registration-form-body'>
      <div className='registration-form-subcontainer-heading'>
        Application Information
      </div>

      <div className='registration-general-subcontainer'>
        {/* awi_app_logo */}
        <div
          className={`upload-image-container upload-registration-image-container`}
        >
          {!state.awi_app_logo && !loading.awi_app_logo && (
            <img
              onClick={(e) => handleImageUpload(appLogoRef, "awi_app_logo")}
              src='/assets/icons/Appathon_Upload_logo.svg'
              alt='App logo'
            ></img>
          )}
          {loading.awi_app_logo && <Loader></Loader>}
          {state.awi_app_logo && (
            <img
              onClick={(e) => handleImageUpload(appLogoRef, "awi_app_logo")}
              src={state.awi_app_logo as string}
              alt='developer'
            ></img>
          )}

          <input
            type='file'
            ref={appLogoRef}
            onChange={handleFileUpload}
            className='file-upload-input'
            accept='image/*'
            id='awi_app_logo'
          ></input>
        </div>
        <div className='registration-general-details'>
          <div className='registration-general-details-row'>
            <div className='registration-general-details-row-inputcontainer'>
              <FormInput
                placeHolder='Name of App'
                changeHandler={handleInputChange}
                type={"input"}
                required
                disabled={currentProcess !== "registerApp"}
                labelOnTop
                fieldName='awi_name'
                value={state.awi_name}
                error={error.awi_name}
              ></FormInput>
            </div>
            <div
              className='registration-general-details-use-container'
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              {currentProcess === "registerAppVersion" && (
                <div style={{ width: "160px" }}>
                  <MultiSelectDropdown
                    placeholder={
                      state.awi_version_upgrade_type || "Type of update"
                    }
                    changeHandler={handleTypeOfUpdateDropdownChange}
                    multiselect={false}
                    options={getUpdateOptions()}
                    required={true}
                    id={"awi_version_upgrade_type"}
                    selectedValues={state.awi_version_upgrade_type}
                    error={error.industry_uses}
                    allowOptionScroll
                    showSelectedOptions={false}
                    hideChip={true}
                  ></MultiSelectDropdown>
                </div>
              )}
              {currentProcess === "registerAppVersion" &&
                state.awi_version_upgrade_type &&
                state.awi_version_upgrade_type !== "major" && (
                  <div className='registration-general-updatetype-dropdown'>
                    <MultiLevelDropdown
                      handleSelection={handleVersionSelection}
                      dropdownItems={getVersionSelectionOptions()}
                      numberOfLevels={
                        state.awi_version_upgrade_type === "patch" ? 2 : 1
                      }
                    ></MultiLevelDropdown>
                  </div>
                )}

              {currentProcess === "registerAppVersion" ? (
                <div>
                  {state.awi_major_version !== null &&
                    state.awi_minor_version !== null &&
                    state.awi_patch_version !== null && (
                      <span>{`v ${state.awi_major_version}.${state.awi_minor_version}.${state.awi_patch_version}`}</span>
                    )}
                </div>
              ) : (
                <FormInput
                  placeHolder={"App version"}
                  changeHandler={handleInputChange}
                  type={"input"}
                  required
                  labelOnTop
                  disabled
                  fieldName='awi_app_version'
                  value={`${state.awi_major_version}.${state.awi_minor_version}.${state.awi_patch_version}`}
                  error={error.awi_app_version}
                ></FormInput>
              )}
            </div>
          </div>
          <div className='registration-general-details-row'>
            <div className='registration-general-details-row-inputcontainer'>
              <FormInput
                placeHolder='What does your app do? '
                changeHandler={handleInputChange}
                type={"input"}
                required
                labelOnTop
                fieldName='awi_description'
                value={state.awi_description}
                error={error.awi_description}
              ></FormInput>
            </div>
            <div className='registration-general-details-row-inputcontainer'>
              <FormInput
                placeHolder='Docker image name '
                changeHandler={handleInputChange}
                type={"input"}
                required
                labelOnTop
                fieldName='awi_docker_image_name'
                value={state.awi_docker_image_name}
                error={error.awi_docker_image_name}
              ></FormInput>
            </div>
            {/* <div className="registration-general-details-row-inputcontainer">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: "8px",
                }}
              >
                {
                  <img
                    onClick={(e) =>
                      handleImageUpload(ssoFileRef, "awi_so_file_path")
                    }
                    src='/assets/icons/appathon_input.svg'
                    alt='SO file'
                  ></img>
                }
                {loading.awi_so_file_path && <Loader></Loader>}
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>Upload SO file </span>
                    <img
                      src='/assets/icons/caution.svg'
                      title={`The "SO file" is the compiled package for the CV application, stored in a tar format. You need to upload this file before you can publish the app. `}
                      style={{ marginLeft: "40px" }}
                    />
                  </div>
                  <div className='input-info'>
                    <span className='input-info-key'>SO FILE PATH : </span>
                    /home/awiros-docker/awiros-apps/build/libs/
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className='registration-general-details-row'>
            <div className='registration-general-details-row-inputcontainer'>
              <FormInput
                placeHolder='What are top features of your app? '
                changeHandler={handleInputChange}
                type={"input"}
                required
                labelOnTop
                fieldName='awi_app_assumption'
                value={state.awi_app_assumption}
                error={error.awi_app_assumption}
              ></FormInput>
            </div>
            <div className='registration-general-details-row-inputcontainer'>
              <FormInput
                placeHolder='Add release notes'
                changeHandler={handleInputChange}
                type={"input"}
                required
                labelOnTop
                fieldName='awi_release_note'
                value={state.awi_release_note}
                error={error.awi_release_note}
              ></FormInput>
            </div>
          </div>
          <div>
            <div className='input-info-key'>
              Industry in which your app can be used?
            </div>
            <div className='registration-general-details-row'>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                {state?.industry_uses?.map((item: any, index: number) => (
                  <div
                    className='registration-general-details-row-inputcontainer'
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <div className='registration-general-details-row-inputcontainer'>
                      <div style={{ width: "100%" }}>
                        <MultiSelectDropdown
                          placeholder={
                            state.industry_uses[index].awi_name === ""
                              ? "Industries"
                              : state.industry_uses[index].awi_name
                          }
                          changeHandler={handleIndustryDropdownChange}
                          multiselect={false}
                          options={getIndustries(listOfTargetIndustries).filter(
                            (item: any) =>
                              !alreadySelectedIndustries.includes(item)
                          )}
                          required={true}
                          id={`${index}`}
                          selectedValues={
                            state.industry_uses[index].awi_name === ""
                              ? []
                              : [state.industry_uses[index].awi_name]
                          }
                          error={error.industry_uses}
                          allowOptionScroll
                          showSelectedOptions={false}
                          hideChip={true}
                        ></MultiSelectDropdown>
                      </div>
                    </div>
                    <div className='registration-general-details-use-container'>
                      <FormInput
                        placeHolder='App Use Case'
                        changeHandler={handleIndustriesDescriptionChange}
                        type={"input"}
                        required={true}
                        labelOnTop
                        fieldName={`${index}`}
                        value={state.industry_uses[index].awi_description}
                      ></FormInput>
                    </div>
                    {index === state.industry_uses.length - 1 ? (
                      <img
                        style={{
                          width: "46px",
                          marginTop: "8px",
                          cursor: "pointer",
                        }}
                        src='/assets/icons/appathon_input.svg'
                        onClick={handleAddIndustry}
                        alt='Add Industry'
                      ></img>
                    ) : (
                      <img
                        style={{
                          width: "46px",
                          marginTop: "8px",
                          cursor: "pointer",
                        }}
                        src='/assets/icons/appathon_delete.png'
                        alt='Delete Industry'
                        onClick={() => handleDeleteIndustry(index)}
                      ></img>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className='registration-credit-details-row-container'>
              <div className='input-info-key'>App Credits (hourly)</div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <button
                  className='price-button'
                  type='button'
                  onClick={decrementCreditsBurnRate}
                >
                  <p>-</p>
                </button>
                <FormInput
                  placeHolder=''
                  changeHandler={handleCreditsBurnRateChange}
                  type={"number"}
                  required={false}
                  step={10}
                  min={0}
                  fieldName='awi_credits_burn_value'
                  value={state.awi_credits_burn_value}
                  error={error.awi_credits_burn_value}
                  disabled={true}
                ></FormInput>
                <button
                  className='price-button'
                  type='button'
                  onClick={incrementCreditsBurnRate}
                >
                  <p>+</p>
                </button>
                <Tooltip content='The App Credits/Hour value represents the hourly credit consumption for running this app on Cloud. This is the cost that end-users will incur for every hour the app is active on their pipelines.'>
                  <img
                    src='/assets/icons/caution.svg'
                    alt='Info'
                    style={{
                      marginTop: "2rem",
                      marginLeft: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='registration-form-subcontainer-heading'
        style={{
          marginTop: "64px",
        }}
      >
        Additional Information which will be helpful to promote your App
      </div>

      <div className='registration-general-subcontainer'>
        <div
          className={`upload-image-container upload-registration-image-container`}
        >
          {!state.awi_developer_image && !loading.awi_developer_image && (
            <img
              onClick={(e) =>
                handleImageUpload(devImageRef, "awi_developer_image")
              }
              src='/assets/icons/appathon_developer.svg'
              alt='developer'
            ></img>
          )}
          {loading.awi_developer_image && <Loader></Loader>}
          {state.awi_developer_image && (
            <img
              onClick={(e) =>
                handleImageUpload(devImageRef, "awi_developer_image")
              }
              src={state.awi_developer_image as string}
              alt='developer'
              // style={{ width: "180px", objectFit: "cover" }}
            ></img>
          )}

          <input
            type='file'
            ref={devImageRef}
            onChange={handleFileUpload}
            className='file-upload-input'
            accept='image/*'
            id='awi_developer_image'
          ></input>
        </div>
        <div className='registration-general-details'>
          <div className='registration-general-details-row'>
            <div
              className='registration-general-details-row-inputcontainer'
              style={{ maxWidth: "500px" }}
            >
              <FormInput
                placeHolder='Developer Name '
                changeHandler={handleInputChange}
                type={"input"}
                required={false}
                labelOnTop
                fieldName='awi_app_developer'
                value={state.awi_app_developer}
                error={error.awi_app_developer}
                disabled={true}
              ></FormInput>
            </div>
          </div>
          {/* <div className="registration-general-aggrement-container">
            <input
              type="checkbox"
              checked={state.awi_aggreement_accepted}
              onChange={(e) =>
                handleCheckboxSelect(e, "awi_aggreement_accepted")
              }
            ></input>{" "}
            <span>I agree to the User Agreement. *</span>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default GeneralInfo;
