import { faComments, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


import { getUser } from "../../services/user";
import { RootState } from "../../store";
import { formatDate } from "../../utils/formatDate";
import "./styles.css";

function UserProfile() {
    const [user, setUser] = useState<any>();

    const parsedToken = useSelector(
        (state: RootState) => state.isLoggedIn
    ).role.id

    useEffect(() => {
        
        getUserInfo();
        
    },[]);

    const getUserInfo = async()=>{
        
        let tempUserInfo = await getUser({
            id:parsedToken
        })
        
        setUser(tempUserInfo);
    }
    const copyKey = ()=>{
        navigator.clipboard.writeText(user?.api_key)
        toast.success('Copied Token to Clipboard!')
    }
    const sendEmail = (e:any) =>{
        // eslint-disable-next-line no-restricted-globals
        location.href = "mailto:info@awiros.com";
        
    }
    return (
        <div>
            <div className="user-profile-container">
                <h1>
                    User Dashboard
                </h1>
                <div className="personal-details-container">
                    <h2>
                        Personal Details
                    </h2>
                    <div className="personal-details-wrapper">
                        <div className="profile-image-container">
                            <img src = {user && user.profile_image?user.profile_image:'/assets/images/user.png'} alt={user?.name}></img>
                        </div>
                        <div className="user-details">
                            <div className="detail-tile">
                                <label>Name:</label>
                                <p>{user?.name}</p>
                            </div>
                            <div className="detail-tile">
                                <label>Email:</label>
                                <p>{user?.email}</p>
                            </div>
                            <div className="detail-tile">
                                <label>Password:</label>
                                <p>**********</p>
                            </div>
                            <div className="detail-tile">
                                <label>Organisation:</label>
                                <p>{user?.org_name}</p>
                            </div>
                            <div className="detail-tile">
                                <label>Role:</label>
                                <p>{user?.role}</p>
                            </div>
                            <div className="detail-tile">
                                <label>Joining Date:</label>
                                <p>{ formatDate( user?.joined_date)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="personal-details-container">
                <h2 className="api-key-header">
                    API Key
                </h2>
                <div className="api-key-container" onClick={copyKey}>
                <p >{user?.api_key}</p>
                <FontAwesomeIcon icon={faCopy}/>
                </div>
                
                </div>
                <div className="need-help-container" onClick={sendEmail}>
                    
                    <h2>Need Help ? </h2>
                    <FontAwesomeIcon icon={faComments}></FontAwesomeIcon>
                    
                </div>
            </div>
        </div>
    );
}

export default UserProfile;
