import AppRouter from "./routes";

import "./App.css";
import AppHeader from "./components/appHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./store";
import { useEffect } from "react";
import { getUser, getUserRole } from "./services/user";
import { setUserSuccess } from "./pages/userProfile/userSlice";
import { useLocation } from "react-router-dom";
import { roleSuccess } from "./pages/Auth/login/loginSlice";

function App() {


    const isLoggedIn = useSelector(
        (state: RootState) => state.isLoggedIn
    ).value;

    const location = useLocation()
    
    const userObject = useSelector(
        (state: RootState) => state.userDetails
    )
    const parsedToken = useSelector(
        (state: RootState) => state.isLoggedIn
    ).role.id
    const dispatch = useDispatch()
    
    const getUserInfo = async() =>{
        console.log(parsedToken,"parsed token")
        if(parsedToken){
            const userResponse = await getUser(
            {id:parsedToken}
        )
        if (userResponse)
        dispatch(setUserSuccess(userResponse))
        }
        else{
            const getRoleResponse = await getUserRole({})
            console.log(getRoleResponse,"get role response")
            if(getRoleResponse){
                dispatch(roleSuccess(getRoleResponse))
                const userResponse = await getUser(
                    {id:getRoleResponse["_id"]}
                )
                if (userResponse){
                    console.log(userResponse,"user response")
                    dispatch(setUserSuccess(userResponse))
                }
            }
        }
    }

    useEffect(() => {
        isLoggedIn && getUserInfo()
    }, [location])
    
    return (
      <>
        <AppHeader></AppHeader>
        <div className="pages-container" id="pages-container">
          <AppRouter></AppRouter>
        </div>
        <footer className="pages-footer">
          <span>
            © 2020 All Rights Reserved.{" "}
            <a
              target="_blank"
              href="https://awiros.com/docs/awidit_privacy_policy.pdf"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a target="_blank" href="https://awiros.com/docs/Terms_of_use.pdf">
              Terms of Use.
            </a>
          </span>{" "}
          <span>
            Awiros name and logo are registered trademarks of Awidit Systems
            Pvt. Ltd.
          </span>
        </footer>
      </>
    );
}

export default App;
