import React, { useRef, useEffect, useState } from "react";

interface DragAndDropProps {
  handleFileSelect: (files: FileList) => void;
  children: JSX.Element;
  className?: string;
}

const DragAndDrop: React.FC<DragAndDropProps> = ({
  children,
  handleFileSelect,
  className = "",
}) => {
  const [dragging, setDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement | null>(null);
  let dragCounter = 0;

  useEffect(() => {
    const currentRef = dropRef.current;
    if (currentRef) {
      currentRef.addEventListener("dragenter", handleDragIn);
      currentRef.addEventListener("dragleave", handleDragOut);
      currentRef.addEventListener("dragover", handleDrag);
      currentRef.addEventListener("drop", handleDrop);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("dragenter", handleDragIn);
        currentRef.removeEventListener("dragleave", handleDragOut);
        currentRef.removeEventListener("dragover", handleDrag);
        currentRef.removeEventListener("drop", handleDrop);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (
      e.dataTransfer &&
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0
    ) {
      setDragging(true);
    }
  };

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter > 0) return;
    if (
      e.dataTransfer &&
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0
    ) {
      setDragging(false);
    }
  };

  const handleDrop = (e: DragEvent) => {
    // console.log("drop event triggered")
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      handleFileSelect(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  return (
    <div
      ref={dropRef}
      className={
        dragging ? `drag-and-drop-container ${className}` : `${className}`
      }
    >
      {!dragging && children}
      {dragging && <p>Drop Items here!</p>}
    </div>
  );
};

export default DragAndDrop;
