import axios from "axios";
import bcrypt from "bcryptjs";
import { toast } from "react-toastify";
import { authURL, resetLinkGenerateURL, signupURL } from "../constants";
import { ISignupResponse } from "../interfaces/loginFormProps";
import {
  ForgotPasswordForm,
  UpdatePasswordForm,
  UserLoginForm,
  UserSignupForm,
} from "../typings/userLogin";
import { client } from "./axiosInstance";

let isLoggedIn = true;

const generateCaptcha = async () => {
  const captchaResponse = await client("/generate_captcha", {
    baseURL: "auth",
    method: "GET",
  });

  if (captchaResponse.data.success) {
    return captchaResponse.data;
  } else {
    return "";
  }
};

const getSalt = async (email: string) => {
  try {
    const saltResponse = await client("/salt", {
      baseURL: "auth",
      method: "GET",
      params: {
        email: email,
      },
    });

    if (saltResponse.status === 200) {
      return saltResponse.data;
    }
  } catch (error) {
    console.log(error);
  }
};

const signUpService = async (payload: UserSignupForm) => {
  if (payload.Password && payload.ConfirmPassword !== payload.Password) {
    return {
      error: "Pasword and Confirm Password do not match",
    };
  }
  const salt = bcrypt.genSaltSync();
  const pass = bcrypt.hashSync(payload.Password!, salt);

  const data = {
    name: payload.FirstName + " " + payload.LastName,
    email: payload.Email,
    password: pass,
    salt: salt,
    token: payload.Token,
    role: payload.Dropdown,
  };
  let signUpData;
  try {
    signUpData = await axios.post<ISignupResponse>(`${signupURL}`, data);
  } catch (error: any) {
    toast.error(error.response.data.message);
  }

  return signUpData;
};

const loginService = async (payload: UserLoginForm) => {
  try {
    let salt = await getSalt(payload.Email!);
    let pass = bcrypt.hashSync(payload.Password!, salt.data.salt);
    const newSalt = salt.data.newSalt;
    if (newSalt) {
      pass = bcrypt.hashSync(pass, newSalt);
    }

    let loginData = {
      captcha: payload.Captcha,
      email: payload.Email,
      password: pass,
      saltID: salt.data.saltId,
      remember: payload.RememberMe,
    };

    let signinResponse = await client("/signin", {
      method: "POST",
      baseURL: "auth",
      data: loginData,
    });

    if (signinResponse.status === 200) {
      console.log(signinResponse.data);
      localStorage.setItem("token", signinResponse.data.token);
      isLoggedIn = true;
    }

    return true;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    localStorage.removeItem("token");
    return false;
  }
};

const updatePasswordService = async (payload: UpdatePasswordForm) => {
  if (payload.ConfirmPassword !== payload.NewPassword) {
    return {
      error: "Pasword and Confirm Password do not match",
    };
  }
  const salt = bcrypt.genSaltSync();
  const pass = bcrypt.hashSync(payload.NewPassword!, salt);

  const data = {
    password: pass,
    salt: salt,
    token: payload.Token,
  };
  let signUpData;
  try {
    signUpData = await axios.post<ISignupResponse>(
      `${authURL}/reset-password`,
      data
    );
    if (signUpData.status === 200) {
      toast.success("Password updated successfully");
    }
  } catch (error: any) {
    toast.error(error.response.data.message);
  }

  return signUpData;
};

const generateResetPasswordToken = async (payload: ForgotPasswordForm) => {
  const data = {
    email: payload.Email,
  };
  let tokenResponse;
  try {
    tokenResponse = await axios.post<ISignupResponse>(
      `${resetLinkGenerateURL}`,
      data
    );
    if (tokenResponse.status === 200) {
      toast.success("Password reset link sent to your email");
    }
    return tokenResponse;
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
};

export {
  isLoggedIn,
  generateCaptcha,
  getSalt,
  loginService,
  signUpService,
  updatePasswordService,
  generateResetPasswordToken,
};
