import { ChangeEvent, useEffect, useState } from "react"
import { formValidator, verifyEmail } from "../../../../utils/formValidator"
import Input from "../../../common/Input"

import "./styles.css"

type Props = {
    state: any,
    stage: number,
    completeStage: (stage: number) => void,
    errorObject: any
    handleErrorObjectChange: (e: any) => void,
    handleInputChange:(e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void
}


function OrgInformation({state,stage,completeStage,errorObject,handleErrorObjectChange,handleInputChange}:Props) {

    useEffect(() => {
        let tempLocalErrors = formValidator(state,["organisation_name","gst_number","customer_name","partner_name","project_title","poc_name","poc_email","poc_phone"]) 
        tempLocalErrors["poc_email"] = verifyEmail(state.poc_email)
        if(Object.values(tempLocalErrors).every((v) => !v)){
            completeStage(stage)
            handleErrorObjectChange(tempLocalErrors)
            
        }
        else{
            completeStage(stage-1)
        }
    },[state])
  return (
    <div className="order-org-information-container">
        <div className="create-order-row-item">
				<Input
                  placeholder="Organisation name"
                  onChange={(e) => handleInputChange(e, "organisation_name")}
                  value={state.organisation_name || state.org_name || ""}
                  disabled 
                  required
                  type={"text"}
                  hideSearch
            	  error={errorObject?.organisation_name}				
				></Input>
                <Input
				  placeholder="GST Number"
                  onChange={(e) => handleInputChange(e, "gst_number")}
                  value={state.gst_number}
                  type={"text"}
                  hideSearch
                  required
                  error={errorObject?.gst_number}
				></Input>
                <Input
					value={state.customer_name}
					placeholder = "Customer Name"
					onChange={(e)=>handleInputChange(e,"customer_name")}
                    
                    required
                    type={"text"}
                    hideSearch
                    error = {errorObject?.customer_name}
				></Input>
		</div>
				
		<div className="create-order-row-item">
                <Input
					value={state.partner_name}
					placeholder = "Partner Name"
					hideSearch
					onChange={(e)=>handleInputChange(e,"partner_name")}
                    error = {errorObject?.partner_name} 
                    required
                    type={"text"}
				></Input>
                <Input
					value={state.project_title}
					placeholder = "Project Name"
					onChange={(e)=>handleInputChange(e,"project_title")}                    
                    required
                    type={"text"}
                    hideSearch
                    error = {errorObject?.project_title}
				></Input>
		</div>
        <div className="create-order-row-item">
            <label>Person of Contact</label>
        </div>
        <div className="create-order-row-item">
            <Input
    			value={state.poc_name}
    			placeholder = "Name"
    			onChange={(e)=>handleInputChange(e,"poc_name")}
                required
                type={"text"}
                hideSearch
                error = {errorObject?.poc_name}
			></Input>
            <Input
					value={state.poc_email}
					placeholder = "Email"
					type={"email"}
					onChange={(e)=>handleInputChange(e,"poc_email")}
                    required
                    hideSearch
                    error = {errorObject?.poc_email}
                    errorMessage = "Please enter a valid email"
			></Input>
            <Input
				value={state.poc_phone}
				placeholder = "Phone Number"
				required
                type={"tel"}
                hideSearch
                error = {errorObject?.poc_phone}
                pattern="^[0-9]*$"
				onChange={(e)=>handleInputChange(e,"poc_phone")}
			></Input>
        </div>
    </div>
  )
}

export default OrgInformation

