import {
    faCamera,
    faGear,
    faIdCard,
    faRightFromBracket,

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useOutsideAlerter from "../../../hooks/OutsideAlerter";
import { uploadFile } from "../../../services/appRegistration";
import { updateProfilePicture } from "../../../services/user";
import { RootState } from "../../../store";

import Image from "../../common/image";
import Modal from "../../common/modal";
import ProfileSettings from "../profileSettings";


import "./style.css";
const ProfileAvatar = () => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [profileModal,setProfileModal] = useState<boolean>(false);

    const renderDropdownMenu = () => {

        setShowDropdown(!showDropdown);
    };

    const closeDropDownMenu = () => {
        setShowDropdown(false);
    };

    const userObject = useSelector(
        (state: RootState) => state.userDetails
    )
    const profileImageRef = useRef<any>(null);

    const navigation = useNavigate()

        const handleFileUpload = async (e:React.ChangeEvent<HTMLInputElement>) => {
        const fileSelected = e.target.files!
        const formData = new FormData();
        formData.append("file", fileSelected[0], fileSelected[0].name);
        const profileImageResponse = await uploadFile(formData)

        await updateProfilePicture({
            email:userObject.email,
            profilePicture: profileImageResponse
        })
    }

    const dropdownOptions = [
        
        
        {
            itemText: (
                <>
                    <FontAwesomeIcon icon={faIdCard} />
                    Copy API Key
                </>
            ),
            action: "copy_key"
        },
        {
            itemText: (
                <>
                    <FontAwesomeIcon icon={faGear} />
                    Account Settings
                </>
            ),
            action: "profile"
        },
        {
            itemText: (
                <>
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    Logout
                </>
            ),
            action: "logout"
        },
    ];

    const handleClick = (e:any,action:string) =>{

        switch (action) {
            case 'copy_key':
                navigator.clipboard.writeText(userObject.api_key)
                toast.success("API Key Copied")
            break;
            
            case 'profile':
                closeDropDownMenu()
                setProfileModal(true)    
            break;

            case 'logout':
                navigation('/logout')

            break;
            default:
            break;
        }
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, closeDropDownMenu);
    
    return (
        <div className="dropdown-wrapper" ref={wrapperRef}>
            <div onClick={renderDropdownMenu} className="avatar-container">
                <div className="profile-picture-container">
                    <img src={userObject.profilePicture??"/assets/images/user.png"} alt= {userObject.name}></img>
                    
                </div>
                {/* <FontAwesomeIcon icon={faUser} /> */}
            </div>
            {showDropdown && (
                <div className="dropdown-menu">
                    <div className="user-dropdown-top">
                        <div className="profile-photo-container" onClick={(e)=>{profileImageRef?.current?.click()}}>
                            <input
                            type="file"
                            ref = {profileImageRef}
                            onChange={handleFileUpload}
                            className = "file-upload-input"
                            accept="image/*"
                            id = "awi_app_logo"                    
                        ></input>
                        <div className="camera-icon-container">
                            <FontAwesomeIcon icon={faCamera} />
                        </div>
                            <Image src={userObject.profilePicture??"/assets/images/user.png"} alt= {userObject.name}/>
                            {/* <div className="profile-image-hover"></div> */}
                        </div>
                        <h2 className="user-profile-header">{userObject.name}</h2>
                        <p>{userObject.email}</p>
                    </div>
                    {dropdownOptions.map((item) => {
                        return (
                                <li className="dropdown-nav-items" onClick={(e)=>handleClick(e,item.action)}>
                                    {item.itemText}
                                </li>
                        );
                    })}
                </div>
            )}
            { profileModal && <ProfileSettings open = {profileModal} onClose = {()=>setProfileModal(false)}></ProfileSettings>}
        </div>
    );
};

export default ProfileAvatar;
