import React, { useEffect } from 'react'
import { formValidator } from '../../../../utils/formValidator'

import Input from '../../../common/Input'
import MultiSelectDropdown from '../../../common/multiSelectDropdown'
import Icon from '../../../Icons'

import "./styles.css"

type Props = {
    state: any,
    appTotal:any,
    stage: number,
    completeStage: (stage: number) => void,
    errorObject: any
    handleErrorObjectChange: (e: any) => void,
    extractAppNames: (selectedApps?:any) => string[],
    isEdit: boolean,
    handleAppCountChange: (e:React.ChangeEvent<HTMLInputElement>,idx:number)=>void,
    handleTotalsObjectChange : (e:any,fieldName:string)=> void,
    copyAboveConfigs: (idx:number)=>void,
    handleAppItemChange: (e:any,data:string,fieldName:string,idx:number) => void,
    handleDropdownChange: (event: any, data: string[])=>void,
	pasteConfigs:(idx:number)=>void,
	pasteAllConfigs:()=>void
}
function AppInformation(
    {
        state,
        stage,
        completeStage,
        errorObject,
        handleErrorObjectChange,
        extractAppNames,
        isEdit,
        appTotal,
        handleAppCountChange,
        handleTotalsObjectChange,
        copyAboveConfigs,
        handleAppItemChange,
        handleDropdownChange,
		pasteConfigs,
		pasteAllConfigs
    }:Props) {

		  useEffect(() => {
			  let tempLocalErrors = formValidator(state,["liscence_type","app_data"]) 
			  let appdataErrors = state.app_data.map((app:any)=>formValidator(app,["awi_name","app_count","tenure","grace_period"]))
			  let totalDataErrors =formValidator(appTotal,["app_count","tenure","grace_period"])
			  let tempTotalErrors = Object.values(totalDataErrors).some((v: any) => v)
			//   tempLocalErrors.app_data = tempLocalErrors.app_data && !appdataErrors.some((v: boolean) => v===true ? true : false)
			  tempLocalErrors.app_total = tempTotalErrors
			  appdataErrors = appdataErrors.map((app:any)=>Object.values(app).some((v: any) => v))
			  appdataErrors = appdataErrors.some((v: boolean) => v===true ? true : false)
			  tempLocalErrors.app_data = tempLocalErrors.app_data || appdataErrors
			  
		
		if(Object.values(tempLocalErrors).every((v) => !v)){
            completeStage(stage)

            handleErrorObjectChange(tempLocalErrors)
        }
		else{
			completeStage(stage-1)
		}
    },[state,appTotal])

  return (
    <div className='order-org-information-container'>
        <div className="order-app-info">
			<div className='licence-dropdown-container'>
            <MultiSelectDropdown
				changeHandler={handleDropdownChange}
				options={["Perpetual"]} 
				placeholder={"Licence Type"} 
				multiselect={false}
				id = "liscence_type"
				selectedValues={state.liscence_type ?[state.liscence_type]:[]}                
			/>
			</div>
			<div className='app-select-dropdown-container'>
            <MultiSelectDropdown
								changeHandler={handleDropdownChange}
								// error={errorObject.Password}
								options={extractAppNames()} 
								placeholder={"Select applications"} 
								multiselect={true}
								selectedValues={extractAppNames(state?.app_data)}
								id = "app_data"                
							/>
			</div>
        </div>
        {state?.app_data?.map((selectedApps:any,idx:number)=>{

						return (
                            <>
                            <div className="create-order-row-header">
                                <p>
										{selectedApps.awi_name}         
									{
									idx===0 && <span className='paste-configs' onClick = {pasteAllConfigs}>Paste all configs</span>
								}
								</p>
								
                            </div>
							
								<div className="create-order-app-tray">
								
								<div>
									
									<Input
										
										placeholder="No. of Channels"
										onChange={(e)=>handleAppItemChange(e,e.target.value,"app_count",idx)}
										type="number"
										hideSearch
										required={true}
										// error={errorObject.Password}
										value={selectedApps.app_count}
									/>
								</div>
								<div>
									
									<Input
										type="number"
										value={selectedApps.tenure}
										onChange={(e)=>{handleAppItemChange(e,e.target.value,"tenure",idx)}}
										placeholder = {"Tenure(years)"}
										hideSearch
										required
									/>
									
								</div>
								<div>
								<Input
										type="number"
										hideSearch
										value={selectedApps.grace_period}
										onChange={(e)=>{handleAppItemChange(e,e.target.value,"grace_period",idx)}}
										placeholder = {"Grace Period(days)"}
										required
									/>
								</div>
								<div>
									<Input
										type="date"
										value={selectedApps.liscence_start_date}
										onChange={(e)=>{handleAppItemChange(e,e.target.value,"liscence_start_date",idx)}}
										placeholder = {"Start Date"}
										hideSearch
										required
									/>

							
			
								</div>
								<div>
									<Input
										type="date"
										value={selectedApps.liscence_end_date}
										onChange={(e)=>{handleAppItemChange(e,e.target.value,"liscence_end_date",idx)}}
										placeholder = {"End Date"}
										hideSearch
										required
									/>

								</div>
								<div className="copy-config-button">
									<Icon src='/assets/icons/copy.svg' title='copy' height='1.5rem' width='1.5rem' padding='0' onClick = {()=>copyAboveConfigs(idx)}></Icon>
									<Icon src='/assets/icons/paste.svg' title = 'paste' height='1.5rem' width='1.5rem' padding='0' onClick = {()=>pasteConfigs(idx)}></Icon>
								</div>
								</div>
							
                            </>
						)
                        
						
					}
                    )}
                <div className="create-order-row-header">
                    <span>
								{appTotal.awi_name} 
																				
					</span>
                </div>
				<hr></hr>
                          
							
							
							<div className="create-order-app-tray">
							<div>
								      
							<Input
								placeholder="No. of Channels"
								onChange={(e)=>handleAppCountChange(e,0)}
								type="number"
								required={true}
								hideSearch
								// error={errorObject.Password}
								disabled
								value={appTotal.app_count}
							/>
							</div>     
							<div >
								
								<Input
									type="number"
									hideSearch
									value={appTotal.tenure}
									onChange={(e)=>{handleTotalsObjectChange(e,"tenure")}}
									placeholder = {"Tenure (years)"}
									required
								></Input>
							</div>
							<div>
								
							<Input
								type="number"
								hideSearch
								placeholder="Grace Period (days)"
								value={appTotal.grace_period}
								onChange={(e)=>{handleTotalsObjectChange(e,"grace_period")}}
								required
							></Input>
							</div>
							<div>
								
							{/* <DatePickerInput 
								value={new Date(appTotal.liscence_start_date)} 
								onChange={(e:any,event:any)=>{handleDateChange(event,e,"liscence_start_date")}} 
								dateFormat="MMMM dd, yyyy"
							/>	 */}
							<Input
								type="date"
								value={appTotal.liscence_start_date}
								placeholder = {"Start Date"}
								hideSearch
								onChange={(e)=>{handleTotalsObjectChange(e,"liscence_start_date")}}
								required
							/>
							
							</div>
							<div>
							

							<Input
								type="date"
								value={appTotal.liscence_end_date}
								onChange={(e)=>{handleTotalsObjectChange(e,"liscence_end_date")}}
								placeholder = {"End Date"}
								hideSearch
								required
							/>
							</div>
							</div>
				
    </div>
  )
}

export default AppInformation