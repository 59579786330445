import { useState } from 'react';
import Button from '../../components/common/button';
import './styles.css'
import Modal from '../../components/common/modal';
import BulkInvite from '../../components/Users/BulkInvite';

function InviteUsers() {
    const [showModal, setShowModal] = useState(false)

    const handleModal = () => {
        setShowModal(!showModal)
    }

  return (
    <div className='invite-user-wrapper'>
        <div>
            <h1>Bulk Invite Users</h1>
            <Button onClick={handleModal}>Upload CSV</Button>
        </div>
        <Modal onClose={handleModal} open={showModal}>
                <BulkInvite></BulkInvite>
            </Modal>
    </div>
  )
}

export default InviteUsers;