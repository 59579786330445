import {
  faMagnifyingGlass,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import FiltersDropdown from "./filterDropdown";
import "./styles.css";

type InputProps = {
  type: string;
  value: string | number | String | undefined;
  onChange: (e: any) => void;
  placeholder?: string;
  checked?: boolean;
  required?: boolean;
  max?: number;
  min?: number;
  step?: number;
  disabled?: boolean;
  showFilters?: boolean;
  filterFields?: string[];
  filterOptions?: any[][];
  isMultiSelect?: boolean[];
  filterHandlers?: ((event: any, data: string[]) => void)[];
  clearFilters?: () => void;
  appliedFilters?: any[][];
  hideSearch?: boolean;
  format?: string;
  error?: boolean;
  errorMessage?: string;
  pattern?: string;
  setInputFocussedOnce?: () => void;
  applyHandler?: () => void;
  onKeyDown?: (e: any) => void;
  toolTip?: string | null;
};

function Input({
  type,
  value,
  onChange,
  placeholder,
  checked,
  required,
  max,
  min,
  step,
  disabled,
  showFilters,
  filterFields,
  filterOptions,
  isMultiSelect,
  filterHandlers,
  clearFilters,
  appliedFilters,
  hideSearch,
  error,
  errorMessage,
  pattern,
  setInputFocussedOnce,
  applyHandler,
  onKeyDown,
  toolTip = null,
}: InputProps) {
  const [inputFocussed, setInputFocussed] = useState(false);
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const [focussedOnce, setFocussedOnce] = useState(false);

  useEffect(() => {
    if (value !== null || undefined) {
      if (typeof value !== "string") {
        setInputFocussed(true);
        setFocussedOnce(true);
        setInputFocussedOnce && setInputFocussedOnce();
      } else if (value.trim() !== "") {
        setInputFocussed(true);
        setFocussedOnce(true);
        setInputFocussedOnce && setInputFocussedOnce();
      }
    }
  }, [value, error]);

  const handleFilterClick = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  const handleLabelClick = () => {
    setInputFocussed(true);
    inputRef.current?.focus();
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const handleFocus = (e: any) => {
    setInputFocussed(true);
    if (type === "date") {
      e.target.type = "date";
    }
  };

  const handleBlur = (e: any) => {
    !focussedOnce && setFocussedOnce(true);
    !focussedOnce && setInputFocussedOnce && setInputFocussedOnce();

    if (value === null || value === undefined) {
      setInputFocussed(false);
    } else {
      if (typeof value === "string" && value.trim() === "") {
        setInputFocussed(false);
      }
    }
    if (type === "date") {
      e.target.type = "text";
    }
  };

  return (
    <div className={`common-input-component `}>
      <fieldset
        className={`input-component-wrapper ${
          focussedOnce && error ? "error" : ""
        }`}
      >
        <div className='common-input-container'>
          {!hideSearch && (
            <span
              className={`search-icon ${
                !inputFocussed && value === "" ? "show-search" : ""
              }`}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
          )}
          {!inputFocussed && (
            <label
              className={`common-input-label ${
                !hideSearch ? "common-shifted-label" : ""
              } ${inputFocussed ? "field-active" : ""}`}
              title={placeholder}
              onClick={handleLabelClick}
            >
              {placeholder}{" "}
              {required && <span className='required-flag'>*</span>}
            </label>
          )}
          {inputFocussed && (
            <legend
              className={`common-input-label ${
                hideSearch ? "shifted-label" : ""
              } field-active`}
            >
              {placeholder}{" "}
              {required && <span className='required-flag'>*</span>}
            </legend>
          )}
          <input
            className={`common-input ${
              type === "date" && !value ? "date-input" : ""
            } `}
            type={type === "date" ? "text" : type}
            value={value as string}
            onChange={onChange}
            checked={checked}
            required={required}
            max={max}
            min={min}
            step={step}
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            pattern={pattern}
            onKeyDown={onKeyDown}
          />
        </div>
        {error && !toolTip && focussedOnce && (
          <div className='error'>
            <img
              src='/assets/icons/error.svg'
              alt={errorMessage}
              className='error'
              title={errorMessage}
            ></img>
          </div>
        )}
        {toolTip && (
          <div className='error'>
            <img
              src='/assets/icons/caution.svg'
              alt={errorMessage}
              className='error'
              title={toolTip || ""}
            ></img>
          </div>
        )}
        {showFilters && (
          <div className='filter-icon' onClick={handleFilterClick}>
            <FontAwesomeIcon icon={faSliders} />
          </div>
        )}
        {/* {
            type==="date" && <div  className = 'filter-icon'>
                <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
        } */}
      </fieldset>
      {showFilterDropdown && (
        <FiltersDropdown
          dropdownFields={filterFields}
          dropdownOptions={filterOptions}
          isMultiSelect={isMultiSelect}
          setDropdownValues={filterHandlers}
          closeFilters={handleFilterClick}
          clearFilters={clearFilters}
          appliedFilters={appliedFilters}
          applyHandler={applyHandler}
        />
      )}
    </div>
  );
}

export default Input;
