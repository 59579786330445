import { ReactNode } from "react"
import { formatDate } from "../../../utils/formatDate"


import "./styles.css"

type TableProps = {
    tableHeaders?:{header:String, type:String}[],
    tableData?:any[]
}


const checkNumber = (value:string)=>{
    return !isNaN(parseInt(value))
}

function Table({tableHeaders,tableData}:TableProps) {

const renderTableHeaders = ()=>{
    return tableHeaders?.map((head)=>{
                return <th>{head.header}</th>        
            }
        
    )
}

const renderTableBody = () =>{
    let tableBody:ReactNode =  tableData?.map((item,idx)=>{
        return <tr className={idx%2===0 ?"odd-table-row":""}>
            <td className="td-number">{idx+1}</td>
            {Object.keys(tableData[0]).map((key)=>{
                if(key.toLowerCase().includes("date"))
                    return <td className="td-number">{formatDate(item[key])}</td>
                else
                    return <td className={`${checkNumber(item[key]) ? 'td-number':'' }`}>{item[key]}</td>
            })}
        </tr>
    })
    if (!tableData)
        tableBody = <div>No items to display</div>
    return tableBody
}

    return (
    <table className="table" >
        <thead>
            {renderTableHeaders()}
        </thead>
        <tbody>
            {renderTableBody()}
        </tbody>
    </table>
  )
}

export default Table