import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { authURL, baseURL, hydraURL, utilURL } from "../constants";

const apiURL = baseURL;
const authenticationURL = authURL;

type BaseURL = "base" | "auth" | "util" | "hydra";

interface ClientOptions extends AxiosRequestConfig {
  baseURL?: BaseURL;
}

type Client = <T = any>(
  url: string,
  options?: ClientOptions
) => Promise<AxiosResponse<T>>;

/**
 * Client to make API Requests
 *
 * @param {String} url API Endpoint
 * @returns {Promise<AxiosResponse<T>>} Axios Response
 */
export const client: Client = (url, { baseURL = "base", ...options } = {}) => {
  const endpoint = getURL(url, baseURL);

  let requestOptions = { ...options };
  if (baseURL !== "auth")
    requestOptions.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")!}`,
    };

  axios.interceptors.response.use(
    (config) => config,
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
        toast.error(error.response.data.message);
      } else {
        return Promise.reject(error);
      }
    }
  );

  const instance = axios({ url: endpoint, ...requestOptions });

  return instance;
};

const getURL = (endpoint: string, baseURL: BaseURL) => {
  if (endpoint.startsWith("/")) {
    switch (baseURL) {
      case "base":
        return `${apiURL}${endpoint}`;
      case "auth":
        return `${authenticationURL}${endpoint}`;
      case "util":
        return `${utilURL}${endpoint}`;
      case "hydra":
        return `${hydraURL}${endpoint}`;
      default:
        break;
    }
  }

  return endpoint;
};
