import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/common/button";
import Checkbox from "../../components/common/checkbox";
import Input from "../../components/common/Input";
import Loader from "../../components/common/loader";
import MultiSelectDropdown from "../../components/common/multiSelectDropdown";
import Pagination from "../../components/common/pagination";
import OrderModal from "../../components/Orders/OrderModal";
import {
  approveOrder,
  approveReExecution,
  assignOrderToUser,
  fetchOrder,
} from "../../services/orders";
import { getAllUsers, getUserByOrg } from "../../services/user";
import { RootState } from "../../store";
import { LocationProps } from "../../typings/common";
import { formatDate } from "../../utils/formatDate";

import "./styles.css";

const tableHeading = [
  "",
  "Deal ID",
  "Project Name",
  "Organisation",
  "Order Date",
  "Order ID",
  "Invoice Number",
  "Status",
  "Assigned To",
  "Order Type",
];

function ViewOrders() {
  const [state, setState] = useState<any>(null);
  const [orders, setOrders] = useState<any>([]);
  const [showOrderModal, setOrderModal] = useState<boolean>(false);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState<number | null>(
    null
  );
  const [orgUsers, setOrgUsers] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedOrders, setSelectedOrders] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filters, setFilters] = useState<any>([[], []]);
  const [metadata, setMetadata] = useState<any>({
    total: 1,
    page: 1,
    limit: 10,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  let location = useLocation() as unknown as LocationProps;

  useEffect(() => {
    fetchOrders();
    fetchUsers();
  }, []);
  const userObject = useSelector((state: RootState) => state.userDetails);
  const navigator = useNavigate();

  const checkRootOrApprover = () => {
    let isRootorApprover = false;
    isRootorApprover =
      location?.state?.role?.includes("approver") ||
      location?.state?.role?.includes("root") ||
      userObject.role.includes("root") ||
      userObject.role.includes("approver");
    return isRootorApprover;
  };

  useEffect(() => {
    setSelectedOrders([]);
    fetchOrders();
  }, [filters]);

  useEffect(() => {
    setSelectedOrders([]);
    setOrders([]);
    fetchOrders(1);
  }, [activeTab]);

  const switchTab = (idx: number) => {
    setOrders([]);
    setActiveTab(idx);
  };

  useEffect(() => {
    console.log(orders, "orders use effect");
  }, [orders]);
  // useEffect(()=>{
  //     let tempOrders = [...orders]

  //     if(searchQuery.length>0){
  //         tempOrders = tempOrders?.filter((order:any)=>{
  //             return (
  //                 order.order_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //                 order.project_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //                 order.org.org_name.toLowerCase().includes(searchQuery.toLowerCase())
  //                 )
  //         })
  //     }

  //     setState(tempOrders)
  // },[searchQuery])

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      fetchOrders();
    }
  };

  const fetchIds = (users: string[]) => {
    const ids = users.map((user: any) => {
      return orgUsers?.find((orgUser: any) => {
        return orgUser.name === user;
      })?.email;
    });
    return ids;
  };

  const showOrderDetails = (idx?: number) => {
    setOrderModal(!showOrderModal);
    setSelectedOrderIndex(idx ?? null);
  };

  const editOrder = () => {
    navigator(`/edit-order/${selectedOrders[0].order_id}`);
  };

  const clearState = () => {
    setState(null);
    setOrders(null);
    setOrderModal(false);
    setSelectedOrderIndex(null);
    setOrgUsers(null);
    setActiveTab(0);
    setSelectedOrders([]);
  };

  const approveUserOrder = async () => {
    selectedOrders.forEach(async (order: any) => {
      const payload = {
        order_id: order.order_id,
      };
      const response = await approveOrder(payload);
    });

    fetchOrders();
    clearState();
  };

  const enableReExecution = async () => {
    selectedOrders.forEach(async (order: any) => {
      if (order.executed) {
        const payload = {
          order_id: order.order_id,
        };
        const response = await approveReExecution(payload);
      }
    });
    fetchOrders();
    clearState();
  };

  const fetchUsers = async () => {
    if (
      !location.state?.role?.includes("root") &&
      !location.state?.role?.includes("requester") &&
      !location.state?.role?.includes("approver")
    ) {
      console.log(location);
      const org_id = location.state?.org_id;

      const response = org_id
        ? await getUserByOrg({ org_id })
        : await getAllUsers();
      setOrgUsers(response);
    } else {
      const response = await getAllUsers();
      setOrgUsers(response);
    }
  };

  const fetchOrders = async (page?: number) => {
    let org_id;
    console.log(location, "location");
    if (location?.state?.org_id) {
      org_id = location.state.org_id;
      if (
        location.state?.role?.includes("approver") ||
        location.state?.role?.includes("root")
      ) {
        org_id = null;
      }
    } else {
      org_id = null;
    }
    let ordersResponse;
    let assigned_to_filter = fetchIds(filters[1]);
    const requestObject: any = {
      org_id,
      page: page ?? currentPage,
      status: activeTab === 0 ? true : false,
      filter: {
        executed: filters[0][0]?.toLowerCase() ?? undefined,
        assigned_to: assigned_to_filter ?? [],
      },
    };
    if (searchQuery.trim().length > 0) {
      requestObject.search = searchQuery;
    }
    setLoading(true);
    ordersResponse = await fetchOrder(requestObject);
    console.log(orders, "orders");
    if (orders !== null) {
      setOrders([...orders!, ...ordersResponse.data]);
    } else {
      setOrders(ordersResponse.data);
    }
    setState(ordersResponse.data);
    setMetadata(ordersResponse.metadata[0]);
    setLoading(false);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (
      page > metadata?.page &&
      page <= Math.ceil(metadata?.total / metadata?.limit) &&
      orders.length < metadata?.total
    )
      fetchOrders(page);
    else {
      let tempOrders = [...orders];
      let tempState = tempOrders.slice(
        (page - 1) * metadata?.limit,
        page * metadata?.limit
      );
      setState(tempState);
    }
  };

  const extractOrgUsers = () => {
    return orgUsers?.map((user: any) => {
      return user.name;
    });
  };

  const assignOrder = async (payload: any) => {
    const response = await assignOrderToUser(payload);
  };

  const handleUserSelect = (e: any, data: string[]) => {
    const assignedUser = orgUsers.find((user: any, idx: number) => {
      if (user.name === data[0]) return true;
    });
    let assignRequestPayload = {
      id: state[+e.target.id]?.order_id ?? state[+e.target.id]?.id,
      assigned_to: { ...assignedUser },
    };
    assignOrder(assignRequestPayload);
    fetchOrders();
  };

  const viewOrderDetails = (idx: number) => {
    showOrderDetails(idx);
  };

  const handleCheckboxSelect = (e: any, executed: boolean) => {
    e.stopPropagation();
    let tempOrders = [...selectedOrders];
    if (e.target.checked) {
      const approvedStatus = activeTab === 0 ? true : false;
      tempOrders.push({
        order_id: e.target.value,
        executed: executed,
        approved: approvedStatus,
      });
    } else {
      tempOrders = tempOrders.filter((order: any) => {
        return order.order_id !== e.target.value;
      });
    }
    setSelectedOrders(tempOrders);
  };
  const handleCheckboxCellClick = (e: any) => {
    e.stopPropagation();
  };

  const getCheckedStatus = (order_id: string) => {
    let checked = false;
    selectedOrders.forEach((order: any) => {
      if (order.order_id === order_id) checked = true;
    });

    return checked;
  };

  const renderAssignedTo = (order: any, idx: number) => {
    if (location?.state?.role?.includes("admin")) {
      return (
        <MultiSelectDropdown
          placeholder={"Assign To"}
          changeHandler={handleUserSelect}
          multiselect={false}
          options={extractOrgUsers()}
          selectedValues={
            order?.assigned_to?.name ? [order?.assigned_to?.name] : undefined
          }
          id={idx.toString()}
        />
      );
    } else {
      return order?.assigned_to?.name || "Unassigned";
    }
  };

  const renderActionTypes = () => {
    let actions = null;

    if (activeTab === 1 && checkRootOrApprover()) {
      actions = (
        <div className='actions-tray'>
          <Button
            onClick={() => approveUserOrder()}
            disabled={checkApprovedDiabled()}
          >
            Approve Order
          </Button>
          <Button onClick={() => editOrder()} disabled={checkEditDisabled()}>
            Edit Order
          </Button>
        </div>
      );
    }
    if (activeTab === 0 && checkRootOrApprover()) {
      actions = (
        <div className='actions-tray'>
          <Button
            onClick={() => enableReExecution()}
            disabled={checkExecutedDisabled()}
            title='Please select orders that are not executed'
            type='primary'
          >
            Enable Re-execution
          </Button>
        </div>
      );
    }

    return actions;
  };
  const handleOrderIdCopy = (e: any) => {
    e.stopPropagation();
  };

  const fetchTableRow = (order: any, idx: number) => {
    return (
      <tr
        onClick={(e) => viewOrderDetails(idx)}
        className={`order-table-row ${
          getCheckedStatus(order.order_id || order.id) ? "selected-row" : ""
        }`}
      >
        <td onClick={handleCheckboxCellClick}>
          <Checkbox
            name={order.project_title}
            value={order.order_id || order.id}
            handleChange={(e) => handleCheckboxSelect(e, order?.executed)}
            checked={getCheckedStatus(order.order_id || order.id)}
          />
        </td>
        <td>{order.deal_id}</td>
        <td>{order.project_title}</td>

        <td>{order?.org?.org_name}</td>
        <td>{formatDate(order?.order_date)}</td>

        <td onClick={handleOrderIdCopy}>{order.order_id}</td>
        <td>{order.invoice_number}</td>
        {activeTab === 0 && (
          <td className={`${order.executed ? "success-text" : "failure-text"}`}>
            {order.executed ? "Executed" : "Not Executed"}
          </td>
        )}

        <td onClick={handleOrderIdCopy}>{renderAssignedTo(order, idx)}</td>

        <td>{order?.is_hydra_order ? "Hydra" : "Appstack"}</td>
      </tr>
    );
  };

  const checkApprovedDiabled = () => {
    let disabled = true;
    selectedOrders.forEach((order: any) => {
      if (!order.approved) disabled = false;
    });
    return disabled;
  };

  const checkEditDisabled = () => {
    let disabled = true;
    selectedOrders.length > 0 && selectedOrders.length < 2
      ? (disabled = false)
      : (disabled = true);
    return disabled;
  };

  const checkExecutedDisabled = () => {
    let disabled = true;
    if (selectedOrders.length > 0)
      disabled = !selectedOrders.every((order: any) => {
        return order.executed === true;
      });
    return disabled;
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
  };
  const handleStatusFilter = (e: any, data: string[]) => {
    let tempFilter = [...filters];
    if (data.length > 0) {
      tempFilter[0] = data;
    } else {
      tempFilter[0] = [];
    }
    setOrders([]);
    setFilters(tempFilter);
  };
  const handleAssignedToFilter = (e: any, data: string[]) => {
    let tempFilter = [...filters];
    tempFilter[1] = data;
    setOrders([]);
    setFilters(tempFilter);
  };

  const clearFilters = () => {
    setSearchQuery("");
    setOrders([]);
    setFilters([[], []]);
  };
  const removeFilterHandler = (index: number, fieldName: string) => {
    let tempFilterState = [...filters];
    if (fieldName === "industry") {
      tempFilterState[0].splice(index, 1);
    } else {
      tempFilterState[1].splice(index, 1);
    }
    setOrders([]);
    setFilters(tempFilterState);
  };
  return (
    <>
      <div className='view-orders-container'>
        {!showOrderModal && (
          <div className='order-table-container'>
            <div className='order-navigation-panel'>
              <div
                className={activeTab === 0 ? "navigation-panel-active" : ""}
                onClick={() => switchTab(0)}
              >
                Approved
              </div>
              <div
                className={activeTab === 1 ? "navigation-panel-active" : ""}
                onClick={() => switchTab(1)}
              >
                Pending
              </div>
            </div>
            <div className='order-search-container'>
              <Input
                type='text'
                placeholder='Search'
                value={searchQuery}
                onChange={handleInputChange}
                showFilters
                filterFields={["Execution Status", "Assigned To"]}
                filterOptions={[["True", "False"], extractOrgUsers()]}
                filterHandlers={[handleStatusFilter, handleAssignedToFilter]}
                clearFilters={clearFilters}
                appliedFilters={filters}
                isMultiSelect={[false, true]}
                onKeyDown={(e: any) => handleSearch(e)}
              />
              <div className='order-header-actions'>{renderActionTypes()}</div>
            </div>
            <div className='selected-filter-chips order-chip'>
              {filters[0].length > 0 &&
                filters[0].map((item: any, idx: number) => {
                  return (
                    <div className='filter-chip'>
                      {item}
                      <span
                        className='close-icon'
                        onClick={(e) => removeFilterHandler(idx, "industry")}
                      >
                        x
                      </span>
                    </div>
                  );
                })}
              {filters[1].length > 0 &&
                filters[1].map((item: any, idx: number) => {
                  return (
                    <div className='filter-chip'>
                      {item}
                      <span
                        className='close-icon'
                        onClick={(e) => removeFilterHandler(idx, "developer")}
                      >
                        x
                      </span>
                    </div>
                  );
                })}
            </div>
            {!loading && (
              <div>
                {state?.length > 0 && (
                  <table className='table'>
                    <thead>
                      {tableHeading?.map((heading) => {
                        if (activeTab === 1 && heading === "Status") {
                          return null;
                        }
                        return <th>{heading}</th>;
                      })}
                    </thead>

                    <tbody>
                      {state?.map((order: any, idx: number) => {
                        return fetchTableRow(order, idx);
                      })}
                    </tbody>
                  </table>
                )}
                {state?.length === 0 && (
                  <div className='no-data-container'>
                    <div className='no-data-text'>No Orders Found</div>
                  </div>
                )}
                <Pagination
                  currentPage={metadata?.page ?? 1}
                  maxPages={Math.ceil(metadata?.total / metadata?.limit ?? 1)}
                  handlePageChange={handlePageChange}
                ></Pagination>
              </div>
            )}
            {loading && (
              <div className='loader-container'>
                <Loader height={200} width={200} />
              </div>
            )}
          </div>
        )}
        {showOrderModal && (
          <OrderModal
            data={state[selectedOrderIndex!]}
            toggleModal={showOrderDetails}
            show={showOrderModal}
          />
        )}
      </div>
    </>
  );
}
export default ViewOrders;
