export interface AppConfig {
  version: number;
  appConfig: any;
  srcConfig: any;
}

export interface DemoVideo {
  video: string;
  config: AppConfig;
}

export interface IAppRegistration {
  awi_name: string;
  awi_app_name: String;
  awi_app_version: String;
  industry_description: String;
  awi_description: String;
  awi_app_assumption: String;
  awi_developer_firstname: String;
  awi_developer_lastname: String;
  awi_aggreement_accepted: Boolean;
  awi_max_mount_height: number | null;
  awi_sample_fov_image: String[];
  awi_gpu_required: Boolean;
  awi_nvdia_deepstream: Boolean;
  awi_intel_openvino: Boolean;
  awi_nvidia_tensorrt: Boolean;
  awi_varifocal_camera: Boolean;
  awi_handheld_compatible: Boolean;
  awi_ptz_compatible: Boolean;
  awi_app_logic: String;
  awi_test_results: String;
  awi_org_id: Number[];
  awi_model_information: {
    name: String;
    type: String;
    version: String;
    architecture: String;
  }[];
  awi_private: Boolean;
  color: string;
  app_id: number;
  awi_app_author: String;
  awi_target_industries: String[];
  industry_uses: {
    awi_name: String;
    awi_description: String;
  }[];
  awi_developer_image: String;
  awi_app_logo: String;
  awi_credits: number;
  awi_supported_acceleration: String[];
  awi_resolution: String[];
  awi_ideal_fov: String[];
  awi_focal_length: number;
  awi_fps: number;
  awi_ideal_lighting: String[];

  awi_sample_images: String[];
  awi_ppm: {
    ppm_value: number | null;
  };
  awi_flowchart: String;
  awi_demo_video: String[];
  awi_cer: number;
  awi_app_developer: String;
  awi_app_developer_id: String;
  awi_so_file_name: String;
  awi_so_file_path: String;
  awi_docker_image_name: String;
  awi_major_version: number | null;
  awi_minor_version: number | null;
  awi_patch_version: number | null;
  awi_release_note: String;
  awi_version_upgrade_type: string;
  awi_credits_burn_value: number;
  demoVideoUpload: string[];
  demoVideos: DemoVideo[];
  config: AppConfig;
}

export interface IAppRegistrationError {
  color: boolean | null;
  awi_name: boolean | null;
  awi_app_name: boolean | null;
  awi_app_author: boolean | null;
  awi_app_version: boolean | null;
  awi_target_industries: boolean | null;
  awi_description: boolean | null;
  awi_developer_image: boolean | null;
  awi_app_logo: boolean | null;
  awi_credits: boolean | null;
  awi_supported_acceleration: boolean | null;
  awi_gpu_required: boolean | null;
  awi_model_information: boolean | null;
  awi_resolution: boolean | null;
  awi_max_mount_height: boolean | null;
  awi_ideal_fov: boolean | null;
  awi_varifocal_camera: boolean | null;
  awi_focal_length: boolean | null;
  awi_fps: boolean | null;
  awi_ideal_lighting: boolean | null;
  awi_ptz_compatible: boolean | null;
  awi_handheld_compatible: boolean | null;
  awi_sample_images: boolean | null;
  awi_ppm: boolean | null;
  awi_app_logic: boolean | null;
  awi_app_assumption: boolean | null;
  awi_flowchart: boolean | null;
  awi_demo_video: boolean | null;
  awi_cer: boolean | null;
  awi_app_developer: boolean | null;
  awi_sample_fov_image: boolean | null;
}

export const IAppRegistrationExample: IAppRegistration = {
  awi_name: "",
  awi_app_name: "",
  industry_description: "",
  awi_description: "",
  awi_app_assumption: "",
  awi_developer_firstname: "",
  awi_developer_lastname: "",
  awi_aggreement_accepted: false,
  awi_max_mount_height: 0,
  awi_sample_fov_image: [],
  awi_gpu_required: false,
  awi_nvdia_deepstream: false,
  awi_intel_openvino: false,
  awi_nvidia_tensorrt: false,
  awi_varifocal_camera: false,
  awi_handheld_compatible: false,
  awi_ptz_compatible: false,
  awi_app_logic: "",
  awi_test_results: "",
  awi_private: false,
  awi_org_id: [],
  app_id: 0,
  awi_app_author: "",
  awi_app_version: "",
  awi_target_industries: [],
  industry_uses: [
    {
      awi_name: "",
      awi_description: "",
    },
  ],

  awi_developer_image: "",
  awi_app_logo: "",
  awi_credits: 0,
  awi_supported_acceleration: [],
  awi_model_information: [
    {
      name: "",
      type: "",
      version: "",
      architecture: "",
    },
    {
      name: "",
      type: "",
      version: "",
      architecture: "",
    },
  ],
  awi_resolution: [],
  awi_ideal_fov: [],
  awi_focal_length: 0,
  awi_fps: 0,
  awi_ideal_lighting: [],
  awi_sample_images: [],
  awi_ppm: {
    ppm_value: null,
  },
  awi_flowchart: "",
  awi_demo_video: [],
  awi_cer: 0,
  awi_app_developer: "",
  awi_app_developer_id: "",
  color: "",
  awi_so_file_name: "",
  awi_so_file_path: "/home/awiros-docker/awiros-apps/build/libs/",
  awi_docker_image_name: "",
  awi_major_version: null,
  awi_minor_version: null,
  awi_patch_version: null,
  awi_release_note: "",
  awi_version_upgrade_type: "",
  awi_credits_burn_value: 10,
  demoVideoUpload: [],
  demoVideos: [],
  config: {
    version: 1,
    appConfig: {},
    srcConfig: {},
  },
};

export const IAppRegistrationErrorExample: IAppRegistrationError = {
  awi_name: null,
  awi_app_name: null,
  awi_app_author: null,
  awi_app_version: null,
  awi_target_industries: null,
  awi_description: null,
  awi_developer_image: null,
  awi_app_logo: null,
  awi_credits: null,
  awi_supported_acceleration: null,
  awi_gpu_required: null,
  awi_model_information: null,
  awi_resolution: null,
  awi_max_mount_height: null,
  awi_ideal_fov: null,
  awi_varifocal_camera: null,
  awi_focal_length: null,
  awi_fps: null,
  awi_ideal_lighting: null,
  awi_ptz_compatible: null,
  awi_handheld_compatible: null,
  awi_sample_images: null,
  awi_ppm: null,
  awi_app_logic: null,
  awi_app_assumption: null,
  awi_flowchart: null,
  awi_demo_video: null,
  awi_cer: null,
  awi_app_developer: null,
  awi_sample_fov_image: null,
  color: null,
};
