function OrderDetails({data}:any) {
  return (
    <>
        <h2 className='org-modal-section-header'>
            Order Details
        </h2>
        <div className='order-modal-section-body'>
        <div className='order-modal-content-row'>
            <label>
                Deal ID
            </label>
            <p>
                {data?.deal_id}
            </p>
            <label>
                PO Number
            </label>
            <p>
                {data?.order_number}
            </p>
            <label>
                Quotation Number
            </label>
            <p>
                {data?.quotation_number}
            </p>
            <label>
                Invoice Number
            </label>
            <p>
                {data?.invoice_number}
            </p>
            <label>
                Invoice Billed (Location)
            </label>
            <p>
                {data?.site_location}
            </p>
        </div>
        </div>
    </>
  )
}

export default OrderDetails
