import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setStatus } from "../../appStatusSlice";
import Button from "../../components/common/button";
import Image from "../../components/common/image";
import Modal from "../../components/common/modal";
import MultiSelectDropdown from "../../components/common/multiSelectDropdown";
import Textarea from "../../components/common/textarea";
import StatusToggle from "../../components/StatusToggle";
import { IAppStoreData } from "../../interfaces/appStore";
import {
  getAppFromID,
  getAppStatus,
  getAppStore,
  publishApp,
  updateAppStatus,
} from "../../services/appStore";
import { RootState } from "../../store";
import { AppStatusState } from "../../typings/appStack";
import {
  getStatusObjectFromDisplay,
  parseVersion,
  renderDisplayStatus,
} from "../../utils/appstackCard";
import "./styles.css";

function AppDescription() {
  let location = useLocation();
  let stateLocation = location.state;
  const [state, setState] = useState<any>(stateLocation);
  const [publishComment, setPublishComment] = useState<string>("");
  const [appStack, setAppStack] = useState<IAppStoreData[] | undefined>();
  const [showPublishModal, setPublishModal] = useState<boolean>(false);

  const appStatusOptions = useSelector(
    (state: RootState) => state?.appStatus
  ).displayStatus;

  useEffect(() => {
    let tempOptions = appStatusOptions.filter((option) => {
      if (option.value !== 3) {
        return option;
      }
      return null;
    });
    if (tempOptions.length > 0) {
      setOptionsToDisplay(tempOptions);
    }
  }, [appStatusOptions]);

  const [optionsToDisplay, setOptionsToDisplay] = useState<AppStatusState[]>(
    []
  );

  // const [appOwner, setAppOwner] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [tempAppStatus, setTempAppStatus] = useState<string>("");

  const navigate = useNavigate();

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const dispatch = useDispatch();

  const fetchAppStatus = useCallback(async () => {
    let appOptions = await getAppStatus(true);
    if (appOptions) {
      dispatch(setStatus(appOptions));
    }
  }, [dispatch]);

  const fetchAppData = useCallback(async () => {
    let appId;
    if (state?.id) {
      appId = state?.id;
    } else {
      appId = location.search.split("=")[1];
    }
    const appResponse = await getAppFromID(appId);
    if (appResponse) {
      setState(appResponse);
    }
  }, [location.search, state?.id]);

  const getAppStackData = useCallback(async () => {
    let tempAppStackData = await getAppStore();
    const filtered_apps = tempAppStackData.filter(
      (item: { awi_name: String }) => item.awi_name !== state?.awi_name
    );
    let shuffled = filtered_apps.sort(function () {
      return 0.5 - Math.random();
    });
    let selected = shuffled.slice(0, 10);

    setAppStack(selected);
    return tempAppStackData;
  }, [state?.awi_name]);

  useEffect(() => {
    if (optionsToDisplay.length === 0) {
      fetchAppStatus();
    }
  }, [optionsToDisplay, fetchAppStatus]);

  useEffect(() => {
    if (stateLocation) {
      setState(stateLocation);
    } else {
      fetchAppData();
    }
  }, [stateLocation, fetchAppData]);

  useEffect(() => {
    document.body.scrollTo(0, 0);
    getAppStackData();
  }, [state, getAppStackData]);

  const userObject = useSelector((state: RootState) => state?.userDetails);
  const parsedToken = useSelector((state: RootState) => state?.isLoggedIn).role
    .id;

  const isAppOwner =
    (state.awi_app_developer_id &&
      state?.awi_app_developer_id === parsedToken) ||
    state?.awi_app_developer === userObject.name ||
    userObject.role.includes("root");

  // const downloadFlowchart = async () => {
  //   const element = document.createElement("a");
  //   const extension = state?.awi_flowchart.split(".").at(-1);
  //   const file = await fetch(state?.awi_flowchart);
  //   const imageBlob = await file.blob();
  //   element.href = URL.createObjectURL(imageBlob);
  //   element.download = `${state?.awi_name || state?.awi_app_name}.${extension}`;
  //   document.body.appendChild(element);
  //   element.click();
  // };

  const navigateToApp = (app: any) => {
    // scrollToTop()
    navigate(
      {
        pathname: "/config/description",
        search: `?id=${app.id}`,
      },
      {
        state: app,
      }
    );
  };

  const navigateToEditApp = () => {
    navigate("/register-app", {
      state: {
        ...state,
        editApp: true,
      },
    });
  };

  const fetchAppStatusOptions = (appData: { key: string; value: number }[]) => {
    return appData?.map((app) => app.key);
  };

  const handleOptionChange = (event: any, data: string[]) => {
    console.log(data);
    setTempAppStatus(data[0]);
    toggleModal();
  };

  const renderAppStatusEditDropdown = () => {
    let appStatusDropDown = (
      <MultiSelectDropdown
        placeholder={renderDisplayStatus(state?.awi_app_status.value)}
        selectedValues={[renderDisplayStatus(state?.awi_app_status.value)]}
        options={fetchAppStatusOptions(optionsToDisplay)}
        changeHandler={handleOptionChange}
        multiselect={false}
        hideChip
        showSelectedOptions={false}
      />
    );
    return appStatusDropDown;
  };

  const updateAppStatusHandler = useCallback(async () => {
    let appStatusUpdateObject = getStatusObjectFromDisplay(tempAppStatus);
    const response = await updateAppStatus(
      state?.id,
      appStatusUpdateObject.key
    );
    if (response) {
      toast.success("App status updated successfully");
      fetchAppData();
    }
  }, [tempAppStatus, state?.id, fetchAppData]);

  const handleAppStatusChange = useCallback(
    (update?: boolean) => {
      if (update) {
        updateAppStatusHandler();
      }
      toggleModal();
    },
    [updateAppStatusHandler, toggleModal]
  );

  const handlePublishApp = useCallback(
    async (publish?: boolean) => {
      if (publishComment.trim().length === 0) {
        toast.error("Please add a comment");
        return;
      }
      if (publish) {
        if (state.id) publishApp(state.id, true, publishComment.trim());
      } else {
        await publishApp(state.id, false, publishComment.trim());
      }
      setPublishModal(false);
    },
    [publishComment, state.id]
  );

  const confirmModalContent = useCallback(() => {
    return (
      <div className='confirm-modal-container'>
        <h3>Confirm App Status Change</h3>
        <p>
          Are you sure you want to change the status from{" "}
          <strong>
            {renderDisplayStatus(state?.awi_app_status.value).toUpperCase()}
          </strong>{" "}
          to <strong>{tempAppStatus.toUpperCase()}</strong>
        </p>
        <div className='confirm-modal-actions'>
          <Button type='primary' onClick={(e) => handleAppStatusChange(true)}>
            Yes
          </Button>
          <Button onClick={(e) => handleAppStatusChange()}>No</Button>
        </div>
      </div>
    );
  }, [handleAppStatusChange, state?.awi_app_status.value, tempAppStatus]);

  const handlePublishCommentChange = useCallback((e: any) => {
    setPublishComment(e.target.value);
  }, []);

  const renderPublishModal = useCallback(() => {
    return (
      <div className='confirm-modal-container'>
        <h3>Publish Application</h3>
        <div>
          <p>
            {" "}
            Are you sure you want to publish {state?.awi_name} to the App Stack?
          </p>
          <Textarea
            value={publishComment}
            onChange={handlePublishCommentChange}
            placeholder='Add a comment here'
          ></Textarea>
        </div>
        <div className='confirm-modal-actions'>
          <Button type='primary' onClick={(e) => handlePublishApp(true)}>
            Publish
          </Button>
          <Button onClick={(e) => handlePublishApp(false)}>Reject</Button>
          <Button onClick={(e) => setPublishModal(false)}>No</Button>
        </div>
      </div>
    );
  }, [
    handlePublishApp,
    handlePublishCommentChange,
    publishComment,
    state?.awi_name,
  ]);

  const isAdmin = useMemo(
    () =>
      userObject?.role?.includes("root") || userObject?.role?.includes("admin"),
    [userObject?.role]
  );

  useEffect(() => {
    fetchAppData();
  }, [fetchAppData]);

  const registerAppVersion = () => {
    navigate("/register-app", {
      state: {
        registerAppVersion: true,
        awi_name: state?.awi_name,
        id: state?.id,
      },
    });
  };

  return (
    <>
      <div className='app-description-container'>
        {isAdmin && (
          <StatusToggle isEnabled={state?.is_hydra_enabled} appId={state?.id} />
        )}
        <div className='app-description-header'>
          <div className='app-description-icon'>
            <img
              src={state?.awi_app_logo}
              alt={state?.awi_name || state?.awi_app_name}
            />
          </div>
          <div className='app-description-heading'>
            <div className='app-description-heading-content'>
              <h1 className='app-description-title-text'>
                {state?.awi_name || state?.awi_app_name}
              </h1>
              <div className='app-description-likes'>
                <div className='app-action-icons'>
                  <img
                    src='/assets/icons/file-plus.svg'
                    alt='like'
                    title='Publish App'
                    onClick={registerAppVersion}
                    className='app-action-icon-image'
                  ></img>
                </div>
                {/* <FontAwesomeIcon
                  icon={faPlus}
                  title="Publish App"
                  onClick={registerAppVersion}
                  className="app-action-icons"
                /> */}

                {userObject?.role.includes("root") && (
                  <div className='app-action-icons'>
                    <FontAwesomeIcon
                      icon={faCheck}
                      onClick={() => setPublishModal(true)}
                      title='Publish App'
                      className='app-action-icon-image'
                    />
                  </div>
                )}
                {isAppOwner && (
                  <div className='app-action-icons'>
                    <img
                      src='/assets/icons/edit.svg'
                      alt='like'
                      title='Publish App'
                      onClick={navigateToEditApp}
                      className='app-action-icon-image'
                    ></img>
                  </div>
                )}
                {isAppOwner && renderAppStatusEditDropdown()}
              </div>
            </div>
            <div className='app-description-heading-content'>
              <span className='app-title-description'>
                {state?.awi_description}
              </span>
            </div>
            <div className='app-description-heading-content'>
              <img
                className='app-developer-image'
                src={state?.awi_developer_image}
                alt={state?.awi_app_developer}
              ></img>

              <div className='credits-container'>
                <span>Developed by {state?.awi_app_developer}</span>
                {state?.awi_credits_burn_value && (
                  <div className='credit-burn-rate-container'>
                    {state?.awi_credits_burn_value} credits/
                    {state?.awi_credit_rate}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='app-description-sample-images-container'>
          <h2 className='app-description-information-heading'>
            Sample Images & Videos
          </h2>
          <div className='app-description-images'>
            {state?.awi_sample_images.map((image: string, i: number) => {
              return (
                <div>
                  <Image
                    src={state?.awi_sample_images}
                    idx={i}
                    alt={state?.awi_name}
                    expandOnClick
                  />
                </div>
              );
            })}
            {state?.awi_demo_video.map((video: string, i: number) => {
              return (
                <video controls>
                  <source src={video}></source>
                </video>
              );
            })}
          </div>
        </div>
        <div className='app-description-section'>
          <h2 className='app-description-information-heading'>
            General Information
          </h2>
          <div className='app-information-container'>
            <div className='app-information-content'>
              <span>Author of App</span>
              <span>{state?.awi_app_author}</span>
            </div>

            <div className='app-information-content'>
              <span>App ID</span>
              <span>{state?.id}</span>
            </div>
            <div className='app-information-content'>
              <span>App Version</span>
              <span>{parseVersion(state?.awi_app_version)}</span>
            </div>

            <div className='app-information-content'>
              <span>SO File Name</span>
              <span>{state?.awi_so_file_name}</span>
            </div>
            <div className='app-information-content'>
              <span>SO File Path</span>
              <span>{state?.awi_so_file_path}</span>
            </div>
            <div className='app-information-content full-length-description'>
              <span>Target Industries:</span>
              <div className='target-industries-list'>
                {state?.awi_target_industries.map((industry: any) => {
                  return (
                    <div
                      className='target-industry-list-item'
                      title={industry.awi_name}
                    >
                      {industry.awi_name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className='app-description-section'>
          <h2 className='app-description-information-heading'>
            Hardware Information
          </h2>
          <div className='app-information-container'>
            <div className='app-information-content'>
              <span>Credits: </span>
              <span>{state?.awi_credits}</span>
            </div>
            <div className='app-information-content'>
              <span>Supported Accleration Modes </span>
              <span>{state?.awi_supported_acceleration}</span>
            </div>
            <div className='app-information-content'>
              <span>GPU Requried </span>
              <span>{state?.awi_gpu_required ? "Yes" : "No"}</span>
            </div>
          </div>
        </div>
        {/* <div className="app-description-section">
                <h2 className="app-description-information-heading">
                    AI Model Information
                </h2>
                <div className="model-information-container">
                    
                    {
                        state?.awi_model_information.map((model:any,idx:number)=>{
                            let heading
                            if(idx===0){
                                heading = (
                                    <h4 className="ai-model-heading no-border">
                                        Primary Model:
                                    </h4>
                                )
                            }
                            if (idx===1){
                                heading = (
                                    <h4 className="ai-model-heading no-border">
                                        Secondary Model:
                                    </h4>
                                )
                            }
                            if(idx===2){
                                heading = (
                                    <h4 className="ai-model-heading no-border">
                                        Other Model:
                                    </h4>
                                )
                            }
                            return(
                                <div >
                                    {heading}
                                    <div className="app-information-container">
                                    <div className="app-information-content">
                                        <span>Name of the model </span>
                                        <span>{model.name}</span>
                                    </div>
                                    <div className="app-information-content">
                                        <span>Type (Architecture) </span>
                                        <span>{model.type}</span>
                                    </div>
                                    <div className="app-information-content">
                                        <span>Version </span>
                                        <span>{model.version}</span>
                                    </div>
                                    </div>
                                </div>
                            )

                        })
                    }
                </div>
                </div> */}
        <div className='app-description-section'>
          <h2 className='app-description-information-heading'>
            Field of View Consideration
          </h2>
          <div className='app-information-container'>
            <div className='app-information-content'>
              <span>Resolution</span>
              <span>{state?.awi_resolution}</span>
            </div>
            <div className='app-information-content'>
              <span>Maximum mount height (in m)</span>
              <span>{state?.awi_max_mount_height}</span>
            </div>
            <div className='app-information-content'>
              <span>Ideal Field of View</span>
              <div>{state?.awi_ideal_fov.map((fov: string) => fov + " ")}</div>
            </div>
            <div className='app-information-content'>
              <span>FPS</span>
              <span>{state?.awi_fps}</span>
            </div>
            <div className='app-information-content'>
              <span>Typical Focal Length</span>
              <span>{state?.awi_focal_length} mm</span>
            </div>
            <div className='app-information-content'>
              <span>PPM (Pixel Per meter)</span>
              <span>{state?.awi_ppm.ppm_value}</span>
            </div>
            <div className='app-information-content'>
              <span>Varifocal Camera Required</span>
              <span>{state?.awi_varifocal_camera}</span>
            </div>

            <div className='app-information-content'>
              <span>PTZ Compatible</span>
              <span>{state?.awi_ptz_compatible ? "Yes" : "No"} </span>
            </div>
            <div className='app-information-content'>
              <span>Hand-held Camera Compatible</span>
              <span>{state?.awi_handheld_compatible ? "Yes" : "No"}</span>
            </div>
            <div className='app-information-content'>
              <span>Ideal Lighting</span>
              <span>
                {state?.awi_ideal_lighting.map(
                  (lighting: string) => lighting + " "
                )}{" "}
              </span>
            </div>
            <div className='app-description-two-column'>
              <span>Examples of FOV</span>
              <span>PPM (Image from JVSG tool)</span>
            </div>
            <div className='app-information-content half-length-description'>
              <div className='fov-example-tray'>
                <div className='fov-image-wrapper'>
                  {state?.awi_sample_fov_image.map(
                    (image: string, idx: number) => {
                      return (
                        <Image
                          src={image}
                          idx={idx}
                          alt={"sample fov images"}
                          expandOnClick
                        ></Image>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className='app-information-content'>
              <Image
                src={state?.awi_ppm.ppm_image}
                alt={"ppm example"}
                expandOnClick
              ></Image>
            </div>
          </div>
          <div className='app-information-container'></div>
        </div>
        <div className='app-description-section'>
          <h2 className='app-description-information-heading'>
            App Description
          </h2>

          <div className='app-information-container'>
            <div className='app-description-content'>
              <h4>App Assumptions:</h4>
              <p className='app-description-assumption'>
                {state?.awi_app_assumption}
              </p>
            </div>
          </div>

          <div className='app-information-container'>
            <div className='app-description-content'>
              <h4>App Logic:</h4>
              <p className='app-description-assumption'>
                {state?.awi_app_logic}
              </p>
            </div>
          </div>

          <div className='app-information-container'>
            <div className='app-description-content'>
              <h4>CER(Cross Environment Readiness):</h4>
              <p className='app-description-assumption'>{state?.awi_cer}</p>
            </div>
            {/* <div className="app-information-content">
                        <span>App Flowchart:</span>
                        
                        <button onClick={downloadFlowchart}>
                        <FontAwesomeIcon icon={faDownload}/>
                        
                        </button>
                        <a href={state?.awi_flowchart} target='_blank' rel="noreferrer">
                        <button >
                           
                        <FontAwesomeIcon icon={faFile}/>
                        
                        </button>
                         </a>
                    </div>                     */}
          </div>
        </div>
        <div className='app-description-section'>
          <h2 className='app-description-information-heading'>Popular Apps</h2>
          <div className='app-information-container full-length-description'>
            <div className='popular-apps '>
              {appStack?.map((app) => {
                return (
                  <div onClick={(e) => navigateToApp(app)}>
                    <img
                      src={app.awi_app_logo}
                      alt={app.awi_name}
                      width='70px'
                      height={"70px"}
                    />
                    <span className='popular-app-name'>{app.awi_name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className='app-status-change-modal'
        onClose={toggleModal}
        open={showModal}
      >
        {confirmModalContent()}
      </Modal>
      <Modal
        className='app-status-publish-modal'
        onClose={() => setPublishModal(false)}
        open={showPublishModal}
      >
        {renderPublishModal()}
      </Modal>
    </>
  );
}

export default AppDescription;
