import React, { useEffect } from "react";

type closeModalArgument = () => void;

const useOutsideAlerter = (
    ref: React.RefObject<HTMLDivElement>,
    closeModal: closeModalArgument
) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: { target: any }) {
            if (ref.current && !ref.current.contains(event.target)) {
                // alert("You clicked outside of me!");
                closeModal();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [closeModal, ref]);
};

export default useOutsideAlerter;
