// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.required-mark {
    color: red;
    font-size: 15px;
}
.label-header {
    display: flex;
    font-size: 0.875rem;    
}
.label-header-top {
    display: flex;
    font-size: 0.875rem;
    flex-direction: column;
    gap: 0.625rem;
}
.form-input-label{
    color: var(--primary-color) !important;
}
textarea{
    color: var(--primary-color) !important;
    padding: 0.625rem;
    outline: none;
    border: 0px;
    border: 1px solid var(--secondary-color);

}
.ui.input.form-input{

}
.ui.input.form-input>input{
    background-color: var(--theme-color);
    border: 0px;
    border-bottom: 1px solid var(--secondary-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/common/formInput/styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,sCAAsC;AAC1C;AACA;IACI,sCAAsC;IACtC,iBAAiB;IACjB,aAAa;IACb,WAAW;IACX,wCAAwC;;AAE5C;AACA;;AAEA;AACA;IACI,oCAAoC;IACpC,WAAW;IACX,+CAA+C;AACnD","sourcesContent":[".required-mark {\n    color: red;\n    font-size: 15px;\n}\n.label-header {\n    display: flex;\n    font-size: 0.875rem;    \n}\n.label-header-top {\n    display: flex;\n    font-size: 0.875rem;\n    flex-direction: column;\n    gap: 0.625rem;\n}\n.form-input-label{\n    color: var(--primary-color) !important;\n}\ntextarea{\n    color: var(--primary-color) !important;\n    padding: 0.625rem;\n    outline: none;\n    border: 0px;\n    border: 1px solid var(--secondary-color);\n\n}\n.ui.input.form-input{\n\n}\n.ui.input.form-input>input{\n    background-color: var(--theme-color);\n    border: 0px;\n    border-bottom: 1px solid var(--secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
