import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type formNavigationProps = {
  multipartStage: number;
  handleMultipartStageChange: (stage: number) => void;
};

function FormNavigation({
  multipartStage,
  handleMultipartStageChange,
}: formNavigationProps) {
  return (
    <div
      className="multipart-registration-navigation"
      style={{ borderRadius: "8px" }}
    >
      <div
        className={`multipart-registration-item 
							${multipartStage >= 1 ? "multipart-registration-item-highlight" : ""}`}
        onClick={() => handleMultipartStageChange(1)}
      >
        General Information
      </div>

      <div
        className={`multipart-registration-item 
							${multipartStage >= 2 ? "multipart-registration-item-highlight" : ""}`}
        onClick={() => handleMultipartStageChange(2)}
      >
        App Information
      </div>
      <div
        className={`multipart-registration-item 
							${multipartStage >= 3 ? "multipart-registration-item-highlight" : ""}`}
        onClick={() => handleMultipartStageChange(3)}
      >
        App Config Information
      </div>
      <div
        className={`multipart-registration-item 
							${multipartStage >= 4 ? "multipart-registration-item-highlight" : ""}`}
        onClick={() => handleMultipartStageChange(4)}
      >
        Model Information
      </div>
    </div>
  );
}

export default FormNavigation;
