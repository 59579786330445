export const AppStore = {
    data: [
        {
            _id: "5d80bd01aed82f0d25bc0616",
            awi_app_credits: 100,
            industry_description:
                "Awiros Advanced Intrusion allows a user to define a ‘virtual fence’ of any shape and dimensions within the camera’s view.\nThe app also uses an object classifier to identify the object that has caused the alert.",
            description:
                "Detect activity across a user-defined boundary or perimeter.",
            color: "#DD3CFD",
            fov_angle: 1,
            v_angle: 1,
            RAM: 1,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/location.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 564.png",
            icon: "http://192.168.0.3:3000/uploads/Group 564.png",
            awi_app_id: 107,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_advanced_intrusion",
            awi_app: "awi_advanced_intrusion",
            awi_name: "Advanced Intrusion",
            id: 49052,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-16-15h34m44s578.png",
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-16-15h23m37s591.png",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG"
            ],
            dislikes: 0,
            likes: 220,
            updated: "2019-09-17T11:01:21.321Z",
            timestamp: "2019-09-17T11:01:21.320Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d95eaed82f0d25bc0626",
            awi_app_credits: 100,
            industry_description:
                "Automatically detects tampering of camera – camera blocking, covering the lens, sudden movement/displacement of the camera, lens out of focus, among others.",
            description: "Get notified if a camera is being tampered",
            color: "#E27039",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "CPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 575.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 575.png",
            icon: "http://192.168.0.3:3000/uploads/Group 575.png",
            awi_app_id: 4,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_camera_health_check",
            awi_app: "awi_camera_health_check",
            awi_name: "Camera Health Check",
            id: 49068,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" },
                { id: 48963, awi_name: "Traffic Management" },
                { id: 48964, awi_name: "Manufacturing" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/Screen Shot 2019-06-23 at 4.07.18 PM.png",
                "http://192.168.0.3:3000/uploads/Screen Shot 2019-06-23 at 4.02.07 PM.png",
                "http://192.168.0.3:3000/uploads/Screen Shot 2019-06-23 at 3.50.14 PM.png",
                "http://192.168.0.3:3000/uploads/Screen Shot 2019-06-23 at 4.02.36 PM.png"
            ],
            dislikes: 0,
            likes: 395,
            updated: "2019-09-17T13:02:22.288Z",
            timestamp: "2019-09-17T13:02:22.288Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80bf06aed82f0d25bc0618",
            awi_app_credits: 100,
            industry_description:
                "Awiros Crowd Counting App allows a user to detect overcrowding or congestion in an indoor or outdoor environment. The user can set the threshold for the number of objects that can occupy the area before it is deemed to be overcrowded.",
            description:
                "Estimating crowd densities automatically in real time  ",
            color: "#8A1A5D",
            fov_angle: 1,
            v_angle: 1,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 581.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 581.png",
            icon: "http://192.168.0.3:3000/uploads/Group 581.png",
            awi_app_id: 120,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_crowd_counting",
            awi_app: "awi_crowd_counting",
            awi_name: "Crowd Counting",
            id: 49054,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 471,
            updated: "2019-09-17T11:09:58.290Z",
            timestamp: "2019-09-17T11:09:58.290Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d9f6aed82f0d25bc0627",
            awi_app_credits: 100,
            industry_description:
                "Face Recognition: Matches the Face to that in the database. Option to send Alerts to in case of match/non-match to VMS/ Alarm Center",
            description: "Identify people in real time",
            color: "#A57E5D",
            fov_angle: 1,
            v_angle: 1,
            RAM: 1,
            processor: "CPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 578.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 578.png",
            icon: "http://192.168.0.3:3000/uploads/Group 578.png",
            awi_app_id: 10,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_facial_recognition",
            awi_app: "awi_facial_recognition",
            awi_name: "Facial Recognition",
            id: 49069,
            __v: 0,
            is_live: true,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-17-11h52m15s134.png",
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-17-11h52m49s212.png",
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-17-11h53m01s482.png",
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-17-11h54m30s893.png"
            ],
            dislikes: 0,
            likes: 162,
            updated: "2019-09-17T13:04:54.776Z",
            timestamp: "2019-09-17T13:04:54.775Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80c0b5aed82f0d25bc061a",
            awi_app_credits: 100,
            industry_description:
                "AI based Video Analytics detects garbage overflow/bin cleaned,garbage vehicle classification,tracking of Garbage vehicle movement etc.\n\nAnalytics used to assist local civic bodies with alerts to\neffectively manage waste and keep the city clean.",
            description:
                "Detect the presence of garbage in a camera’s view automatically",
            color: "#2D4E59",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 583.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 583.png",
            icon: "http://192.168.0.3:3000/uploads/Group 583.png",
            awi_app_id: 121,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_garbage_detection",
            awi_app: "awi_garbage_detection",
            awi_name: "Garbage Detection",
            id: 49056,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48962, awi_name: "Municipalities" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/4.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG"
            ],
            dislikes: 0,
            likes: 138,
            updated: "2019-09-17T11:17:09.816Z",
            timestamp: "2019-09-17T11:17:09.816Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80cd89aed82f0d25bc061d",
            awi_app_credits: 100,
            industry_description:
                "Analytics used to detect helmet, an alert can be generated if helmet is found on screen.\nTraffic Violation- Challan if not wearing a helmet. ATM security- a person wearing helmet found in ATM.",
            description: "Identify two-wheeler riders  without a helmet",
            color: "#D3BB90",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 584.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 584.png",
            icon: "http://192.168.0.3:3000/uploads/Group 584.png",
            awi_app_id: 18,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_helmet_violation",
            awi_app: "awi_helmet_violation",
            awi_name: "Helmet Violation",
            id: 49059,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 164,
            updated: "2019-09-17T12:11:53.277Z",
            timestamp: "2019-09-17T12:11:53.277Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80da7faed82f0d25bc0628",
            awi_app_credits: 100,
            industry_description:
                "Recognises the license plate by identifying license number from detected plate.",
            description: "Identify vehicle number plates in real time",
            color: "#3ECAA9",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "CPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 576.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 576.png",
            icon: "http://192.168.0.3:3000/uploads/Group 576.png",
            awi_app_id: 16,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_licence_plate_recognition",
            awi_app: "awi_licence_plate_recognition",
            awi_name: "Licence Plate Recognition",
            id: 49070,
            __v: 0,
            is_live: true,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/Screen Shot 2019-06-23 at 3.48.21 PM.png",
                "http://192.168.0.3:3000/uploads/Screen Shot 2019-06-23 at 3.49.03 PM.jpg",
                "http://192.168.0.3:3000/uploads/Picture6.png",
                "http://192.168.0.3:3000/uploads/Picture5.png"
            ],
            dislikes: 0,
            likes: 841,
            updated: "2019-09-17T13:07:11.530Z",
            timestamp: "2019-09-17T13:07:11.530Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d55daed82f0d25bc0621",
            awi_app_credits: 100,
            industry_description:
                "Detects parking or stopping by any vehicle in a specified virtual area (no parking zone or restricted zone) beyond a specified period.",
            description: "Detect improperly parked vehicles.",
            color: "#FEA706",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 606.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 606.png",
            icon: "http://192.168.0.3:3000/uploads/Group 606.png",
            awi_app_id: 106,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_parking_violation",
            awi_app: "awi_parking_violation",
            awi_name: "Parking Violation",
            id: 49063,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48962, awi_name: "Municipalities" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG"
            ],
            dislikes: 0,
            likes: 59,
            updated: "2019-09-17T12:45:17.545Z",
            timestamp: "2019-09-17T12:45:17.545Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d5dfaed82f0d25bc0622",
            awi_app_credits: 100,
            industry_description:
                "Track humans and their pose to understand and conclude if a person has fallen. Recognise post fall events to conclude about safety of person",
            description:
                "Get notified if a person collapses in the camera’s view",
            color: "#EC511D",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 604.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 604.png",
            icon: "http://192.168.0.3:3000/uploads/Group 604.png",
            awi_app_id: 122,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_person_collapsing",
            awi_app: "awi_person_collapsing",
            awi_name: "Person Collapsing",
            id: 49064,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 67,
            updated: "2019-09-17T12:47:27.759Z",
            timestamp: "2019-09-17T12:47:27.758Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d636aed82f0d25bc0623",
            awi_app_credits: 100,
            industry_description:
                "System detects the potholes or digging on the public road or highways and will be able to generate alerts to authorities for appropriate actions",
            description:
                "Detect the presence of potholes on the road in real time",
            color: "#2EA0F3",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 605.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 605.png",
            icon: "http://192.168.0.3:3000/uploads/Group 605.png",
            awi_app_id: 145,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_pothole_detection",
            awi_app: "awi_pothole_detection",
            awi_name: "Pothole Detection",
            id: 49065,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48962, awi_name: "Municipalities" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/7.png",
                "http://192.168.0.3:3000/uploads/5.png",
                "http://192.168.0.3:3000/uploads/6.png",
                "http://192.168.0.3:3000/uploads/8.png"
            ],
            dislikes: 0,
            likes: 92,
            updated: "2019-09-17T12:48:54.648Z",
            timestamp: "2019-09-17T12:48:54.647Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d8c7aed82f0d25bc0625",
            awi_app_credits: 100,
            industry_description: "Detect activity in a region of interest",
            description: "Detect activity in a region of interest",
            color: "#9184F9",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "CPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 619.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 619.png",
            icon: "http://192.168.0.3:3000/uploads/Group 619.png",
            awi_app_id: 13,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_region_of_interest",
            awi_app: "awi_region_of_interest",
            awi_name: "Region Of Interest",
            id: 49067,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/3.jpg",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/2 (copy).PNG"
            ],
            dislikes: 0,
            likes: 53,
            updated: "2019-09-17T12:59:51.804Z",
            timestamp: "2019-09-17T12:59:51.804Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80ce2baed82f0d25bc061e",
            awi_app_credits: 100,
            industry_description:
                "Awiros Advanced Speed Violation Detection App allows the user to identify vehicles that are moving at a speed higher than the user-defined speed limit. The user can define as well as vary the set speed limit as and when needed. \nCan generate Challan in case of traffic.\nCan be used in Smart cities for min. Speed inside premises",
            description:
                "Detect vehicles moving at speeds higher than the speed limit. ",
            color: "#1A62D3",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/speeding.png",
            app_image: "http://192.168.0.3:3000/uploads/speeding.png",
            icon: "http://192.168.0.3:3000/uploads/speeding.png",
            awi_app_id: 100,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_speed_violation",
            awi_app: "awi_speed_violation",
            awi_name: "Speed Violation",
            id: 49060,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 91,
            updated: "2019-09-17T12:14:35.559Z",
            timestamp: "2019-09-17T12:14:35.559Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80d6f3aed82f0d25bc0624",
            awi_app_credits: 100,
            industry_description:
                "Identify every kind of object on the street ",
            description: "Identify every kind of object on the street ",
            color: "#F73474",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 607.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 607.png",
            icon: "http://192.168.0.3:3000/uploads/Group 607.png",
            awi_app_id: 147,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_street_view_classification",
            awi_app: "awi_street_view_classification",
            awi_name: "Street View Classification",
            id: 49066,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48960, awi_name: "Smart Homes" },
                { id: 48961, awi_name: "Defence" },
                { id: 48962, awi_name: "Municipalities" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG"
            ],
            dislikes: 0,
            likes: 312,
            updated: "2019-09-17T12:52:03.033Z",
            timestamp: "2019-09-17T12:52:03.033Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "5d80dc70aed82f0d25bc0629",
            awi_app_credits: 100,
            industry_description:
                "Detects vehicle movement in direction opposite to user-specified direction. \nExample: If vehicle entering through exit gate, alarm will be raised.",
            description: "Identify vehicles moving in the wrong direction",
            color: "#B5F79E",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 570.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 570.png",
            icon: "http://192.168.0.3:3000/uploads/Group 570.png",
            awi_app_id: 110,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_wrong_way_detection",
            awi_app: "awi_wrong_way_detection",
            awi_name: "Wrong Way Detection",
            id: 49071,
            __v: 0,
            is_live: false,
            industries: [
                { id: 48957, awi_name: "Warehousing and Logistics" },
                { id: 48958, awi_name: "Smart Cities" },
                { id: 48959, awi_name: "Safety and Security" },
                { id: 48961, awi_name: "Defence" },
                { id: 48963, awi_name: "Traffic Management" }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/1 (another copy).PNG",
                "http://192.168.0.3:3000/uploads/1 (copy).PNG",
                "http://192.168.0.3:3000/uploads/1 (3rd copy).PNG"
            ],
            dislikes: 0,
            likes: 56,
            updated: "2019-09-17T13:15:28.642Z",
            timestamp: "2019-09-17T13:15:28.642Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "62f5e0c9bc060bfb3c778130",
            awi_app_credits: 1,
            industry_description: "shjb",
            description: "bhj",
            color: "#944500",
            fov_angle: 1,
            v_angle: 1,
            RAM: 1,
            processor: "CPU",
            developer_image:
                "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png",
            app_image:
                "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png",
            icon: "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png",
            awi_app_id: 1,
            awi_developer: "test",
            awi_app_name: "awi_test",
            awi_app: "awi_test",
            awi_name: "test",
            id: 27,
            __v: 0,
            is_live: false,
            industries: [],
            screenshot_image: [
                "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png"
            ],
            dislikes: 1,
            likes: 1,
            updated: "2022-08-12T05:10:33.773Z",
            timestamp: "2022-08-12T05:10:33.773Z",
            awi_app_API_key: "34",
            production_flag: true
        },
        {
            _id: "62f394c99e555787becebd46",
            awi_app_credits: 1,
            industry_description: "asdasdas",
            description: "asdasd",
            color: "#C8BB60",
            fov_angle: 1,
            v_angle: 1,
            RAM: 1,
            processor: "CPU",
            developer_image:
                "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png",
            app_image:
                "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png",
            icon: "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png",
            awi_app_id: 1,
            awi_developer: "test",
            awi_app_name: "awi_trest",
            awi_app: "awi_trest",
            awi_name: "trest",
            id: 26,
            __v: 0,
            is_live: false,
            industries: [],
            screenshot_image: [
                "http://localhost:12009/uploads/Screenshot from 2022-08-04 10-33-25.png"
            ],
            dislikes: 1,
            likes: 1,
            updated: "2022-08-10T11:21:45.020Z",
            timestamp: "2022-08-10T11:21:45.020Z",
            awi_app_API_key: "34",
            production_flag: true
        }
    ]
};

export const appStackDevelopers = {
    success: true,
    data: ["test", "Awidit Systems"],
    msg: "user list fetched successfully"
};

export const filteredAppStack = {
    success: true,
    msg: "10 apps found",
    data: [
        {
            _id: "5d80bd01aed82f0d25bc0616",
            awi_app_credits: 100,
            industry_description:
                "Awiros Advanced Intrusion allows a user to define a ‘virtual fence’ of any shape and dimensions within the camera’s view.\nThe app also uses an object classifier to identify the object that has caused the alert.",
            description:
                "Detect activity across a user-defined boundary or perimeter.",
            color: "#DD3CFD",
            fov_angle: 1,
            v_angle: 1,
            RAM: 1,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/location.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 564.png",
            icon: "http://192.168.0.3:3000/uploads/Group 564.png",
            awi_app_id: 107,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_advanced_intrusion",
            awi_app: "awi_advanced_intrusion",
            awi_name: "Advanced Intrusion",
            id: 49052,
            industries: [
                { awi_name: "Warehousing and Logistics", id: 48957 },
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Safety and Security", id: 48959 },
                { awi_name: "Smart Homes", id: 48960 },
                { awi_name: "Defence", id: 48961 },
                { awi_name: "Municipalities", id: 48962 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-16-15h34m44s578.png",
                "http://192.168.0.3:3000/uploads/vlcsnap-2019-09-16-15h23m37s591.png",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG"
            ],
            dislikes: 0,
            likes: 220,
            updated: "2019-09-17T11:01:21.321Z",
            timestamp: "2019-09-17T11:01:21.320Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80bf06aed82f0d25bc0618",
            awi_app_credits: 100,
            industry_description:
                "Awiros Crowd Counting App allows a user to detect overcrowding or congestion in an indoor or outdoor environment. The user can set the threshold for the number of objects that can occupy the area before it is deemed to be overcrowded.",
            description:
                "Estimating crowd densities automatically in real time  ",
            color: "#8A1A5D",
            fov_angle: 1,
            v_angle: 1,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 581.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 581.png",
            icon: "http://192.168.0.3:3000/uploads/Group 581.png",
            awi_app_id: 120,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_crowd_counting",
            awi_app: "awi_crowd_counting",
            awi_name: "Crowd Counting",
            id: 49054,
            industries: [
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Safety and Security", id: 48959 },
                { awi_name: "Defence", id: 48961 },
                { awi_name: "Municipalities", id: 48962 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 471,
            updated: "2019-09-17T11:09:58.290Z",
            timestamp: "2019-09-17T11:09:58.290Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80c0b5aed82f0d25bc061a",
            awi_app_credits: 100,
            industry_description:
                "AI based Video Analytics detects garbage overflow/bin cleaned,garbage vehicle classification,tracking of Garbage vehicle movement etc.\n\nAnalytics used to assist local civic bodies with alerts to\neffectively manage waste and keep the city clean.",
            description:
                "Detect the presence of garbage in a camera’s view automatically",
            color: "#2D4E59",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 583.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 583.png",
            icon: "http://192.168.0.3:3000/uploads/Group 583.png",
            awi_app_id: 121,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_garbage_detection",
            awi_app: "awi_garbage_detection",
            awi_name: "Garbage Detection",
            id: 49056,
            industries: [
                { awi_name: "Warehousing and Logistics", id: 48957 },
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Smart Homes", id: 48960 },
                { awi_name: "Municipalities", id: 48962 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/4.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG"
            ],
            dislikes: 0,
            likes: 138,
            updated: "2019-09-17T11:17:09.816Z",
            timestamp: "2019-09-17T11:17:09.816Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80cd89aed82f0d25bc061d",
            awi_app_credits: 100,
            industry_description:
                "Analytics used to detect helmet, an alert can be generated if helmet is found on screen.\nTraffic Violation- Challan if not wearing a helmet. ATM security- a person wearing helmet found in ATM.",
            description: "Identify two-wheeler riders  without a helmet",
            color: "#D3BB90",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 584.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 584.png",
            icon: "http://192.168.0.3:3000/uploads/Group 584.png",
            awi_app_id: 18,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_helmet_violation",
            awi_app: "awi_helmet_violation",
            awi_name: "Helmet Violation",
            id: 49059,
            industries: [
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Traffic Management", id: 48963 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 164,
            updated: "2019-09-17T12:11:53.277Z",
            timestamp: "2019-09-17T12:11:53.277Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80d55daed82f0d25bc0621",
            awi_app_credits: 100,
            industry_description:
                "Detects parking or stopping by any vehicle in a specified virtual area (no parking zone or restricted zone) beyond a specified period.",
            description: "Detect improperly parked vehicles.",
            color: "#FEA706",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 606.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 606.png",
            icon: "http://192.168.0.3:3000/uploads/Group 606.png",
            awi_app_id: 106,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_parking_violation",
            awi_app: "awi_parking_violation",
            awi_name: "Parking Violation",
            id: 49063,
            industries: [
                { awi_name: "Warehousing and Logistics", id: 48957 },
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Safety and Security", id: 48959 },
                { awi_name: "Municipalities", id: 48962 },
                { awi_name: "Traffic Management", id: 48963 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG"
            ],
            dislikes: 0,
            likes: 59,
            updated: "2019-09-17T12:45:17.545Z",
            timestamp: "2019-09-17T12:45:17.545Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80d5dfaed82f0d25bc0622",
            awi_app_credits: 100,
            industry_description:
                "Track humans and their pose to understand and conclude if a person has fallen. Recognise post fall events to conclude about safety of person",
            description:
                "Get notified if a person collapses in the camera’s view",
            color: "#EC511D",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 604.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 604.png",
            icon: "http://192.168.0.3:3000/uploads/Group 604.png",
            awi_app_id: 122,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_person_collapsing",
            awi_app: "awi_person_collapsing",
            awi_name: "Person Collapsing",
            id: 49064,
            industries: [
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Safety and Security", id: 48959 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 67,
            updated: "2019-09-17T12:47:27.759Z",
            timestamp: "2019-09-17T12:47:27.758Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80d636aed82f0d25bc0623",
            awi_app_credits: 100,
            industry_description:
                "System detects the potholes or digging on the public road or highways and will be able to generate alerts to authorities for appropriate actions",
            description:
                "Detect the presence of potholes on the road in real time",
            color: "#2EA0F3",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 605.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 605.png",
            icon: "http://192.168.0.3:3000/uploads/Group 605.png",
            awi_app_id: 145,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_pothole_detection",
            awi_app: "awi_pothole_detection",
            awi_name: "Pothole Detection",
            id: 49065,
            industries: [
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Municipalities", id: 48962 },
                { awi_name: "Traffic Management", id: 48963 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/7.png",
                "http://192.168.0.3:3000/uploads/5.png",
                "http://192.168.0.3:3000/uploads/6.png",
                "http://192.168.0.3:3000/uploads/8.png"
            ],
            dislikes: 0,
            likes: 92,
            updated: "2019-09-17T12:48:54.648Z",
            timestamp: "2019-09-17T12:48:54.647Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80ce2baed82f0d25bc061e",
            awi_app_credits: 100,
            industry_description:
                "Awiros Advanced Speed Violation Detection App allows the user to identify vehicles that are moving at a speed higher than the user-defined speed limit. The user can define as well as vary the set speed limit as and when needed. \nCan generate Challan in case of traffic.\nCan be used in Smart cities for min. Speed inside premises",
            description:
                "Detect vehicles moving at speeds higher than the speed limit. ",
            color: "#1A62D3",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/speeding.png",
            app_image: "http://192.168.0.3:3000/uploads/speeding.png",
            icon: "http://192.168.0.3:3000/uploads/speeding.png",
            awi_app_id: 100,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_speed_violation",
            awi_app: "awi_speed_violation",
            awi_name: "Speed Violation",
            id: 49060,
            industries: [
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Defence", id: 48961 },
                { awi_name: "Municipalities", id: 48962 },
                { awi_name: "Traffic Management", id: 48963 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG"
            ],
            dislikes: 0,
            likes: 91,
            updated: "2019-09-17T12:14:35.559Z",
            timestamp: "2019-09-17T12:14:35.559Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80d6f3aed82f0d25bc0624",
            awi_app_credits: 100,
            industry_description:
                "Identify every kind of object on the street ",
            description: "Identify every kind of object on the street ",
            color: "#F73474",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 607.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 607.png",
            icon: "http://192.168.0.3:3000/uploads/Group 607.png",
            awi_app_id: 147,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_street_view_classification",
            awi_app: "awi_street_view_classification",
            awi_name: "Street View Classification",
            id: 49066,
            industries: [
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Safety and Security", id: 48959 },
                { awi_name: "Smart Homes", id: 48960 },
                { awi_name: "Defence", id: 48961 },
                { awi_name: "Municipalities", id: 48962 },
                { awi_name: "Traffic Management", id: 48963 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/4.PNG",
                "http://192.168.0.3:3000/uploads/2.PNG",
                "http://192.168.0.3:3000/uploads/3.PNG"
            ],
            dislikes: 0,
            likes: 312,
            updated: "2019-09-17T12:52:03.033Z",
            timestamp: "2019-09-17T12:52:03.033Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        },
        {
            _id: "5d80dc70aed82f0d25bc0629",
            awi_app_credits: 100,
            industry_description:
                "Detects vehicle movement in direction opposite to user-specified direction. \nExample: If vehicle entering through exit gate, alarm will be raised.",
            description: "Identify vehicles moving in the wrong direction",
            color: "#B5F79E",
            fov_angle: 2,
            v_angle: 2,
            RAM: 2,
            processor: "GPU",
            developer_image: "http://192.168.0.3:3000/uploads/Group 570.png",
            app_image: "http://192.168.0.3:3000/uploads/Group 570.png",
            icon: "http://192.168.0.3:3000/uploads/Group 570.png",
            awi_app_id: 110,
            awi_developer: "Awidit Systems",
            awi_app_name: "awi_wrong_way_detection",
            awi_app: "awi_wrong_way_detection",
            awi_name: "Wrong Way Detection",
            id: 49071,
            industries: [
                { awi_name: "Warehousing and Logistics", id: 48957 },
                { awi_name: "Smart Cities", id: 48958 },
                { awi_name: "Safety and Security", id: 48959 },
                { awi_name: "Defence", id: 48961 },
                { awi_name: "Traffic Management", id: 48963 }
            ],
            screenshot_image: [
                "http://192.168.0.3:3000/uploads/1.PNG",
                "http://192.168.0.3:3000/uploads/1 (another copy).PNG",
                "http://192.168.0.3:3000/uploads/1 (copy).PNG",
                "http://192.168.0.3:3000/uploads/1 (3rd copy).PNG"
            ],
            dislikes: 0,
            likes: 56,
            updated: "2019-09-17T13:15:28.642Z",
            timestamp: "2019-09-17T13:15:28.642Z",
            awi_app_API_key: "34",
            production_flag: true,
            __v: 0
        }
    ]
};
