import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname,state } = useLocation();

  useEffect(() => {

    document?.getElementById?.('pages-container')?.scrollTo({top:0,behavior:"smooth"})
  }, [pathname,state]);

  return null;
}