import React from 'react'

function OrgInformation({data}:any) {
  return (
    <>
        <h2 className='org-modal-section-header'>
            Organisation Information
        </h2>
        <div className='order-modal-section-body'>
        <div className='order-modal-content-row'>
            <label>
                Organisation Name
            </label>
            <p>
                {data?.org?.org_name}
            </p>
            <label>
                GST Number
            </label>
            <p>
                {data?.gst_number}
            </p>
            <label>
                Project Name
            </label>
            <p>
                {data?.project_title}
            </p>
            <label>
                Customer Name
            </label>
            <p>
                {data?.customer_name}
            </p>
            <label>
                Partner Name
            </label>
            <p>
                {data?.partner_name}
            </p>
        </div>
        <div className='order-modal-content-row'>
            <label>
                Person of Contact
            </label>
        </div>
        <div className='order-modal-content-row'>
            <label>
                Name
            </label>
            <p>
                {data?.poc_name}
            </p>
            <label>
                Email
            </label>
            <p>
                {data?.poc_email}
            </p>
            <label>
                Phone Number
            </label>
            <p>
                {data?.poc_phone}
            </p>
        </div>
        </div>
    </>
  )
}

export default OrgInformation