export const getAllCameras = {
    success: true,
    msg: "Sources found",
    data: [
        {
            _id: "6298a06cf644b061d9eae514",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/5/3_5.jpg",
                status: true,
                url: "/home/awiros-docker/awiros_anpr_test.mp4",
                recheck_time: 5,
                name: "awi_camera",
                id: 5,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "awi_vid_1",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-02T11:35:08.218Z",
                ts_updation: "2022-06-02T11:35:08.218Z",
                release: "v1.0"
            },
            awi_camid: 5,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        },
        {
            _id: "6298a7b3ed1ea5b7ac4954ba",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/9/3_9.jpg",
                status: true,
                url: "/home/awiros-docker/awiros_anpr_test.mp4",
                recheck_time: 5,
                name: "awi_camera",
                id: 9,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "awi_vid_2",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-02T12:06:11.151Z",
                ts_updation: "2022-06-02T12:06:11.151Z",
                release: "v1.0"
            },
            awi_camid: 9,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        },
        {
            _id: "6298a7b7ed1ea5d4b84954bb",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/10/3_10.jpg",
                status: true,
                url: "/home/awiros-docker/awiros_anpr_test.mp4",
                recheck_time: 5,
                name: "awi_camera",
                id: 10,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "awi_vid_3",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-02T12:06:15.366Z",
                ts_updation: "2022-06-02T12:06:15.366Z",
                release: "v1.0"
            },
            awi_camid: 10,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        },
        {
            _id: "6298a7baed1ea5ba1b4954bc",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/11/3_11.jpg",
                status: true,
                url: "/home/awiros-docker/awiros_anpr_test.mp4",
                recheck_time: 5,
                name: "awi_camera",
                id: 11,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "awi_vid_4",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-02T12:06:18.683Z",
                ts_updation: "2022-06-02T12:06:18.683Z",
                release: "v1.0"
            },
            awi_camid: 11,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        },
        {
            _id: "6298a7beed1ea5a6d44954bd",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/12/3_12.jpg",
                status: true,
                url: "/home/awiros-docker/awiros_anpr_test.mp4",
                recheck_time: 5,
                name: "awi_camera",
                id: 12,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "awi_vid_5",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-02T12:06:22.087Z",
                ts_updation: "2022-06-02T12:06:22.087Z",
                release: "v1.0"
            },
            awi_camid: 12,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        },
        {
            _id: "6298a7c1ed1ea544ba4954be",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/13/3_13.jpg",
                status: true,
                url: "/home/awiros-docker/awiros_anpr_test.mp4",
                recheck_time: 5,
                name: "awi_camera",
                id: 13,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "awi_vid_6",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-02T12:06:25.811Z",
                ts_updation: "2022-06-02T12:06:25.811Z",
                release: "v1.0"
            },
            awi_camid: 13,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        },
        {
            _id: "6299b52bed1ea50dd9496bf6",
            awi_camera: {
                image: "http://172.18.0.2:12009/frame/3/24/3_24.jpg",
                status: true,
                url: "rtsp://admin:awisys555@10.15.17.1:554",
                recheck_time: 5,
                name: "awi_camera",
                id: 24,
                ip_add: "localhost",
                cam_no: 1,
                stream_id: "2",
                port: "554",
                username: "none",
                password: "none",
                model: null,
                url_proto: "none",
                location: "ANPR_CAM_1",
                frame_count: 10,
                selected_source_type: "live",
                decoder: "gstreamer",
                second_url: "",
                lat: "0",
                long: "0",
                source_group: [null],
                awi_start_time: 0,
                physical_props: {
                    camera_height: 12,
                    vertical_angle: 12,
                    fov_angle: 12,
                    focal_length: 3.2,
                    sensor_size: 0.12,
                    sensor_type: "1/2.8"
                },
                calibration: {
                    awi_is_calibrated: false,
                    awi_width: 640,
                    awi_height: 480,
                    awi_ed_threshold: 10,
                    awi_ed_kernel: 3,
                    awi_ld_threshold: 5,
                    awi_min_line_length: 10,
                    awi_max_line_gap: 5,
                    awi_frame_count: 50,
                    awi_orthonormal_tolerance: 4,
                    awi_ransac_iterations: 2000,
                    awi_ransac_inlier_threshold: 5,
                    awi_regions: [
                        {
                            awi_coords: [
                                { y: 0.873194839015151, x: 0.274125532670455 },
                                { y: 0.553119081439394, x: 0.234352805397727 },
                                { y: 0.433800899621212, x: 0.532648259943182 },
                                { y: 0.837209990530303, x: 0.803955078125 }
                            ],
                            awi_region_width: 2,
                            awi_region_height: 4,
                            awi_name: "Region 1"
                        }
                    ]
                },
                camera_type: "bullet",
                lighting_type: "indoor",
                camera_placement: "entrance"
            },
            awi_version: {
                type: "awi_cs",
                awi_name: "awi_version",
                seq: 1,
                ts_creation: "2022-06-03T07:15:55.710Z",
                ts_updation: "2022-06-03T07:15:55.710Z",
                release: "v1.0"
            },
            awi_camid: 24,
            awi_terminal_id: 3,
            demo_status: false,
            mapping_id: "0",
            list_of_enabled_apps: [16],
            terminal: [{ terminal_name: "Awiros" }]
        }
    ]
};