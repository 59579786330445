import { useNavigate } from 'react-router-dom'
import { IAppStoreData } from '../../../interfaces/appStore'
import { parseVersion } from '../../../utils/appstackCard'
import './styles.css'

type props = {
    appData: IAppStoreData[] | undefined
}


const tableHeadings = [ "S.No.","App Icon", "App Name","Developer","Version","Description"]

function AppList({appData}: props) {
    let navigate = useNavigate();

    const goToDescription = (appData:IAppStoreData) => {
        navigate({
           pathname: "/config/description",
           search: `?id=${appData.id}`,
        }, {
            state: appData
        });
    };

    const fetchTableRows = () => {
        if(!appData || appData.length === 0){
            return (
                <tr>
                    <td colSpan={6} className="no-data">
                        No data to display
                    </td>
                </tr>
            )
        }
        return (
            appData?.map((rowItem:any,idx:number)=>{
                return (
                <tr onClick={(e)=>{goToDescription(rowItem)}}>
                    <td>{idx+1}</td>
                <td className="card-logo-container">
                    <img src={rowItem.awi_app_logo} alt="app-icon" className="app-icon"/>
                </td>
                <td>
                    {rowItem.awi_name}
                </td>
                <td>
                    {rowItem.awi_app_developer}
                </td>
                <td>
                    {parseVersion(rowItem?.awi_app_version)}
                </td>
               <td>
                    {rowItem?.awi_description}
               </td>
                </tr>
                )
            })
        )
    }
  return (
    
        <table className="table">
            <thead>
                {
                    tableHeadings.map((heading)=>{
                        return <th>{heading}</th>
                    })
                }
            </thead>
            <tbody>
                {fetchTableRows()}
            </tbody>
        </table>
  )
}

export default AppList