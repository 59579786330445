import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../lotties/success.json";
import "./styles.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function AppSummary({ processCompleted = "appRegistered" }) {
  return (
    <div className="order-summary">
      <Lottie options={defaultOptions} height={150} width={150} />
      {processCompleted === "registerApp" && (
        <>
          <h1 className="create-order-heading">App Registered</h1>
          <p>
            Your App is registered. Visit <Link to={"/"}>Appstack Page</Link> to
            view detailed view of your app.{" "}
          </p>
        </>
      )}
      {processCompleted === "editApp" && (
        <>
          <h1 className="create-order-heading">App Updated</h1>
          <p>
            Your App is updated. Visit <Link to={"/"}>Appstack Page</Link> to
            view detailed view of your app.{" "}
          </p>
        </>
      )}
      {processCompleted === "registerAppVersion" && (
        <>
          <h1 className="create-order-heading">App Version Registered</h1>
          <p>
            Your App Version is registered. Visit{" "}
            <Link to={"/"}>Appstack Page</Link> to view detailed view of your
            app.{" "}
          </p>
        </>
      )}
    </div>
  );
}

export default AppSummary;
